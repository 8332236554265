export const codes = [
  '',
  'PL',
  'AF',
  'AL',
  'DZ',
  'AD',
  'AO',
  'SA',
  'AR',
  'AM',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BY',
  'BO',
  'BA',
  'BW',
  'BR',
  'BN',
  'BG',
  'BF',
  'BI',
  'CL',
  'CN',
  'HR',
  'CY',
  'TD',
  'ME',
  'CZ',
  'DK',
  'CD',
  'DM',
  'DO',
  'DJ',
  'EG',
  'EC',
  'ER',
  'EE',
  'ET',
  'FJ',
  'PH',
  'FI',
  'FR',
  'GA',
  'GM',
  'GH',
  'GI',
  'GR',
  'GD',
  'GL',
  'GE',
  'GF',
  'GY',
  'GT',
  'GW',
  'GQ',
  'GN',
  'HT',
  'ES',
  'NL',
  'HN',
  'IN',
  'ID',
  'IQ',
  'IR',
  'IE',
  'IS',
  'IL',
  'JM',
  'JP',
  'YE',
  'JE',
  'JO',
  'KH',
  'CM',
  'CA',
  'QA',
  'KZ',
  'KE',
  'KG',
  'KI',
  'CO',
  'KM',
  'CG',
  'KR',
  'KP',
  'KV',
  'CR',
  'CU',
  'KW',
  'LA',
  'LS',
  'LB',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'LV',
  'MK',
  'MG',
  'MW',
  'MV',
  'MY',
  'ML',
  'MT',
  'MA',
  'MR',
  'MU',
  'MX',
  'FM',
  'MM',
  'MD',
  'MC',
  'MN',
  'MZ',
  'NA',
  'NR',
  'NP',
  'DE',
  'NE',
  'NG',
  'NI',
  'NO',
  'NZ',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PF',
  'PR',
  'PT',
  'ZA',
  'CF',
  'CV',
  'RU',
  'RO',
  'RW',
  'EH',
  'KN',
  'LC',
  'VC',
  'SV',
  'AS',
  'WS',
  'SM',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SO',
  'LK',
  'US',
  'SZ',
  'SD',
  'SS',
  'SR',
  'SY',
  'CH',
  'SE',
  'TJ',
  'TH',
  'TZ',
  'TL',
  'TG',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'UG',
  'UA',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'HU',
  'GB',
  'VN',
  'IT',
  'CI',
  'IM',
  'MH',
  'SB',
  'ST',
  'ZM',
  'ZW',
  'AE',
];

export const labels = {
  PL: 'Polska',
  AF: 'Afganistan',
  AL: 'Albania',
  DZ: 'Algieria',
  AD: 'Andora',
  AO: 'Angola',
  SA: 'Arabia Saudyjska',
  AR: 'Argentyna',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbejdżan',
  BS: 'Bahamy',
  BH: 'Bahrajn',
  BD: 'Bangladesz',
  BB: 'Barbados',
  BE: 'Belgia',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermudy',
  BT: 'Bhutan',
  BY: 'Białoruś',
  BO: 'Boliwia',
  BA: 'Bośnia i Hercegowina',
  BW: 'Botswana',
  BR: 'Brazylia',
  BN: 'Brunei',
  BG: 'Bułgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CL: 'Chile',
  CN: 'Chiny',
  HR: 'Chorwacja',
  CY: 'Cypr',
  TD: 'Czad',
  ME: 'Czarnogóra',
  CZ: 'Czechy',
  DK: 'Dania',
  CD: 'Demokratyczna Republika Konga',
  DM: 'Dominika',
  DO: 'Dominikana',
  DJ: 'Dżibuti',
  EG: 'Egipt',
  EC: 'Ekwador',
  ER: 'Erytrea',
  EE: 'Estonia',
  ET: 'Etiopia',
  FJ: 'Fidżi',
  PH: 'Filipiny',
  FI: 'Finlandia',
  FR: 'Francja',
  GA: 'Gabon',
  GM: 'Gambia',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grecja',
  GD: 'Grenada',
  GL: 'Grenlandia',
  GE: 'Gruzja',
  GF: 'Gujana Francuska',
  GY: 'Gujana',
  GT: 'Gwatemala',
  GW: 'Gwinea Bissau',
  GQ: 'Gwinea Równikowa',
  GN: 'Gwinea',
  HT: 'Haiti',
  ES: 'Hiszpania',
  NL: 'Holandia',
  HN: 'Honduras',
  IN: 'Indie',
  ID: 'Indonezja',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irlandia',
  IS: 'Islandia',
  IL: 'Izrael',
  JM: 'Jamajka',
  JP: 'Japonia',
  YE: 'Jemen',
  JE: 'Jersey',
  JO: 'Jordania',
  KH: 'Kambodża',
  CM: 'Kamerun',
  CA: 'Kanada',
  QA: 'Katar',
  KZ: 'Kazachstan',
  KE: 'Kenia',
  KG: 'Kirgistan',
  KI: 'Kiribati',
  CO: 'Kolumbia',
  KM: 'Komory',
  CG: 'Kongo',
  KR: 'Korea Południowa',
  KP: 'Korea Północna',
  KV: 'Kosovo',
  CR: 'Kostaryka',
  CU: 'Kuba',
  KW: 'Kuwejt',
  LA: 'Laos',
  LS: 'Lesotho',
  LB: 'Liban',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Litwa',
  LU: 'Luksemburg',
  LV: 'Łotwa',
  MK: 'Macedonia',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MV: 'Malediwy',
  MY: 'Malezja',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Maroko',
  MR: 'Mauretania',
  MU: 'Mauritius',
  MX: 'Meksyk',
  FM: 'Mikronezja',
  MM: 'Mjanma',
  MD: 'Mołdawia',
  MC: 'Monako',
  MN: 'Mongolia',
  MZ: 'Mozambik',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  DE: 'Niemcy',
  NE: 'Niger',
  NG: 'Nigeria',
  NI: 'Nikaragua',
  NO: 'Norwegia',
  NZ: 'Nowa Zelandia',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestyna',
  PA: 'Panama',
  PG: 'Papua-Nowa Gwinea',
  PY: 'Paragwaj',
  PE: 'Peru',
  PF: 'Polinezja Francuska',
  PR: 'Portoryko',
  PT: 'Portugalia',
  ZA: 'Republika Południowej Afryki',
  CF: 'Republika Środkowoafrykańska',
  CV: 'Republika Zielonego Przylądka',
  RU: 'Rosja',
  RO: 'Rumunia',
  RW: 'Rwanda',
  EH: 'Sahara Zachodnia',
  KN: 'Saint Kitts i Nevis',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent i Grenadyny',
  SV: 'Salwador',
  AS: 'Samoa Amerykańskie',
  WS: 'Samoa',
  SM: 'San Marino',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seszele',
  SL: 'Sierra Leone',
  SG: 'Singapur',
  SK: 'Słowacja',
  SI: 'Słowenia',
  SO: 'Somalia',
  LK: 'Sri Lanka',
  US: 'Stany Zjednoczone',
  SZ: 'Suazi',
  SD: 'Sudan',
  SS: 'Sudan Południowy',
  SR: 'Surinam',
  SY: 'Syria',
  CH: 'Szwajcaria',
  SE: 'Szwecja',
  TJ: 'Tadżykistan',
  TH: 'Tajlandia',
  TZ: 'Tanzania',
  TL: 'Timor Wschodni',
  TG: 'Togo',
  TO: 'Tonga',
  TT: 'Trynidad i Tobago',
  TN: 'Tunezja',
  TR: 'Turcja',
  TM: 'Turkmenistan',
  UG: 'Uganda',
  UA: 'Ukraina',
  UY: 'Urugwaj',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Watykan',
  VE: 'Wenezuela',
  HU: 'Węgry',
  GB: 'Wielka Brytania',
  VN: 'Wietnam',
  IT: 'Włochy',
  CI: 'Wybrzeże Kości Słoniowej',
  IM: 'Wyspa Man',
  MH: 'Wyspy Marshalla',
  SB: 'Wyspy Salomona',
  ST: 'Wyspy Świętego Tomasza i Książęca',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AE: 'Zjednoczone Emiraty Arabskie',
};
