import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Form } from 'react-final-form';
import {
  TextField,
  makeValidate,
} from 'mui-rff';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles
} from '@material-ui/core';
import { login } from '../../store/actions/auth';
import authSelectors from '../../store/selectors/auth';

import Page from '../../components/Page';
import AlertBar from '../../components/AlertBar';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

// Validation
const schema = Yup.object().shape({
  email: Yup.string()
    .required('Pole jest wymagane'),
  password: Yup.string()
    .required('Pole jest wymagane'),
});
const validate = makeValidate(schema);

const LoginView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isFetching = useSelector((state) => authSelectors.getIsFetching(state));

  const onSubmit = async (values) => {
    try {
      await dispatch(login(values));

      navigate('/dashboard');

      return true;
    } catch (error) {
      return error;
    }
  };

  return (
    <Page
      className={classes.root}
      title="Logowanie"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {!isFetching ? (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, submitting, submitErrors }) => (
                <form onSubmit={handleSubmit} noValidate>
                  {submitErrors && (
                    <AlertBar
                      type="error"
                      title={submitErrors.error}
                      message="Błąd logowania"
                    />
                  )}
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Hasło"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="secondary"
                      disabled={submitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {!submitting ? 'Loguj' : <CircularProgress color="secondary" size={26} />}
                    </Button>
                  </Box>
                </form>
              )}
            />
          ) : <Loading />}
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
