import React, {
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import clsx from 'clsx';
import { TextField } from 'mui-rff';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  TextField as MuiTextField,
  makeStyles
} from '@material-ui/core';
import {
  MapContainer,
  TileLayer,
  Marker,
} from 'react-leaflet';

import getPlaceMarkerIcon from '../../../components/PlaceMarkerIcon';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  actions: {
    padding: 0,
  },
  button: {
    padding: theme.spacing(2),
    borderRadius: 0,
  },
  coordinates: {
    minHeight: 52,
    padding: theme.spacing(2),
  },
  error: {
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  hidden: {
    display: 'none',
  },
  latLngField: {
    width: '100%',
    minHeight: 52,
    padding: '12px 16px',
    '& input': {
      textAlign: 'center',
    },
  },
  map: {
    height: 300,
    padding: 0,
    '& .close-place': {
      filter: 'grayscale(100%)',
      opacity: 0.8,
      pointerEvents: 'none !important',
    }
  },
}));

const LATLNG_REGEX = /^-?[0-9]+(\.[0-9]+)?,( |)-?[0-9]+(?:\.[0-9]+)?$/;

const Map = ({
  data,
  isMapDraggable,
  setLatLngValues,
  setMapDraggable,
  getClosePlaces,
  closePlaces,
  isClosePlacesVisible,
}) => {
  const classes = useStyles();
  const markerRef = useRef(null);

  const [map, setMap] = useState(null);
  const [position, setPosition] = useState({
    lat: data?.latitude || 51.9189046,
    lng: data?.longitude || 19.1343786,
  });
  const [positionString, setPositionString] = useState(data ? `${data.latitude}, ${data.longitude}` : '');

  const latLngToString = (latLng) => `${latLng.lat}, ${latLng.lng}`;
  const stringToLatLng = (string) => {
    const [lat, lng] = string.trim().split(',');

    return {
      lat: lat.trim(),
      lng: lng.trim(),
    };
  };

  const handlePositionTextFieldChange = (event) => {
    const { value } = event.target;

    if (LATLNG_REGEX.test(value)) {
      map.setView(stringToLatLng(value));
      setPosition(stringToLatLng(value));
    }

    setPositionString(value);
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;

        if (marker != null) {
          const location = marker.getLatLng();

          setPosition(location);
          setPositionString(latLngToString(location));
        }
      },
    }), [],
  );

  const toggleDraggable = () => {
    if (isMapDraggable) {
      const marker = markerRef.current;
      const location = marker.getLatLng();
      setLatLngValues(location);

      if (getClosePlaces) {
        getClosePlaces(location.lat, location.lng);
      }
    }
    setMapDraggable(!isMapDraggable);
  };

  return (
    <>
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
      <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
        {({ errors, submitFailed, values }) => (
          <Card
            className={
              clsx((submitFailed && (errors.longitude || errors.latitude)) && classes.error)
            }
          >
            <CardContent className={classes.map}>
              <MapContainer
                center={position}
                className={classes.map}
                scrollWheelZoom
                zoom={data ? 15 : 5}
                whenCreated={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  draggable={isMapDraggable}
                  icon={getPlaceMarkerIcon(values.type)}
                  position={position}
                  eventHandlers={eventHandlers}
                  ref={markerRef}
                />
                {isClosePlacesVisible && closePlaces.map((place) => (
                  <Marker
                    key={place.id}
                    icon={getPlaceMarkerIcon(place.type, 'close-place')}
                    position={{
                      lat: place.latitude,
                      lng: place.longitude,
                    }}
                  />
                ))}
              </MapContainer>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="secondary"
                disabled={isMapDraggable && !LATLNG_REGEX.test(positionString)}
                className={classes.button}
                fullWidth
                variant={isMapDraggable ? 'contained' : 'text'}
                onClick={toggleDraggable}
              >
                {isMapDraggable ? 'Zapisz nową lokalizację' : 'Zmień lokalizację'}
              </Button>
              <TextField
                className={classes.hidden}
                hidden
                name="latitude"
              />
              <TextField
                className={classes.hidden}
                hidden
                name="longitude"
              />
            </CardActions>
            <Divider />
            {isMapDraggable ? (
              <>
                <MuiTextField
                  className={classes.latLngField}
                  name="latlng"
                  error={!LATLNG_REGEX.test(positionString)}
                  onChange={handlePositionTextFieldChange}
                  value={positionString}
                />
              </>
            ) : (
              <Typography
                align="center"
                className={classes.coordinates}
                color="textSecondary"
                variant="body1"
              >
                {positionString || 'Kliknij w "Zmień lokalizację", aby przesunać marker'}
              </Typography>
            )}
          </Card>
        )}
      </FormSpy>
    </>
  );
};

Map.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  closePlaces: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  isClosePlacesVisible: PropTypes.bool.isRequired,
  isMapDraggable: PropTypes.bool.isRequired,
  setLatLngValues: PropTypes.func.isRequired,
  setMapDraggable: PropTypes.func.isRequired,
  getClosePlaces: PropTypes.func,
};

Map.defaultProps = {
  data: {
    type: null,
    latitude: 51.9189046,
    longitude: 19.1343786,
  },
  closePlaces: [],
};

export default Map;
