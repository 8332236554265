import React from 'react';

const WindmillIcon = (props) => {
  return (
    <img
      alt="Wiatrak"
      src="/static/images/icons/tourist_attractions/windmill.svg"
      {...props}
    />
  );
};

export default WindmillIcon;
