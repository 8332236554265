import React from 'react';

const ShedIcon = (props) => {
  return (
    <img
      alt="Wild"
      src="/static/images/icons/shed.svg"
      {...props}
    />
  );
};

export default ShedIcon;
