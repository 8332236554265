import React from 'react';

const TunnelIcon = (props) => {
  return (
    <img
      alt="Tunel"
      src="/static/images/icons/tourist_attractions/tunnel.svg"
      {...props}
    />
  );
};

export default TunnelIcon;
