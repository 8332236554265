import React from 'react';

const CamperParkIcon = (props) => {
  return (
    <img
      alt="Kamper Park"
      src="/static/images/icons/camper_park.svg"
      {...props}
    />
  );
};

export default CamperParkIcon;
