import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Checkboxes,
  TextField,
} from 'mui-rff';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  makeStyles,
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
  codes as countriesCodes,
  labels as countriesLabels,
} from 'src/utils/countries';
import {
  codes as regionsCodes,
  labels as regionsLabels,
} from 'src/utils/regions';
import {
  accessTypeValues,
  accessForValues,
  campingAtmosphereValues,
  // campingCategoryValues,
  campingDurationValues,
  campingPetsValues,
  campingTypeValues,
  groundTypeValues,
  gsmSignalValues,
  otherTypeValues,
  placeTypeValues,
  touristAttractionTypeValues,
  serviceTypeValues,
} from 'src/utils/enums';
import countryToFlag from 'src/utils/countryToFlag';
import getPlaceTypeLabel from 'src/utils/getPlaceTypeLabel';

import TouristAttractionType from 'src/components/TouristAttractionType';
import ServiceType from 'src/components/ServiceType';

import ChangesRow from './ChangesRow';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  countryIcon: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  headerButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'white',
  },
  image: {
    position: 'relative',
    overflow: 'hidden',
    height: ({ variant }) => (variant === 'outlined' ? 100 : 150),
    cursor: 'zoom-in',
    '& > img': {
      display: 'block',
      objectFit: 'cover',
      width: ({ variant }) => (variant === 'outlined' ? 160 : 250),
      height: '100%',
      borderRadius: theme.shape.borderRadius,
    },
  },
  mapField: {
    position: 'relative',
    '& input': {
      paddingRight: 48,
    },
  },
  mapButton: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));

const getBigImageFilename = (filepath) => {
  const filename = filepath.slice(0, filepath.lastIndexOf('.'));
  const extension = filepath.slice(filepath.lastIndexOf('.'));

  return `${filename}_big${extension}`;
};

const getLabel = (object, value) => object.find((type) => type.value === value)?.label;

const ChangesTable = ({
  suggestions,
  place,
}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  return (
    <Card className={classes.root}>
      <Button
        className={classes.headerButton}
        color="primary"
        onClick={() => {
          setShowAll(!showAll);
        }}
        startIcon={showAll ? <VisibilityIcon /> : <VisibilityOffIcon />}
        variant="outlined"
      >
        Pokaż wszystkie pola
      </Button>
      <CardHeader
        title={`Propozycje zmian dla: ${place ? `${place.title} [ID: ${place.id}]` : '...'}`}
      />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={60}>
                  Akceptuj
                </TableCell>
                <TableCell width={235}>
                  Pole
                </TableCell>
                <TableCell width={300}>
                  Aktualny stan
                </TableCell>
                <TableCell width={300}>
                  Proponowane zmiany
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                <ChangesRow
                  label="Tytuł"
                  name="title"
                  showAll={showAll}
                  suggestion={suggestions.title}
                  place={place.title}
                >
                  <TextField
                    fullWidth
                    name="title"
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Opis"
                  name="description"
                  showAll={showAll}
                  suggestion={suggestions.description}
                  place={place.description}
                >
                  <TextField
                    fullWidth
                    multiline
                    name="description"
                    minRows={5}
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Kraj"
                  name="country"
                  showAll={showAll}
                  suggestion={countriesLabels[suggestions.country]}
                  place={countriesLabels[place.country]}
                >
                  <Autocomplete
                    autoHighlight
                    classes={{
                      option: classes.countryIcon,
                    }}
                    disableClearable
                    getOptionLabel={(option) => countriesLabels[option] || ''}
                    name="country"
                    options={countriesCodes}
                    renderOption={(option) => (option !== '' ? (
                      <>
                        <span>{countryToFlag(option)}</span>
                        {countriesLabels[option]}
                      </>
                    ) : '')}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </ChangesRow>
                <ChangesRow
                  label="Województwo"
                  name="region"
                  showAll={showAll}
                  suggestion={regionsLabels[suggestions.region]}
                  place={regionsLabels[place.region]}
                >
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    getOptionLabel={(option) => regionsLabels[option] || ''}
                    name="region"
                    options={regionsCodes}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </ChangesRow>
                <ChangesRow
                  label="Typ"
                  name="type"
                  showAll={showAll}
                  suggestion={getPlaceTypeLabel(suggestions.type)}
                  place={getPlaceTypeLabel(place.type)}
                >
                  <TextField
                    className={classes.typeSelect}
                    fullWidth
                    label="Typ miejsca"
                    name="type"
                    required
                    select
                    variant="outlined"
                  >
                    <MenuItem
                      key=""
                      value=""
                    />
                    {
                      [
                        ...placeTypeValues,
                        ...campingTypeValues,
                        ...otherTypeValues
                      ].map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </ChangesRow>
                {place.type === 'tourist_attraction' && (
                  <ChangesRow
                    label="Typ atrakcji turystycznej"
                    name="tourist_attraction"
                    showAll={showAll}
                    suggestion={
                      getLabel(touristAttractionTypeValues, suggestions.tourist_attraction)
                    }
                    place={getLabel(touristAttractionTypeValues, place.tourist_attraction)}
                  >
                    <TextField
                      className={classes.typeSelect}
                      fullWidth
                      name="tourist_attraction"
                      select
                      variant="outlined"
                    >
                      {touristAttractionTypeValues.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          <TouristAttractionType
                            className={classes.placeTypeIcon}
                            type={option.value}
                          />
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ChangesRow>
                )}
                {place.type === 'service' && (
                  <ChangesRow
                    label="Typ serwisu"
                    name="service"
                    showAll={showAll}
                    suggestion={getLabel(serviceTypeValues, suggestions.service)}
                    place={getLabel(serviceTypeValues, place.service)}
                  >
                    <TextField
                      className={classes.typeSelect}
                      fullWidth
                      name="service"
                      select
                      variant="outlined"
                    >
                      {serviceTypeValues.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          <ServiceType
                            className={classes.placeTypeIcon}
                            type={option.value}
                          />
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ChangesRow>
                )}
                <ChangesRow
                  label="Koordynaty"
                  name="latlng"
                  showAll={showAll}
                  suggestion={`${suggestions.latitude}, ${suggestions.longitude}`}
                  place={`${place.latitude}, ${place.longitude}`}
                >
                  <Box className={classes.mapField}>
                    <TextField
                      fullWidth
                      name="latlng"
                      variant="outlined"
                    />
                    <IconButton
                      className={classes.mapButton}
                      onClick={() => {
                        window.open(`https://www.google.com/maps/search/?api=1&query=${suggestions.latitude},${suggestions.longitude}`);
                      }}
                    >
                      <MapIcon />
                    </IconButton>
                  </Box>
                </ChangesRow>
                {placeTypeValues.some((type) => type.value === place.type) && (
                  <>
                    <ChangesRow
                      label="Rodzaj nawierzchni/miejsca"
                      name="groundType"
                      showAll={showAll}
                      suggestion={getLabel(groundTypeValues, suggestions.groundType)}
                      place={getLabel(groundTypeValues, place.groundType)}
                    >
                      <TextField
                        fullWidth
                        name="groundType"
                        select
                        variant="outlined"
                      >
                        {groundTypeValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Dojazd drogą"
                      name="accessType"
                      showAll={showAll}
                      suggestion={getLabel(accessTypeValues, suggestions.accessType)}
                      place={getLabel(accessTypeValues, place.accessType)}
                    >
                      <TextField
                        fullWidth
                        name="accessType"
                        select
                        variant="outlined"
                      >
                        {accessTypeValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Dojazd dla pojazdów"
                      name="accessFor"
                      showAll={showAll}
                      suggestion={getLabel(accessForValues, suggestions.accessFor)}
                      place={getLabel(accessForValues, place.accessFor)}
                    >
                      <TextField
                        fullWidth
                        name="accessFor"
                        select
                        variant="outlined"
                      >
                        {accessForValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Ilość miejsca"
                      name="capacity"
                      showAll={showAll}
                      suggestion={suggestions.capacity}
                      place={place.capacity}
                    >
                      <TextField
                        fullWidth
                        name="capacity"
                        variant="outlined"
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Koszt postoju"
                      name="cost"
                      showAll={showAll}
                      suggestion={suggestions.cost}
                      place={place.cost}
                    >
                      <TextField
                        fullWidth
                        helperText="Puste = bezpłatny"
                        name="cost"
                        variant="outlined"
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Limit wysokości wjazdu"
                      name="heightLimit"
                      showAll={showAll}
                      suggestion={suggestions.heightLimit}
                      place={place.heightLimit}
                    >
                      <TextField
                        fullWidth
                        helperText="Puste jeśli bez limitu"
                        inputProps={{
                          min: 0.5,
                          max: 5,
                          step: 0.05,
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        }}
                        name="heightLimit"
                        type="number"
                        variant="outlined"
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Jakość synału GSM"
                      name="gsmSignal"
                      showAll={showAll}
                      suggestion={getLabel(gsmSignalValues, suggestions.gsmSignal)}
                      place={getLabel(gsmSignalValues, place.gsmSignal)}
                    >
                      <TextField
                        fullWidth
                        name="gsmSignal"
                        select
                        variant="outlined"
                      >
                        {gsmSignalValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Wiata"
                      name="shed"
                      showAll={showAll}
                      suggestion={suggestions.shed ? 'Tak' : 'Nie'}
                      place={place.shed ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="shed"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Ławki"
                      name="benches"
                      showAll={showAll}
                      suggestion={suggestions.benches ? 'Tak' : 'Nie'}
                      place={place.benches ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="benches"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Stoliki"
                      name="tables"
                      showAll={showAll}
                      suggestion={suggestions.tables ? 'Tak' : 'Nie'}
                      place={place.tables ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="tables"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Kosze na śmieci"
                      name="trashcan"
                      showAll={showAll}
                      suggestion={suggestions.trashcan ? 'Tak' : 'Nie'}
                      place={place.trashcan ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="trashcan"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Możliwość rozbicia namiotu"
                      name="tent"
                      showAll={showAll}
                      suggestion={suggestions.tent ? 'Tak' : 'Nie'}
                      place={place.tent ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="tent"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Możliwość rozpalenia ogniska"
                      name="bonfire"
                      showAll={showAll}
                      suggestion={suggestions.bonfire ? 'Tak' : 'Nie'}
                      place={place.bonfire ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="bonfire"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Możliwość nazbierania opału"
                      name="combustible"
                      showAll={showAll}
                      suggestion={suggestions.combustible ? 'Tak' : 'Nie'}
                      place={place.combustible ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="combustible"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Grill wolnostojący"
                      name="grill"
                      showAll={showAll}
                      suggestion={suggestions.grill ? 'Tak' : 'Nie'}
                      place={place.grill ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="grill"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="WC"
                      name="wc"
                      showAll={showAll}
                      suggestion={suggestions.wc ? 'Tak' : 'Nie'}
                      place={place.wc ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="wc"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Prysznic"
                      name="shower"
                      showAll={showAll}
                      suggestion={suggestions.shower ? 'Tak' : 'Nie'}
                      place={place.shower ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="shower"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Bieżąca woda"
                      name="water"
                      showAll={showAll}
                      suggestion={suggestions.water ? 'Tak' : 'Nie'}
                      place={place.water ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="water"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Zrzut kasety WC"
                      name="carWc"
                      showAll={showAll}
                      suggestion={suggestions.carWc ? 'Tak' : 'Nie'}
                      place={place.carWc ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="carWc"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Zrzut szarej wody"
                      name="dirtWater"
                      showAll={showAll}
                      suggestion={suggestions.dirtWater ? 'Tak' : 'Nie'}
                      place={place.dirtWater ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="dirtWater"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Prąd"
                      name="electricity"
                      showAll={showAll}
                      suggestion={suggestions.electricity ? 'Tak' : 'Nie'}
                      place={place.electricity ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="electricity"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Wi-Fi"
                      name="wifi"
                      showAll={showAll}
                      suggestion={suggestions.wifi ? 'Tak' : 'Nie'}
                      place={place.wifi ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="wifi"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Inna infrastruktura"
                      name="other_infrastructure"
                      showAll={showAll}
                      suggestion={suggestions.other_infrastructure}
                      place={place.other_infrastructure}
                    >
                      <TextField
                        fullWidth
                        name="other_infrastructure"
                        variant="outlined"
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Zakazy i niedogodności"
                      name="prohibitions"
                      showAll={showAll}
                      suggestion={suggestions.prohibitions}
                      place={place.prohibitions}
                    >
                      <TextField
                        fullWidth
                        name="prohibitions"
                        variant="outlined"
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Inne zalety"
                      name="advantages"
                      showAll={showAll}
                      suggestion={suggestions.advantages}
                      place={place.advantages}
                    >
                      <TextField
                        fullWidth
                        name="advantages"
                        variant="outlined"
                      />
                    </ChangesRow>
                  </>
                )}
                {campingTypeValues.some((type) => type.value === place.type) && (
                  <>
                    <ChangesRow
                      label="Przybliżone ceny"
                      name="priceList"
                      showAll={showAll}
                      suggestion={suggestions.priceList}
                      place={place.priceList}
                    >
                      <TextField
                        fullWidth
                        multiline
                        name="priceList"
                        minRows={5}
                        variant="outlined"
                      />
                    </ChangesRow>
                    {/* <ChangesRow
                      label="Kategoryzacja"
                      name="camping_category"
                      showAll={showAll}
                      suggestion={getLabel(campingCategoryValues, suggestions.camping_category)}
                      place={getLabel(campingCategoryValues, place.camping_category)}
                    >
                      <TextField
                        fullWidth
                        name="camping_category"
                        select
                        variant="outlined"
                      >
                        {campingCategoryValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow> */}
                    <ChangesRow
                      label="Atmosfera"
                      name="camping_atmosphere"
                      showAll={showAll}
                      suggestion={getLabel(campingDurationValues, suggestions.camping_atmosphere)}
                      place={getLabel(campingDurationValues, place.camping_atmosphere)}
                    >
                      <TextField
                        fullWidth
                        name="camping_atmosphere"
                        select
                        variant="outlined"
                      >
                        {campingAtmosphereValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Okres działania"
                      name="camping_duration"
                      showAll={showAll}
                      suggestion={getLabel(campingAtmosphereValues, suggestions.camping_duration)}
                      place={getLabel(campingAtmosphereValues, place.camping_duration)}
                    >
                      <TextField
                        fullWidth
                        name="camping_duration"
                        select
                        variant="outlined"
                      >
                        {campingDurationValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Rodzaj podłoża"
                      name="camping_groundType"
                      showAll={showAll}
                      suggestion={getLabel(groundTypeValues, suggestions.camping_groundType)}
                      place={getLabel(groundTypeValues, place.camping_groundType)}
                    >
                      <TextField
                        fullWidth
                        name="camping_groundType"
                        select
                        variant="outlined"
                      >
                        {groundTypeValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Zwierzęta domowe"
                      name="camping_pets"
                      showAll={showAll}
                      suggestion={getLabel(campingPetsValues, suggestions.camping_pets)}
                      place={getLabel(campingPetsValues, place.camping_pets)}
                    >
                      <TextField
                        fullWidth
                        name="camping_pets"
                        select
                        variant="outlined"
                      >
                        {campingPetsValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Jakość synału GSM"
                      name="camping_gsmSignal"
                      showAll={showAll}
                      suggestion={getLabel(gsmSignalValues, suggestions.camping_gsmSignal)}
                      place={getLabel(gsmSignalValues, place.camping_gsmSignal)}
                    >
                      <TextField
                        fullWidth
                        name="camping_gsmSignal"
                        select
                        variant="outlined"
                      >
                        {gsmSignalValues.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ChangesRow>
                    <ChangesRow
                      label="Toalety"
                      name="camping_wc"
                      showAll={showAll}
                      suggestion={suggestions.camping_wc ? 'Tak' : 'Nie'}
                      place={place.camping_wc ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_wc"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Prysznice"
                      name="camping_shower"
                      showAll={showAll}
                      suggestion={suggestions.camping_shower ? 'Tak' : 'Nie'}
                      place={place.camping_shower ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_shower"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Kuchnia"
                      name="camping_kitchen"
                      showAll={showAll}
                      suggestion={suggestions.camping_kitchen ? 'Tak' : 'Nie'}
                      place={place.camping_kitchen ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_kitchen"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Świetlica"
                      name="camping_commonRoom"
                      showAll={showAll}
                      suggestion={suggestions.camping_commonRoom ? 'Tak' : 'Nie'}
                      place={place.camping_commonRoom ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_commonRoom"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Pralnia"
                      name="camping_laundry"
                      showAll={showAll}
                      suggestion={suggestions.camping_laundry ? 'Tak' : 'Nie'}
                      place={place.camping_laundry ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_laundry"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Wiata / Stoły piknikowe"
                      name="camping_shed"
                      showAll={showAll}
                      suggestion={suggestions.camping_shed ? 'Tak' : 'Nie'}
                      place={place.camping_shed ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_shed"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Miejsce na ognisko lub grill"
                      name="camping_bonfire"
                      showAll={showAll}
                      suggestion={suggestions.camping_bonfire ? 'Tak' : 'Nie'}
                      place={place.camping_bonfire ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_bonfire"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Plac zabaw"
                      name="camping_playground"
                      showAll={showAll}
                      suggestion={suggestions.camping_playground ? 'Tak' : 'Nie'}
                      place={place.camping_playground ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_playground"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Sklep"
                      name="camping_shop"
                      showAll={showAll}
                      suggestion={suggestions.camping_shop ? 'Tak' : 'Nie'}
                      place={place.camping_shop ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_shop"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Bar / restauracja"
                      name="camping_restaurant"
                      showAll={showAll}
                      suggestion={suggestions.camping_restaurant ? 'Tak' : 'Nie'}
                      place={place.camping_restaurant ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_restaurant"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Basen"
                      name="camping_pool"
                      showAll={showAll}
                      suggestion={suggestions.camping_pool ? 'Tak' : 'Nie'}
                      place={place.camping_pool ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_pool"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Wi-Fi"
                      name="camping_wifi"
                      showAll={showAll}
                      suggestion={suggestions.camping_wifi ? 'Tak' : 'Nie'}
                      place={place.camping_wifi ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="camping_wifi"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Zlanie kasety WC"
                      name="caravaning_wc"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_wc ? 'Tak' : 'Nie'}
                      place={place.caravaning_wc ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_wc"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Zlanie szarej wody"
                      name="caravaning_dirtWater"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_dirtWater ? 'Tak' : 'Nie'}
                      place={place.caravaning_dirtWater ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_dirtWater"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Możliwość napełnienia zbiornika wody"
                      name="caravaning_fillWater"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_fillWater ? 'Tak' : 'Nie'}
                      place={place.caravaning_fillWater ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_fillWater"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Przyłącze elektryczne"
                      name="caravaning_electricity"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_electricity ? 'Tak' : 'Nie'}
                      place={place.caravaning_electricity ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_electricity"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Woda na parceli"
                      name="caravaning_water"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_water ? 'Tak' : 'Nie'}
                      place={place.caravaning_water ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_water"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Dla gości"
                      name="caravaning_guest"
                      showAll={showAll}
                      suggestion={suggestions.caravaning_guest ? 'Tak' : 'Nie'}
                      place={place.caravaning_guest ? 'Tak' : 'Nie'}
                    >
                      <Checkboxes
                        name="caravaning_guest"
                        data={[{ value: true }]}
                      />
                    </ChangesRow>
                    <ChangesRow
                      label="Inna infrastruktura"
                      name="other_infrastructure"
                      showAll={showAll}
                      suggestion={suggestions.other_infrastructure}
                      place={place.other_infrastructure}
                    >
                      <TextField
                        fullWidth
                        name="other_infrastructure"
                        variant="outlined"
                      />
                    </ChangesRow>
                  </>
                )}

                <ChangesRow
                  label="Email"
                  name="email"
                  showAll={showAll}
                  suggestion={suggestions.email}
                  place={place.email}
                >
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Telefon"
                  name="phone"
                  showAll={showAll}
                  suggestion={suggestions.phone}
                  place={place.phone}
                >
                  <TextField
                    fullWidth
                    name="phone"
                    type="tel"
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Strona internetowa"
                  name="website"
                  showAll={showAll}
                  suggestion={suggestions.website}
                  place={place.website}
                >
                  <TextField
                    fullWidth
                    name="website"
                    type="url"
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Facebook"
                  name="facebook"
                  showAll={showAll}
                  suggestion={suggestions.facebook}
                  place={place.facebook}
                >
                  <TextField
                    fullWidth
                    name="facebook"
                    type="url"
                    variant="outlined"
                  />
                </ChangesRow>
                <ChangesRow
                  label="Google Maps Street View URL"
                  name="streetViewUrl"
                  showAll={showAll}
                  suggestion={suggestions.streetViewUrl}
                  place={place.streetViewUrl}
                >
                  <Box className={classes.mapField}>
                    <TextField
                      fullWidth
                      name="streetViewUrl"
                      type="url"
                      variant="outlined"
                    />
                    <IconButton
                      className={classes.mapButton}
                      onClick={() => {
                        window.open(suggestions.streetViewUrl);
                      }}
                    >
                      <MapIcon />
                    </IconButton>
                  </Box>
                </ChangesRow>
                {suggestions.files.length > 0 && suggestions.files.map((file) => (
                  <ChangesRow
                    key={file.id}
                    label="Nowe zdjęcie"
                    name={`file_${file.id}`}
                    showAll={showAll}
                    suggestion={file.id}
                    place={null}
                  >
                    <Box
                      className={classes.image}
                      onClick={() => {
                        window.open(`${STORAGE_URL}/suggestions/${place.id}/${getBigImageFilename(file.filename)}`);
                      }}
                    >
                      <img
                        alt=""
                        src={`${STORAGE_URL}/suggestions/${place.id}/${file.filename}`}
                      />
                    </Box>
                  </ChangesRow>
                ))}
              </>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

ChangesTable.propTypes = {
  place: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
};

export default ChangesTable;
