import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import countryToFlag from 'src/utils/countryToFlag';

import articles from 'src/store/selectors/articles';
import { fetchArticleCategories } from 'src/store/actions/articles';

import TableLoading from 'src/components/TableLoading';

const useStyles = makeStyles((theme) => ({
  categoryRow: {
    height: 44,
    cursor: 'pointer',
  },
  noResults: {
    width: '100%',
    height: '100%',
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Categories = ({ handleSelectCategory, totalCount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const categories = useSelector((state) => articles.getArticleCategoryList(state));
  const isFetching = useSelector((state) => articles.getArticleCategoryIsFetching(state));

  const currentCategoryId = useMemo(() => (
    searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId'), 10) : null
  ), [searchParams]);

  const fetchCategories = useCallback(async () => {
    await dispatch(fetchArticleCategories())
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania listy kategorii', { variant: 'error' });
      });
  }, [dispatch]);

  const openDetails = (id) => {
    navigate(`/articles/category/${id}`);
  };

  const selectCategory = (id) => {
    handleSelectCategory({
      categoryId: id,
      page: 1
    });
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <Card>
      <CardHeader title="Kategorie" />
      <Divider />
      <PerfectScrollbar>
        <Box minHeight={252}>
          {isFetching && <TableLoading />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={220}>
                  Nazwa
                </TableCell>
                <TableCell align="center">
                  Artykułów
                </TableCell>
                <TableCell align="center">
                  Język
                </TableCell>
                <TableCell width={40} />
              </TableRow>
            </TableHead>
            {categories && (
              <TableBody>
                {categories.length > 0 ? (
                  <>
                    <TableRow
                      hover
                      selected={!currentCategoryId}
                      className={classes.categoryRow}
                      onClick={() => selectCategory()}
                    >
                      <TableCell>
                        Wszystkie
                      </TableCell>
                      <TableCell align="center">
                        {totalCount}
                      </TableCell>
                      <TableCell colSpan={2} />
                    </TableRow>
                    {categories.map((category) => (
                      <TableRow
                        key={`category-${category.id}`}
                        className={classes.categoryRow}
                        onClick={() => selectCategory(category.id)}
                        hover
                        selected={currentCategoryId === category.id}
                      >
                        <TableCell>
                          {category.title}
                        </TableCell>
                        <TableCell align="center">
                          {category.articlesCount}
                        </TableCell>
                        <TableCell align="center">
                          {countryToFlag(category.language)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton size="small" onClick={() => openDetails(category.id)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak kategorii
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Categories.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  totalCount: PropTypes.number
};

Categories.defaultProps = {
  totalCount: 0
};

export default Categories;
