import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';

import CommentItem from 'src/components/CommentItem';

const LatestComments = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader title="Ostatnie komentarze" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={250}>
                  Użytkownik
                </TableCell>
                <TableCell width={200}>
                  Miejsce
                </TableCell>
                <TableCell width={152}>
                  Ocena
                </TableCell>
                <TableCell>
                  Komentarz
                </TableCell>
                <TableCell width={200}>
                  Data dodania
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((comment) => (
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  showActions={false}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      <Box padding={1}>
        <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={() => navigate('/comments')}
        >
          Pokaż wszystkie
        </Button>
      </Box>
    </Card>
  );
};

LatestComments.propTypes = {
  data: PropTypes.array.isRequired
};

export default LatestComments;
