import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab';

const AlertBar = ({
  message,
  title,
  type,
}) => (
  <Alert severity={type}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {message}
  </Alert>
);

AlertBar.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'error',
    'info',
    'success',
    'warning',
  ]),
};

AlertBar.defaultProps = {
  title: null,
  type: 'info',
};

export default AlertBar;
