import {
  GET_DATA_FAILURE,
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
} from '../actions/dashboard';

const initialState = {
  data: {
    checkIns: [],
    comments: [],
    places: [],
    placesCount: 0,
    unverifiedPlacesCount: 0,
    checkInsCount: 0,
    commentsCount: 0,
    usersCount: 0,
    foreignUsersCount: 0,
    isFetching: false,
  },
  activities: {
    isFetching: false,
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true,
        },
      };
    case GET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          isFetching: false,
        },
      };
    case GET_DATA_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false,
        },
      };
    case GET_ACTIVITIES_REQUEST:
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: true,
        },
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...action.payload,
          isFetching: false,
        },
      };
    case GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
