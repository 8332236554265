import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import {
  green,
  red
} from '@material-ui/core/colors';

import { labels as countriesLabels } from 'src/utils/countries';
import { labels as regionsLabels } from 'src/utils/regions';
import {
  accessTypeValues,
  accessForValues,
  campingAtmosphereValues,
  // campingCategoryValues,
  campingDurationValues,
  campingPetsValues,
  groundTypeValues,
  gsmSignalValues,
  touristAttractionTypeValues,
  serviceTypeValues,
} from 'src/utils/enums';

const useStyles = makeStyles((theme) => ({
  approved: {
    backgroundColor: green[50],
  },
  rejected: {
    backgroundColor: red[50],
  },
  image: {
    position: 'relative',
    overflow: 'hidden',
    height: ({ variant }) => (variant === 'outlined' ? 100 : 150),
    cursor: 'zoom-in',
    '& > img': {
      display: 'block',
      objectFit: 'cover',
      width: ({ variant }) => (variant === 'outlined' ? 160 : 250),
      height: '100%',
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const getBigImageFilename = (filepath) => {
  const filename = filepath.slice(0, filepath.lastIndexOf('.'));
  const extension = filepath.slice(filepath.lastIndexOf('.'));

  return `${filename}_big${extension}`;
};

const getLabel = (object, value) => object.find((type) => type.value === value)?.label;

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const HistoryRow = ({
  approved,
  label,
  previousValue,
  newValue,
}) => {
  const classes = useStyles();

  return (
    <TableRow className={approved ? classes.approved : classes.rejected}>
      <TableCell style={{ fontWeight: 500 }}>
        {label}
      </TableCell>
      <TableCell>
        {previousValue}
      </TableCell>
      <TableCell>
        {newValue}
      </TableCell>
    </TableRow>
  );
};

HistoryRow.propTypes = {
  approved: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  previousValue: PropTypes.string,
  newValue: PropTypes.string,
};

HistoryRow.defaultProps = {
  previousValue: null,
  newValue: null,
};

const HistoryTable = ({
  history,
  place,
  suggestions,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={`Historia zmian dla: ${place ? `${place.title} [ID: ${place.id}] z dnia ${moment(suggestions.created).format('DD MMMM YYYY HH:mm')}` : '...'}`}
      />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={235}>
                  Pole
                </TableCell>
                <TableCell width={300}>
                  Poprzednia wartość
                </TableCell>
                <TableCell width={300}>
                  Nowa wartość
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.title && (
                <HistoryRow
                  approved={history.title.approved}
                  label="Tytuł"
                  newValue={history.title.new}
                  previousValue={history.title.previous}
                />
              )}
              {history.description && (
                <HistoryRow
                  approved={history.description.approved}
                  label="Opis"
                  newValue={history.description.new}
                  previousValue={history.description.previous}
                />
              )}
              {history.country && (
                <HistoryRow
                  approved={history.country.approved}
                  label="Kraj"
                  newValue={countriesLabels[history.country.new]}
                  previousValue={countriesLabels[history.country.previous]}
                />
              )}
              {history.region && (
                <HistoryRow
                  approved={history.region.approved}
                  label="Województwo"
                  newValue={regionsLabels[history.region.new]}
                  previousValue={regionsLabels[history.region.previous]}
                />
              )}
              {(history.latitude || history.longitude) && (
                <HistoryRow
                  approved={history.latitude.approved || history.longitude.approved}
                  label="Koordynaty"
                  newValue={`${history.latitude}, ${history.longitude}`.new}
                  previousValue={`${history.latitude}, ${history.longitude}`.previous}
                />
              )}
              {history.groundType && (
                <HistoryRow
                  approved={history.groundType.approved}
                  label="Rodzaj nawierzchni/miejsca"
                  newValue={getLabel(groundTypeValues, history.groundType.new)}
                  previousValue={getLabel(groundTypeValues, history.groundType.previous)}
                />
              )}
              {history.accessType && (
                <HistoryRow
                  approved={history.accessType.approved}
                  label="Dojazd drogą"
                  newValue={getLabel(accessTypeValues, history.accessType.new)}
                  previousValue={getLabel(accessTypeValues, history.accessType.previous)}
                />
              )}
              {history.accessFor && (
                <HistoryRow
                  approved={history.accessFor.approved}
                  label="Dojazd dla pojazdów"
                  newValue={getLabel(accessForValues, history.accessFor.new)}
                  previousValue={getLabel(accessForValues, history.accessFor.previous)}
                />
              )}
              {history.capacity && (
                <HistoryRow
                  approved={history.capacity.approved}
                  label="Ilość miejsca"
                  newValue={history.capacity.new}
                  previousValue={history.capacity.previous}
                />
              )}
              {history.cost && (
                <HistoryRow
                  approved={history.cost.approved}
                  label="Koszt postoju"
                  newValue={history.cost.new}
                  previousValue={history.cost.previous}
                />
              )}
              {history.heightLimit && (
                <HistoryRow
                  approved={history.heightLimit.approved}
                  label="Limit wysokości wjazdu"
                  newValue={history.heightLimit.new}
                  previousValue={history.heightLimit.previous}
                />
              )}
              {history.gsmSignal && (
                <HistoryRow
                  approved={history.gsmSignal.approved}
                  label="Jakość synału GSM"
                  newValue={getLabel(gsmSignalValues, history.gsmSignal.new)}
                  previousValue={getLabel(gsmSignalValues, history.gsmSignal.previous)}
                />
              )}
              {history.shed && (
                <HistoryRow
                  approved={history.shed.approved}
                  label="Wiata"
                  newValue={history.shed.new ? 'Tak' : 'Nie'}
                  previousValue={history.shed.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.benches && (
                <HistoryRow
                  approved={history.benches.approved}
                  label="Ławki"
                  newValue={history.benches.new ? 'Tak' : 'Nie'}
                  previousValue={history.benches.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.tables && (
                <HistoryRow
                  approved={history.tables.approved}
                  label="Stoliki"
                  newValue={history.tables.new ? 'Tak' : 'Nie'}
                  previousValue={history.tables.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.trashcan && (
                <HistoryRow
                  approved={history.trashcan.approved}
                  label="Kosze na śmieci"
                  newValue={history.trashcan.new ? 'Tak' : 'Nie'}
                  previousValue={history.trashcan.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.tent && (
                <HistoryRow
                  approved={history.tent.approved}
                  label="Możliwość rozbicia namiotu"
                  newValue={history.tent.new ? 'Tak' : 'Nie'}
                  previousValue={history.tent.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.bonfire && (
                <HistoryRow
                  approved={history.bonfire.approved}
                  label="Możliwość rozpalenia ogniska"
                  newValue={history.bonfire.new ? 'Tak' : 'Nie'}
                  previousValue={history.bonfire.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.combustible && (
                <HistoryRow
                  approved={history.combustible.approved}
                  label="Możliwość nazbierania opału"
                  newValue={history.combustible.new ? 'Tak' : 'Nie'}
                  previousValue={history.combustible.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.grill && (
                <HistoryRow
                  approved={history.grill.approved}
                  label="Grill wolnostojący"
                  newValue={history.grill.new ? 'Tak' : 'Nie'}
                  previousValue={history.grill.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.wc && (
                <HistoryRow
                  approved={history.wc.approved}
                  label="WC"
                  newValue={history.wc.new ? 'Tak' : 'Nie'}
                  previousValue={history.wc.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.shower && (
                <HistoryRow
                  approved={history.shower.approved}
                  label="Prysznic"
                  newValue={history.shower.new ? 'Tak' : 'Nie'}
                  previousValue={history.shower.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.water && (
                <HistoryRow
                  approved={history.water.approved}
                  label="Bieżąca woda"
                  newValue={history.water.new ? 'Tak' : 'Nie'}
                  previousValue={history.water.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.carWc && (
                <HistoryRow
                  approved={history.carWc.approved}
                  label="Zrzut kasety WC"
                  newValue={history.carWc.new ? 'Tak' : 'Nie'}
                  previousValue={history.carWc.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.dirtWater && (
                <HistoryRow
                  approved={history.dirtWater.approved}
                  label="Zrzut szarej wody"
                  newValue={history.dirtWater.new ? 'Tak' : 'Nie'}
                  previousValue={history.dirtWater.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.electricity && (
                <HistoryRow
                  approved={history.electricity.approved}
                  label="Prąd"
                  newValue={history.electricity.new ? 'Tak' : 'Nie'}
                  previousValue={history.electricity.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.wifi && (
                <HistoryRow
                  approved={history.wifi.approved}
                  label="Wi-Fi"
                  newValue={history.wifi.new ? 'Tak' : 'Nie'}
                  previousValue={history.wifi.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.other_infrastructure && (
                <HistoryRow
                  approved={history.other_infrastructure.approved}
                  label="Inna infrastruktura"
                  newValue={history.other_infrastructure.new}
                  previousValue={history.other_infrastructure.previous}
                />
              )}
              {history.prohibitions && (
                <HistoryRow
                  approved={history.prohibitions.approved}
                  label="Zakazy i niedogodności"
                  newValue={history.prohibitions.new}
                  previousValue={history.prohibitions.previous}
                />
              )}
              {history.advantages && (
                <HistoryRow
                  approved={history.advantages.approved}
                  label="Inne zalety"
                  newValue={history.advantages.new}
                  previousValue={history.advantages.previous}
                />
              )}
              {history.priceList && (
                <HistoryRow
                  approved={history.priceList.approved}
                  label="Przybliżone ceny"
                  newValue={history.priceList.new}
                  previousValue={history.priceList.previous}
                />
              )}
              {/* {history.camping_category && (
                <HistoryRow
                  approved={history.camping_category.approved}
                  label="Kategoryzacja"
                  newValue={getLabel(campingCategoryValues, history.camping_category.new)}
                  previousValue={getLabel(campingCategoryValues, history.camping_category.previous)}
                />
              )} */}
              {history.camping_atmosphere && (
                <HistoryRow
                  approved={history.camping_atmosphere.approved}
                  label="Okres działania"
                  newValue={getLabel(campingDurationValues, history.camping_atmosphere.new)}
                  previousValue={
                    getLabel(campingDurationValues, history.camping_atmosphere.previous)
                  }
                />
              )}
              {history.camping_duration && (
                <HistoryRow
                  approved={history.camping_duration.approved}
                  label="Atmosfera"
                  newValue={getLabel(campingAtmosphereValues, history.camping_duration.new)}
                  previousValue={
                    getLabel(campingAtmosphereValues, history.camping_duration.previous)
                  }
                />
              )}
              {history.camping_groundType && (
                <HistoryRow
                  approved={history.camping_groundType.approved}
                  label="Rodzaj podłoża"
                  newValue={getLabel(groundTypeValues, history.camping_groundType.new)}
                  previousValue={getLabel(groundTypeValues, history.camping_groundType.previous)}
                />
              )}
              {history.camping_pets && (
                <HistoryRow
                  approved={history.camping_pets.approved}
                  label="Zwierzęta domowe"
                  newValue={getLabel(campingPetsValues, history.camping_pets.new)}
                  previousValue={getLabel(campingPetsValues, history.camping_pets.previous)}
                />
              )}
              {history.camping_gsmSignal && (
                <HistoryRow
                  approved={history.camping_gsmSignal.approved}
                  label="Jakość synału GSM"
                  newValue={getLabel(gsmSignalValues, history.camping_gsmSignal.new)}
                  previousValue={getLabel(gsmSignalValues, history.camping_gsmSignal.previous)}
                />
              )}
              {history.camping_wc && (
                <HistoryRow
                  approved={history.camping_wc.approved}
                  label="Toalety"
                  newValue={history.camping_wc.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_wc.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_shower && (
                <HistoryRow
                  approved={history.camping_shower.approved}
                  label="Prysznice"
                  newValue={history.camping_shower.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_shower.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_kitchen && (
                <HistoryRow
                  approved={history.camping_kitchen.approved}
                  label="Kuchnia"
                  newValue={history.camping_kitchen.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_kitchen.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_commonRoom && (
                <HistoryRow
                  approved={history.camping_commonRoom.approved}
                  label="Świetlica"
                  newValue={history.camping_commonRoom.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_commonRoom.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_laundry && (
                <HistoryRow
                  approved={history.camping_laundry.approved}
                  label="Pralnia"
                  newValue={history.camping_laundry.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_laundry.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_shed && (
                <HistoryRow
                  approved={history.camping_shed.approved}
                  label="Wiata / Stoły piknikowe"
                  newValue={history.camping_shed.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_shed.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_bonfire && (
                <HistoryRow
                  approved={history.camping_bonfire.approved}
                  label="Miejsce na ognisko lub grill"
                  newValue={history.camping_bonfire.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_bonfire.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_playground && (
                <HistoryRow
                  approved={history.camping_playground.approved}
                  label="Plac zabaw"
                  newValue={history.camping_playground.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_playground.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_shop && (
                <HistoryRow
                  approved={history.camping_shop.approved}
                  label="Sklep"
                  newValue={history.camping_shop.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_shop.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_restaurant && (
                <HistoryRow
                  approved={history.camping_restaurant.approved}
                  label="Bar / restauracja"
                  newValue={history.camping_restaurant.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_restaurant.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_pool && (
                <HistoryRow
                  approved={history.camping_pool.approved}
                  label="Basen"
                  newValue={history.camping_pool.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_pool.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.camping_wifi && (
                <HistoryRow
                  approved={history.camping_wifi.approved}
                  label="Wi-Fi"
                  newValue={history.camping_wifi.new ? 'Tak' : 'Nie'}
                  previousValue={history.camping_wifi.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_wc && (
                <HistoryRow
                  approved={history.caravaning_wc.approved}
                  label="Zlanie kasety WC"
                  newValue={history.caravaning_wc.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_wc.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_dirtWater && (
                <HistoryRow
                  approved={history.caravaning_dirtWater.approved}
                  label="Zlanie szarej wody"
                  newValue={history.caravaning_dirtWater.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_dirtWater.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_fillWater && (
                <HistoryRow
                  approved={history.caravaning_fillWater.approved}
                  label="Możliwość napełnienia zbiornika wody"
                  newValue={history.caravaning_fillWater.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_fillWater.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_electricity && (
                <HistoryRow
                  approved={history.caravaning_electricity.approved}
                  label="Przyłącze elektryczne"
                  newValue={history.caravaning_electricity.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_electricity.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_water && (
                <HistoryRow
                  approved={history.caravaning_water.approved}
                  label="Woda na parceli"
                  newValue={history.caravaning_water.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_water.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.caravaning_guest && (
                <HistoryRow
                  approved={history.caravaning_guest.approved}
                  label="Dla gości"
                  newValue={history.caravaning_guest.new ? 'Tak' : 'Nie'}
                  previousValue={history.caravaning_guest.previous ? 'Tak' : 'Nie'}
                />
              )}
              {history.other_infrastructure && (
                <HistoryRow
                  approved={history.other_infrastructure.approved}
                  label="Inna infrastruktura"
                  newValue={history.other_infrastructure.new}
                  previousValue={history.other_infrastructure.previous}
                />
              )}
              {history.tourist_attraction && (
                <HistoryRow
                  approved={history.tourist_attraction.approved}
                  label="Typ atrakcji turystycznej"
                  newValue={
                    getLabel(touristAttractionTypeValues, history.tourist_attraction.new)
                  }
                  previousValue={
                    getLabel(touristAttractionTypeValues, history.tourist_attraction.previous)
                  }
                />
              )}
              {history.service && (
                <HistoryRow
                  approved={history.service.approved}
                  label="Typ serwisu"
                  newValue={getLabel(serviceTypeValues, history.service.new)}
                  previousValue={getLabel(serviceTypeValues, history.service.previous)}
                />
              )}
              {history.email && (
                <HistoryRow
                  approved={history.email.approved}
                  label="Email"
                  newValue={history.email.new}
                  previousValue={history.email.previous}
                />
              )}
              {history.phone && (
                <HistoryRow
                  approved={history.phone.approved}
                  label="Telefon"
                  newValue={history.phone.new}
                  previousValue={history.phone.previous}
                />
              )}
              {history.website && (
                <HistoryRow
                  approved={history.website.approved}
                  label="Strona internetowa"
                  newValue={history.website.new}
                  previousValue={history.website.previous}
                />
              )}
              {history.facebook && (
                <HistoryRow
                  approved={history.facebook.approved}
                  label="Facebook"
                  newValue={history.facebook.new}
                  previousValue={history.facebook.previous}
                />
              )}
              {history.streetViewUrl && (
                <HistoryRow
                  approved={history.streetViewUrl.approved}
                  label="Google Maps Street View URL"
                  newValue={history.streetViewUrl.new}
                  previousValue={history.streetViewUrl.previous}
                />
              )}
              {history.images?.length > 0 && history.images.map((file) => (
                <TableRow
                  key={file.filename}
                  className={file.approved ? classes.approved : classes.rejected}
                >
                  <TableCell>
                    {file.approved ? 'Dodano zdjęcie' : 'Nie zaakceptowane zdjęcie'}
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    {file.approved && (
                      <Box
                        className={classes.image}
                        onClick={() => {
                          window.open(`${STORAGE_URL}/places/${place.id}/${getBigImageFilename(file.filename)}`);
                        }}
                      >
                        <img
                          alt=""
                          src={`${STORAGE_URL}/places/${place.id}/${file.filename}`}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

HistoryTable.propTypes = {
  history: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
};

export default HistoryTable;
