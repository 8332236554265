import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import CommentsListView from 'src/views/comments/CommentsListView';
import UserDetailsView from 'src/views/users/UserDetailsView';
import UsersListView from 'src/views/users/UsersListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import PlaceDetailsView from 'src/views/places/PlaceDetailsView';
import PlacesListView from 'src/views/places/PlacesListView';
import CampingDetailsView from 'src/views/campings/CampingDetailsView';
import CampingsListView from 'src/views/campings/CampingsListView';
import TouristAttractionDetailsView from 'src/views/tourist_attractions/TouristAttractionDetailsView';
import TouristAttractionListView from 'src/views/tourist_attractions/TouristAttractionListView';
import ServiceDetailsView from 'src/views/services/ServiceDetailsView';
import ServiceListView from 'src/views/services/ServiceListView';
import SuggestionsDetailsView from 'src/views/suggestions/SuggestionsDetailsView';
import SuggestionsListView from 'src/views/suggestions/SuggestionsListView';
import SettingsView from 'src/views/settings/SettingsView';
import UnverifiedListView from 'src/views/unverified/UnverifiedListView';
import DiscountsListView from 'src/views/discounts/DiscountsListView';
import ArticleCategoryView from 'src/views/articles/ArticleCategoryView';
import ArticleDetailsView from 'src/views/articles/ArticleDetailsView';
import ArticlesListView from 'src/views/articles/ArticlesListView';
import NewsDetailsView from 'src/views/news/NewsDetailsView';
import NewsListView from 'src/views/news/NewsListView';
import PageDetailsView from 'src/views/pages/PageDetailsView';
import PagesListView from 'src/views/pages/PagesListView';
import AdDetailsView from 'src/views/ads/AdDetailsView';
import AdsListView from 'src/views/ads/AdsListView';

const routes = (isUserLogged) => [
  {
    path: 'login',
    element: !isUserLogged ? <MainLayout /> : <Navigate to="/dashboard" />,
    children: [
      { path: '', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '',
    element: isUserLogged ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'comments', element: <CommentsListView /> },
      { path: 'users', element: <UsersListView /> },
      { path: 'user/add', element: <UserDetailsView action="add" /> },
      { path: 'user/:id', element: <UserDetailsView action="edit" /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'places', element: <PlacesListView /> },
      { path: 'place/add', element: <PlaceDetailsView action="add" /> },
      { path: 'place/:id', element: <PlaceDetailsView action="edit" /> },
      { path: 'campings', element: <CampingsListView /> },
      { path: 'camping/add', element: <CampingDetailsView action="add" /> },
      { path: 'camping/:id', element: <CampingDetailsView action="edit" /> },
      { path: 'tourist-attractions', element: <TouristAttractionListView /> },
      { path: 'tourist-attraction/add', element: <TouristAttractionDetailsView action="add" /> },
      { path: 'tourist-attraction/:id', element: <TouristAttractionDetailsView action="edit" /> },
      { path: 'services', element: <ServiceListView /> },
      { path: 'service/add', element: <ServiceDetailsView action="add" /> },
      { path: 'service/:id', element: <ServiceDetailsView action="edit" /> },
      { path: 'suggestions', element: <SuggestionsListView /> },
      { path: 'suggestions/:id', element: <SuggestionsDetailsView /> },
      { path: 'suggestions/history/:id', element: <SuggestionsDetailsView isArchived /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'unverified', element: <UnverifiedListView /> },
      { path: 'discounts', element: <DiscountsListView /> },
      { path: 'articles', element: <ArticlesListView /> },
      { path: 'articles/category/add', element: <ArticleCategoryView action="add" /> },
      { path: 'articles/category/:id', element: <ArticleCategoryView action="edit" /> },
      { path: 'article/add', element: <ArticleDetailsView action="add" /> },
      { path: 'article/:id', element: <ArticleDetailsView action="edit" /> },
      { path: 'news', element: <NewsListView /> },
      { path: 'news/add', element: <NewsDetailsView action="add" /> },
      { path: 'news/:id', element: <NewsDetailsView action="edit" /> },
      { path: 'pages', element: <PagesListView /> },
      { path: 'page/add', element: <PageDetailsView action="add" /> },
      { path: 'page/:id', element: <PageDetailsView action="edit" /> },
      { path: 'ads', element: <AdsListView /> },
      { path: 'ad/add', element: <AdDetailsView action="add" /> },
      { path: 'ad/:id', element: <AdDetailsView action="edit" /> },
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
];

export default routes;
