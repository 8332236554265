import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import {
  clearUsersData,
  deleteUser,
  fetchUsers,
  setFilters,
} from 'src/store/actions/users';
import usersSelectors from 'src/store/selectors/users';

import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import Results from '../components/Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UsersListView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteAlertRef = useRef();

  const [isInited, setIsInited] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const search = searchParams.get('search') || null;
  const currentPage = parseInt(searchParams.get('page') || 1, 10);
  const rowsPerPage = parseInt(searchParams.get('limit') || 10, 10);

  const isFetching = useSelector((state) => usersSelectors.getUsersIsFetching(state));
  const filters = useSelector((state) => usersSelectors.getFilters(state));

  const fetchData = async () => {
    try {
      await dispatch(fetchUsers({
        search,
        page: currentPage,
        limit: rowsPerPage,
        ...filters,
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy użytkowników', { variant: 'error' });
    }
  };

  const setFiltersFromSearchParams = () => {
    // eslint-disable-next-line no-unused-vars
    const { page, limit, ...filtersArray } = Object.fromEntries(searchParams);

    dispatch(setFilters(filtersArray));
  };

  useEffect(() => {
    setIsInited(true);
    return () => {
      dispatch(clearUsersData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInited) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFiltersFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangePage = (event, value) => {
    setSearchParams({
      page: value + 1,
      limit: rowsPerPage,
      ...filters
    });
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setSearchParams({
      page: currentPage,
      limit: value,
      ...filters
    });
  };

  const handleSearch = (values) => {
    setSearchParams({
      page: currentPage,
      limit: rowsPerPage,
      ...values
    });
  };

  const openDeleteModal = (id) => {
    deleteAlertRef.current.open();
    setSelectedId(id);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteUser(selectedId));
      deleteAlertRef.current.close();
      setSelectedId(null);
      enqueueSnackbar('Użytkownik został usunięty', { variant: 'success' });

      await fetchData();

      return true;
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania użytkownika', { variant: 'error' });

      return error;
    }
  };

  return (
    <Page
      className={classes.root}
      title="Użytkownicy"
    >
      <Container maxWidth={false}>
        <Toolbar handleSearch={handleSearch} />
        <Box mt={3}>
          <Results
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            openDeleteModal={openDeleteModal}
          />
        </Box>
      </Container>
      <AlertDialog
        title="Usuwanie użytkownika"
        message="Czy na pewno chcesz usunąć tego użytkownika?"
        ref={deleteAlertRef}
        actions={[
          {
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Page>
  );
};

export default UsersListView;
