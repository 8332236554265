import React from 'react';

const TrainIcon = (props) => {
  return (
    <img
      alt="Trasa kolejowa"
      src="/static/images/icons/tourist_attractions/train.svg"
      {...props}
    />
  );
};

export default TrainIcon;
