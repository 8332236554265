import React, {
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import ReactRouterPrompt from 'react-router-prompt';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import slugify from 'slugify/slugify';
import {
  Container,
  CircularProgress,
  Fab,
  Grid,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import {
  clearPagesData,
  createPage,
  deletePage,
  fetchPageDetails,
  updatePage,
} from 'src/store/actions/pages';
import pagesSelectors from 'src/store/selectors/pages';

import AlertDialog from 'src/components/AlertDialog';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import PageDetails from './PageDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

// Validation
const schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  slug: Yup.string()
    .required('Pole jest wymagane'),
  status: Yup.string()
    .required('Pole jest wymagane'),
});

const PageDetailView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const deleteAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const page = useSelector((state) => pagesSelectors.getPageDetails(state));
  const isFetching = useSelector((state) => pagesSelectors.getPageDetailsIsFetching(state));

  const language = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('language') || 'pl';
  }, [location.search]);

  useEffect(() => {
    if (action === 'edit') {
      const fetchData = async () => {
        await dispatch(fetchPageDetails(language, id));
      };

      fetchData()
        .catch(() => {
          enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
        });
    } else {
      // console.log(validate);
    }

    return () => {
      dispatch(clearPagesData());
    };
  }, [action, dispatch, enqueueSnackbar, id, language]);

  const createSlug = (title) => {
    return slugify(title, {
      lower: true,
      remove: SLUGIFY_REGEX,
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    values.language = language;
    values.slug = createSlug(values.slug);

    try {
      if (action === 'edit') {
        await dispatch(updatePage(id, values));
        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createPage(values));
        enqueueSnackbar('Dodano nową stronę', { variant: 'success' });

        setTimeout(() => {
          navigate('/pages');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async () => {
    try {
      await dispatch(deletePage(id));
      enqueueSnackbar('Strona została usunięta', { variant: 'success' });

      setTimeout(() => {
        navigate('/pages');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        page ? {
          title: page.title ?? '',
          type: page.type,
          content: page.content ?? '',
          description: page.description ?? '',
          slug: page.slug ?? '',
          status: page.status,
        } : {
          type: 'page',
          status: 'public',
        }
      }
      keepDirtyOnReinitialize
      mutators={{
        setContent: (args, state, utils) => {
          utils.changeValue(state, 'content', () => args[0]);
        },
        setSlug: (args, state, utils) => {
          const slug = createSlug(args[0]);
          if ((action === 'add' || (action === 'edit' && !page.title)) && !state.fields.slug.modified) {
            utils.changeValue(state, 'slug', () => slug);
          }
        },
      }}
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={makeValidate(schema)}
      render={({
        dirty,
        form,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja strony' : 'Dodaj nową stronę',
            hasBackButton: true,
            backTo: '/pages',
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja strony${page ? `: ${page.title ?? ''} [ID: #${page.id}]` : ''}`
              : 'Dodaj nową stronę'
          }
        >
          {(action === 'add' || page) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <PageDetails
                      data={page}
                      setContent={form.mutators.setContent}
                      setSlug={form.mutators.setSlug}
                    />
                  </Grid>
                </Grid>
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <Tooltip
                      placement="left"
                      title={getSubmitLabel(values.status)}
                    >
                      <Fab
                        aria-label="save"
                        className={clsx(
                          classes.floatingActionButton,
                          values.status === 'deleted' && classes.deleteButton,
                        )}
                        color="secondary"
                        disabled={submitting}
                        type="submit"
                      >
                        {!submitting
                          ? getSubmitIcon(values.status)
                          : <CircularProgress color="secondary" size={26} />}
                      </Fab>
                    </Tooltip>
                  )}
                </FormSpy>
              </Container>
              <ReactRouterPrompt when={dirty && !submitSucceeded}>
                {({ isActive, onConfirm, onCancel }) => (
                  <AlertDialog
                    title="Nie zapisano zmian"
                    message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                    isOpen={isActive}
                    actions={[
                      {
                        label: 'Wyjdź bez zapisywania',
                        onClick: onConfirm,
                      },
                      {
                        autoFocus: true,
                        label: 'Wróć',
                        variant: 'contained',
                        onClick: onCancel,
                      },
                    ]}
                  />
                )}
              </ReactRouterPrompt>
              <AlertDialog
                title="Czy na pewno chcesz usunąć tą stronę?"
                ref={deleteAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    className: classes.deleteButton,
                    disabled: isFetching,
                    label: 'Usuń',
                    loading: isFetching,
                    onClick: () => {
                      onDelete();
                      deleteAlertRef.current.close();
                    },
                  },
                ]}
              />
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

PageDetailView.propTypes = {
  action: PropTypes.string,
};

PageDetailView.defaultProps = {
  action: 'add',
};

export default PageDetailView;
