import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { campingTypeValues, otherTypeValues, placeTypeValues } from 'src/utils/enums';
import { convertPlaceType } from 'src/store/actions/places';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    position: 'relative',
  },
  caption: {
    marginTop: theme.spacing(2),
    fontSize: 12,
  },
  wrapper: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ConvertPlaceModal = ({ place }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getType = useCallback(() => {
    let placeType = place.type;

    if (placeTypeValues.find((item) => item.value === place.type)) {
      placeType = 'wild';
    } else if (campingTypeValues.find((item) => item.value === place.type)) {
      placeType = 'camping';
    }

    return placeType;
  }, [place]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await dispatch(convertPlaceType(place.id, { type: value }));

      let url;

      switch (getType()) {
        case 'wild':
          url = `/place/${place.id}`;
          break;
        case 'camping':
          url = `/camping/${place.id}`;
          break;
        case 'tourist_attraction':
          url = `/tourist-attraction/${place.id}`;
          break;
        case 'service':
          url = `/service/${place.id}`;
          break;
        default:
          return null;
      }

      if (url) {
        window.location = url;
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }

    return true;
  };

  const getValues = useCallback(() => {
    return [
      {
        label: 'Dzikie miejsce biwakowe',
        value: 'wild',
      },
      {
        label: 'Kemping',
        value: 'camping',
      },
      ...otherTypeValues,
    ].filter(
      (item) => item.value !== getType()
    );
  }, [getType]);

  return (
    <>
      <Button
        color="primary"
        onClick={openModal}
        variant="outlined"
      >
        Zmień typ
      </Button>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={closeModal}
      >
        <DialogTitle id="alert-dialog-title">Zmień typ miejsca</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="input-type">Typ miejsca</InputLabel>
            <Select
              labelId="input-type"
              value={value}
              label="Typ miejsca"
              onChange={handleChange}
            >
              {getValues().map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogContentText className={classes.caption}>
            Wszystkie informacje, których nie da się przenieść do nowego typu miejsca
            zostaną przeniesione do opisu.
            <br />
            Należy wtedy ręcznie uzupełnić miejsce i wyczyścić opis.
            <br />
            Tej operacji nie można cofnąć!
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.wrapper}>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={closeModal}
          >
            Anuluj
          </Button>
          <Button
            autoFocus
            className={classes.container}
            color="secondary"
            disabled={!value || isLoading}
            onClick={handleSubmit}
            variant="contained"
          >
            Zmień
            {isLoading && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConvertPlaceModal.propTypes = {
  place: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
};

export default ConvertPlaceModal;
