import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  grey,
  orange,
  red,
} from '@material-ui/core/colors';
import PublicIcon from '@material-ui/icons/Visibility';
import RestrictedIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import DeletedIcon from '@material-ui/icons/Cancel';

import adsSelectors from 'src/store/selectors/ads';

import TableLoading from 'src/components/TableLoading';
import countryToFlag from 'src/utils/countryToFlag';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteModal,
}) => {
  const classes = useStyles();

  const ads = useSelector((state) => adsSelectors.getAds(state));
  const pagination = useSelector(
    (state) => adsSelectors.getAdsPaginationData(state),
  );
  const isFetching = useSelector((state) => adsSelectors.getAdsIsFetching(state));

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tytuł
                </TableCell>
                <TableCell width={200}>
                  Typ
                </TableCell>
                <TableCell width={125}>
                  Wyświetleń
                </TableCell>
                <TableCell width={180}>
                  Wyświetlana do
                </TableCell>
                <TableCell width={125}>
                  Język
                </TableCell>
                <TableCell align="center" width={115}>
                  Status
                </TableCell>
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            {ads && (
              <TableBody>
                {ads.length > 0 ? ads.map((ad) => (
                  <TableRow
                    hover
                    key={ad.id}
                  >
                    <TableCell>
                      {ad.title}
                    </TableCell>
                    <TableCell>
                      {ad.type === 'mobile' && 'Aplikacja mobilna'}
                      {ad.type === 'webapp_banner' && 'Banner'}
                      {ad.type === 'webapp_panel' && 'Panel boczny'}
                    </TableCell>
                    <TableCell>
                      {ad.views}
                      {ad.expiryViews && `/${ad.expiryViews}`}
                    </TableCell>
                    <TableCell>
                      {ad.expiryDate ? moment(ad.expiryDate).format('DD MMMM YYYY') : 'Bez limitu'}
                    </TableCell>
                    <TableCell>
                      {ad.language ? countryToFlag(ad.language) : 'Wszystkie'}
                    </TableCell>
                    <TableCell align="center">
                      {ad.status === 'active' && <PublicIcon style={{ color: green[500] }} />}
                      {ad.status === 'expired' && <RestrictedIcon style={{ color: orange[500] }} />}
                      {ad.status === 'hidden' && <HiddenIcon style={{ color: grey[500] }} />}
                      {ad.status === 'deleted' && <DeletedIcon style={{ color: red[500] }} />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/ad/${ad.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton disabled={ad.type === 'element'} onClick={() => openDeleteModal(ad.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak reklam
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
