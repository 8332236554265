import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(3),
  }
}));

const Rate = ({
  className,
  data,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent className={classes.content}>
        <Typography
          component="p"
          variant="h1"
        >
          {data.rate.toPrecision(2)}
        </Typography>
        <Rating
          readOnly
          precision={0.5}
          value={data.rate}
        />
        <Typography
          component="p"
          color="textSecondary"
          variant="caption"
        >
          {`Liczba głosów: ${data.ratesCount}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

Rate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
};

export default Rate;
