import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
  makeStyles,
  colors
} from '@material-ui/core';

import TableLoading from 'src/components/TableLoading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
  },
  select: {
    marginRight: theme.spacing(1),
    '& > div': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
  }
}));

const Activities = ({
  data,
  isFetching,
  period,
  onChange,
}) => {
  const classes = useStyles();
  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  const parseData = (activities) => {
    const checkIns = [];
    const comments = [];
    const places = [];
    const users = [];
    const foreignUsers = [];
    const labels = [];

    Object.values(activities).forEach(({ label, counts }) => {
      if (label && counts) {
        if (period === 7) {
          labels.push(moment(label).format('D MMM'));
        } else if (period === 30) {
          labels.push(moment(label).format('D MMM'));
        } else if (period === 365) {
          labels.push(moment(label).format('MMM \'YY'));
        } else if (period === 999) {
          labels.push(moment(label).format('YYYY'));
        }

        checkIns.push(counts.checkIns);
        comments.push(counts.comments);
        places.push(counts.places);
        users.push(counts.users);
        foreignUsers.push(counts.foreignUsers);
      }
    });

    return {
      datasets: [
        {
          backgroundColor: colors.red[500],
          hoverBackgroundColor: colors.red[500],
          data: places.reverse(),
          label: 'Nowych miejsc'
        },
        {
          backgroundColor: colors.blue[500],
          hoverBackgroundColor: colors.blue[500],
          data: checkIns.reverse(),
          label: 'Nowych zameldowań'
        },
        {
          backgroundColor: colors.green[500],
          hoverBackgroundColor: colors.green[500],
          data: comments.reverse(),
          label: 'Nowych komentarzy'
        },
        {
          backgroundColor: colors.purple[300],
          hoverBackgroundColor: colors.purple[300],
          data: foreignUsers.reverse(),
          label: 'Nowych użytkowników zza granicy',
          stack: 'Users',
        },
        {
          backgroundColor: colors.purple[500],
          hoverBackgroundColor: colors.purple[500],
          data: users.reverse(),
          label: 'Nowych użytkowników',
          stack: 'Users',
        },
      ],
      labels: labels.reverse(),
    };
  };

  React.useEffect(() => {
    const chart = chartRef.current;

    if (chart && data && Object.keys(data).length > 0) {
      setChartData(parseData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const options = {
    borderRadius: 8,
    interaction: {
      intersect: true,
      mode: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: (tooltipItems) => {
            let sum = 0;

            tooltipItems.forEach((tooltipItem) => {
              // eslint-disable-next-line no-underscore-dangle
              if (tooltipItem.parsed._stacks) {
                sum += tooltipItem.parsed.y;
              }
            });
            if (sum > 0) {
              return `Łącznie: ${sum}`;
            }

            return null;
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scale: {
      ticks: {
        precision: 0
      }
    }
  };

  return (
    <Card>
      <CardHeader
        action={(
          <Select
            className={classes.select}
            onChange={onChange}
            size="small"
            value={period}
            variant="outlined"
          >
            <MenuItem value={7}>Ostatni tydzień</MenuItem>
            <MenuItem value={30}>Ostatni miesiąc</MenuItem>
            <MenuItem value={365}>Ostatni rok</MenuItem>
            <MenuItem value={999}>Od początku</MenuItem>
          </Select>
        )}
        title="Ostatnie aktywności"
      />
      <Divider />
      <CardContent className={classes.content}>
        {isFetching && <TableLoading />}
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={chartData}
            options={options}
            ref={chartRef}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Activities.propTypes = {
  data: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  period: PropTypes.oneOf([7, 30, 365, 999]),
  onChange: PropTypes.func.isRequired,
};

export default Activities;
