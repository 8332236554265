import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';

import settingsSelectors from 'src/store/selectors/settings';
import {
  Box,
  LinearProgress,
  Grid,
  Typography,
  makeStyles,
  withStyles,
  TextField,
  Button,
} from '@material-ui/core';
import {
  green,
  yellow,
  red,
} from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import { saveAutocompleteApiKeyIndex } from 'src/store/actions/settings';

const API_KEYS_AMOUNT = 4;
const QUOTA_LIMIT = 1000;

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  saveLimitButton: {
    height: 56,
    marginLeft: theme.spacing(1),
  }
}));

const AutocompleteSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const settings = useSelector((state) => settingsSelectors.getData(state));

  const [apiValue, setApiValue] = useState(settings.autocompleteApiKeyIndex);

  const progressBarValue = settings.autocompleteRequests < (API_KEYS_AMOUNT * QUOTA_LIMIT)
    ? (settings.autocompleteRequests / (API_KEYS_AMOUNT * QUOTA_LIMIT)) * 100
    : 100;

  const getProgressBarColor = () => {
    if (progressBarValue > 75) {
      return red[500];
    }

    if (progressBarValue > 50) {
      return yellow[700];
    }

    return green[500];
  };

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: getProgressBarColor(),
    },
  }))(LinearProgress);

  const submitApi = () => {
    enqueueSnackbar('Zapisano', { variant: 'success' });
    dispatch(saveAutocompleteApiKeyIndex(apiValue));
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          {`Limit autouzupełniania (${settings.autocompleteRequests} / ${API_KEYS_AMOUNT * QUOTA_LIMIT}) [API #${settings.autocompleteApiKeyIndex}]`}
        </Typography>
        <Box mt={2} mb={1}>
          <BorderLinearProgress variant="determinate" value={progressBarValue} />
        </Box>
        <Typography variant="caption">
          {`Po wykorzystaniu ${QUOTA_LIMIT} zapytań, następuje przełączenie klucza API na inne, abyśmy dalej mogli korzystać z darmowych zapytań.`}
          <br />
          Licznik zeruje się codziennie o 2 w nocy.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          Ustaw API
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          label="API"
          variant="outlined"
          value={apiValue}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              max: API_KEYS_AMOUNT
            }
          }}
          onChange={(event) => {
            setApiValue(event.target.value);
          }}
        />
        <Button
          className={classes.saveLimitButton}
          color="secondary"
          size="small"
          onClick={submitApi}
          variant="contained"
        >
          <SaveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default AutocompleteSettings;
