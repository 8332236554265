import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ZoomIcon from '@material-ui/icons/ZoomIn';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    width: 80,
    height: 50,
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.54)',
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      opacity: 0,
      transform: 'translate(-50%, -50%)',
      fill: '#fff',
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'conver',
    },
    '&:hover': {
      '&::before, & svg': {
        opacity: 1,
      },
    },
    '& + &': {
      marginLeft: theme.spacing(1),
    }
  },
}));

const getBigImageFilename = (filepath) => {
  const filename = filepath.slice(0, filepath.lastIndexOf('.'));
  const extension = filepath.slice(filepath.lastIndexOf('.'));

  return `${filename}_big${extension}`;
};

const GalleryImage = ({ file }) => {
  const classes = useStyles();

  return (
    <a
      className={classes.image}
      href={`${STORAGE_URL}/comments/${file.parentId}/${getBigImageFilename(file.filename)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ZoomIcon />
      <figure>
        <img src={`${STORAGE_URL}/comments/${file.parentId}/${file.filename}`} alt="" />
      </figure>
    </a>
  );
};

GalleryImage.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string,
    parentId: PropTypes.number,
  })
};

export default GalleryImage;
