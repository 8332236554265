import React from 'react';

const ChurchWoodIcon = (props) => {
  return (
    <img
      alt="Zabytek sakralny drewniany"
      src="/static/images/icons/tourist_attractions/church_wood.svg"
      {...props}
    />
  );
};

export default ChurchWoodIcon;
