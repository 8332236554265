import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import * as serviceWorker from './serviceWorker';
import configureStore from './store/store';
import App from './App';

const store = configureStore();

ReactDOM.render((
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
