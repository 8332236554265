import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import CommentIcon from '@material-ui/icons/Comment';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import getPlaceTypeLabel from 'src/utils/getPlaceTypeLabel';
import PlaceType from '../../../components/PlaceType';

const { PUBLIC_URL } = process.env;
const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  commentsIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  media: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: 250,
    objectFit: 'cover',
  },
  placeIcon: {
    marginRight: theme.spacing(1),
  },
  starIcon: {
    marginRight: theme.spacing(1),
  },
  thumbnail: {
    padding: 0,
  },
  title: {
    padding: theme.spacing(1),
  }
}));

const PlaceCard = ({
  className,
  place,
  ...rest
}) => {
  const classes = useStyles();

  const getImage = () => {
    if (place && place.thumbnail) {
      return `${STORAGE_URL}/places/${place.id}/${place.thumbnail}`;
    }

    return `${PUBLIC_URL}/static/images/place_placeholder.png`;
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardActionArea
        component={RouterLink}
        to={`/camping/${place.id}`}
      >
        <CardContent className={classes.thumbnail}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <img
              className={classes.media}
              src={getImage()}
              alt="Samochód"
            />
          </Box>
          <Divider />
          <Typography
            align="center"
            className={classes.title}
            color="textPrimary"
            variant="h4"
            noWrap
          >
            {place.title}
          </Typography>
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        <Box p={2}>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            wrap="nowrap"
          >
            <Grid
              className={classes.statsItem}
              item
              zeroMinWidth
            >
              <PlaceType
                className={classes.placeIcon}
                size={24}
                type={place.type}
              />
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
                noWrap
              >
                {getPlaceTypeLabel(place.type)}
              </Typography>
            </Grid>
            <Grid
              className={classes.statsItem}
              item
            >
              {place.status === 'unverified' ? (
                <>
                  <AddLocationIcon
                    className={classes.starIcon}
                    color="action"
                  />
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    {moment(place.created).calendar()}
                  </Typography>
                </>
              ) : (
                <>
                  <StarIcon
                    className={classes.starIcon}
                    color="action"
                  />
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    {place.rating > 0 ? `${place.rating}/5` : 'Brak ocen'}
                  </Typography>
                  <CommentIcon
                    className={classes.commentsIcon}
                    color="action"
                  />
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    {place.commentsCount}
                  </Typography>
                </>
              )}

            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </Card>
  );
};

PlaceCard.propTypes = {
  className: PropTypes.string,
  place: PropTypes.object.isRequired
};

PlaceCard.defaultProps = {
  className: null,
};

export default PlaceCard;
