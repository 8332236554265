import React from 'react';

const UndergroundPathIcon = (props) => {
  return (
    <img
      alt="Podziemna trasa turystyczna"
      src="/static/images/icons/tourist_attractions/underground_path.svg"
      {...props}
    />
  );
};

export default UndergroundPathIcon;
