import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import ZoomIcon from '@material-ui/icons/ZoomIn';

import FieldSet from 'src/components/FieldSet';
import FileUploadDialog from 'src/components/FileUploadDialog';

const useStyles = makeStyles((theme) => {
  return {
    addButton: {
      width: 160,
      height: 100,
    },
    image: {
      position: 'relative',
      overflow: 'hidden',
      height: ({ variant }) => (variant === 'outlined' ? 100 : 150),
      marginBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      fontSize: 48,
      '& > img': {
        display: 'block',
        objectFit: 'cover',
        width: ({ variant }) => (variant === 'outlined' ? 160 : 250),
        height: '100%',
      },
      '&:hover button': {
        opacity: 1,
      },
    },
    deleteButton: {
      position: 'absolute',
      bottom: 6,
      right: 6,
      opacity: 0,
      backgroundColor: 'rgba(200, 200, 200, 0.7)',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        '& svg': {
          fill: theme.palette.white,
        },
      },
    },
    zoomButton: {
      position: 'absolute',
      top: 6,
      right: 6,
      opacity: 0,
      backgroundColor: 'rgba(200, 200, 200, 0.7)',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& svg': {
          fill: '#fff',
        },
      },
    },
    noResults: {
      width: '100%',
      height: '100%',
      color: theme.palette.text.disabled,
      textAlign: 'center',
    },
    noResultsIcon: {
      display: 'block',
      margin: '30px auto 0',
      fontSize: 64,
    },
    scrollContainer: {
      overflow: 'auto',
    }
  };
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 ${8}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgrey' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : '#fff',
  display: 'inline-flex',
  borderRadius: 4,
});

const Gallery = ({
  items,
  setItems,
  variant,
}) => {
  const classes = useStyles({ variant });
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const getBigImageFilename = (filepath) => {
    const filename = filepath.slice(0, filepath.lastIndexOf('.'));
    const extension = filepath.slice(filepath.lastIndexOf('.'));

    return `${filename}_big${extension}`;
  };

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    removed.order = endIndex;
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onAdd = (newFiles) => {
    setItems([
      ...items,
      ...newFiles,
    ]);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const onDelete = (file) => {
    const index = items.findIndex((item) => item === file);

    if (items[index].id) {
      items[index].deleted = true;
    } else {
      items = items.filter((item) => item !== file);
    }

    setItems([...items]);
  };

  const renderGallery = () => {
    return items?.length > 0 || variant === 'outlined' ? (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <Box className={classes.scrollContainer}>
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => !item.deleted && (
                  <Draggable key={item.file.name} draggableId={item.file.name} index={index}>
                    {(dProvided, dSnapshot) => (
                      <Box
                        ref={dProvided.innerRef}
                        {...dProvided.draggableProps}
                        {...dProvided.dragHandleProps}
                        style={getItemStyle(
                          dSnapshot.isDragging,
                          dProvided.draggableProps.style
                        )}
                        className={classes.image}
                      >
                        <img
                          alt=""
                          src={item.data}
                        />
                        <IconButton
                          className={classes.deleteButton}
                          onClick={() => onDelete(item)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          className={classes.zoomButton}
                          onClick={() => {
                            window.open(getBigImageFilename(item.data));
                          }}
                          size="small"
                        >
                          <ZoomIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {variant === 'outlined' && (
                  <Button
                    className={classes.addButton}
                    color="primary"
                    fullWidth
                    variant="text"
                    onClick={openFileUploadDialog}
                  >
                    <AddPhotoIcon />
                  </Button>
                )}
              </div>
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <Box
        width="100%"
        height={150}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.noResults}>
          <CloseIcon className={classes.noResultsIcon} />
          Brak elementów do wyświetlenia
        </Typography>
      </Box>
    );
  };

  const renderCard = () => (
    <Card>
      <CardHeader title="Galeria" />
      <Divider />
      <CardContent>
        {renderGallery()}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={openFileUploadDialog}
        >
          Dodaj zdjęcie
        </Button>
      </CardActions>
    </Card>
  );

  const renderOutlined = () => (
    <FieldSet title="Galeria">
      {renderGallery()}
    </FieldSet>
  );

  return (
    <>
      {variant === 'card' && renderCard()}
      {variant === 'outlined' && renderOutlined()}
      <FileUploadDialog
        handleClose={closeFileUploadDialog}
        filesLimit={10}
        isOpen={isFileUploadOpen}
        setFiles={onAdd}
      />
    </>
  );
};

Gallery.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setItems: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['card', 'outlined']),
};

Gallery.defaultProps = {
  items: [],
  variant: 'card',
};

export default Gallery;
