import React, { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'moment/locale/pl';

import authSelectors from 'src/store/selectors/auth';
import { getUser } from 'src/store/actions/auth';
import { getToken } from 'src/utils/jwt';

import theme from 'src/theme';
import routes from 'src/routes';

import GlobalStyles from 'src/components/GlobalStyles';
import Loading from 'src/components/Loading';

const App = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isInited, setIsInited] = useState(false);
  const isUserLogged = useSelector((state) => authSelectors.isUserLogged(state));
  const router = createBrowserRouter(routes(isUserLogged));

  moment.locale('pl');

  useEffect(() => {
    async function fetchUserData() {
      if (getToken('token')) {
        try {
          await dispatch(getUser());
        } catch {
          enqueueSnackbar(
            'Błąd autentykacji. Zaloguj się ponownie.',
            {
              variant: 'error',
            }
          );
        }
      }
      setIsInited(true);
    }

    fetchUserData();
  }, [dispatch, enqueueSnackbar]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {isInited ? <RouterProvider router={router} /> : <Loading />}
    </ThemeProvider>
  );
};

export default App;
