import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { green } from '@material-ui/core/colors';
import IconClose from '@material-ui/icons/Close';

import ChangeUserField from 'src/components/ChangeUserField';
import Gallery from 'src/components/Gallery';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {},
  rating: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EditCommentDialog({
  callback,
  comment,
  isOpen,
  setIsOpen,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [rate, setRate] = useState(0);
  const [content, setContent] = useState('');
  const [user, setUser] = useState(null);
  const [date, setDate] = useState(moment().format('yyyy-MM-DDTHH:mm'));
  const [galleryItems, setGalleryItems] = useState([]);

  const parseGallery = (files) => {
    const gallery = files.map((file) => {
      return {
        id: file.id,
        data: `${STORAGE_URL}/comments/${comment.id}/${file.filename}`,
        file: {
          name: file.filename,
        },
        order: file.order,
      };
    });

    return gallery;
  };

  useEffect(() => {
    setRate(comment.rate || 0);
    setContent(comment.content || '');
    if (comment.username) {
      setUser(comment.username);
    } else {
      setUser(comment.user || null);
    }
    setDate(moment(comment.created).format('yyyy-MM-DDTHH:mm') || moment().format('yyyy-MM-DDTHH:mm'));
    setGalleryItems(comment.files?.length > 0 ? parseGallery(comment.files) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    if (!isLoading && (rate > 0 || content !== '')) {
      try {
        setIsLoading(true);

        await callback({
          content,
          date,
          galleryItems,
          rate,
          user,
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">{comment?.id ? 'Edycja komentarza' : 'Dodaj komentarz'}</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <ChangeUserField
            customUser={user}
            error={content.length > 0 && !user}
            label="Autor"
            name="username"
            onChange={(event, value) => {
              setUser(value);
            }}
            rff={false}
          />
        </Box>
        <Box className={classes.rating}>
          <Rating
            onChange={(event) => {
              setRate(parseInt(event.target.value, 10));
            }}
            name="rate"
            size="large"
            value={rate}
          />
          {rate > 0 ? (
            <IconButton
              size="small"
              onClick={() => {
                setRate(0);
              }}
              style={{
                marginLeft: 12,
              }}
            >
              <IconClose />
            </IconButton>
          ) : (
            <Typography
              color="textSecondary"
              style={{
                marginLeft: 12,
              }}
              variant="caption"
            >
              Brak oceny
            </Typography>
          )}
        </Box>
        <TextField
          autoFocus
          fullWidth
          label="Komentarz"
          margin="dense"
          multiline
          onChange={(event) => {
            setContent(event.target.value);
          }}
          minRows={6}
          value={content}
          variant="outlined"
        />
        <Box mt={2}>
          <Gallery
            items={galleryItems}
            setItems={setGalleryItems}
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Data dodania"
            type="datetime-local"
            defaultValue={date}
            className={classes.textField}
            onChange={(event) => {
              setDate(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={isLoading}
          onClick={handleClose}
        >
          Anuluj
        </Button>
        <div className={classes.wrapper}>
          <Button
            autoFocus
            color="secondary"
            disabled={isLoading || !(rate > 0 || content !== '') || !user}
            onClick={handleSubmit}
            variant="contained"
          >
            {comment?.id ? 'Edytuj' : 'Dodaj'}
          </Button>
          {isLoading && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
}

EditCommentDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    created: PropTypes.string,
    files: PropTypes.array,
    rate: PropTypes.number,
    user: PropTypes.shape(),
    username: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

EditCommentDialog.defaultProps = {
  comment: {
    id: null,
    content: '',
    files: [],
    rate: 0,
    user: null,
  },
};
