import React from 'react';
import PropTypes from 'prop-types';
import { Checkboxes } from 'mui-rff';
import { FormSpy } from 'react-final-form';
import {
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    textAlign: 'center',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  cell: {
    whiteSpace: 'break-spaces'
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
  }
}));

const ChangesRow = ({
  children,
  label,
  showAll,
  name,
  place,
  suggestion,
}) => {
  const classes = useStyles();
  return (place !== suggestion || showAll) && (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <TableRow className={place === suggestion || !values[`accept_${name}`] ? classes.disabled : ''}>
          <TableCell className={classes.checkboxContainer}>
            {place !== suggestion && (
              <Checkboxes
                name={`accept_${name}`}
                data={[{ value: true }]}
              />
            )}
          </TableCell>
          <TableCell>
            {label}
          </TableCell>
          <TableCell className={classes.cell}>
            {place}
          </TableCell>
          <TableCell className={classes.cell}>
            {place !== suggestion && (
              <>
                {React.cloneElement(children, { disabled: !values[`accept_${name}`] })}
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </FormSpy>
  );
};

ChangesRow.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  showAll: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  place: PropTypes.any,
  suggestion: PropTypes.any,
};

ChangesRow.defaultProps = {
  place: null,
  suggestion: null,
};

export default ChangesRow;
