const initialState = {
  appInitialized: false,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default configReducer;
