import request from '../../utils/request';

const PREFIX = '[NEWS]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearNewsData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createNewsRequest = () => ({
  type: CREATE_REQUEST,
});

const createNEWSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createNewsFailure = () => ({
  type: CREATE_FAILURE,
});

export const createNews = (payload) => (dispatch) => {
  dispatch(createNewsRequest());

  return request
    .post('v1/news/', payload)
    .then(({ data }) => {
      dispatch(createNEWSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createNewsFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteNewsRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteNEWSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteNewsFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteNews = (id) => (dispatch) => {
  dispatch(deleteNewsRequest());

  return request
    .delete(`v1/news/${id}`)
    .then(({ data }) => {
      dispatch(deleteNEWSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteNewsFailure());

      throw error;
    });
};

export const GET_NEWS_REQUEST = `${PREFIX} GET_NEWS_REQUEST`;
export const GET_NEWS_SUCCESS = `${PREFIX} GET_NEWS_SUCCESS`;
export const GET_NEWS_FAILURE = `${PREFIX} GET_NEWS_FAILURE`;

const fetchNewsRequest = () => ({
  type: GET_NEWS_REQUEST,
});

const fetchNewsSuccess = (payload) => ({
  payload,
  type: GET_NEWS_SUCCESS,
});

const fetchNewsFailure = () => ({
  type: GET_NEWS_FAILURE,
});

export const fetchNews = (filters) => (dispatch) => {
  dispatch(fetchNewsRequest());

  return request
    .get('v1/news/', { params: { ...filters, isAdmin: true } })
    .then(({ data }) => {
      dispatch(fetchNewsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchNewsFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchNewsDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchNewsDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchNewsDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchNewsDetails = (id) => (dispatch) => {
  dispatch(fetchNewsDetailsRequest());

  return request
    .get(`v1/news/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchNewsDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchNewsDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateNewsRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateNEWSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateNewsFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateNews = (id, payload) => (dispatch) => {
  dispatch(updateNewsRequest());

  return request
    .put(`v1/news/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateNEWSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateNewsFailure());

      throw error;
    });
};
