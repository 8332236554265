export default [
  'title',
  'description',
  'type',
  'country',
  'region',
  'groundType',
  'accessType',
  'accessFor',
  'capacity',
  'cost',
  'heightLimit',
  'gsmSignal',
  'shed',
  'benches',
  'tables',
  'trashcan',
  'tent',
  'bonfire',
  'combustible',
  'grill',
  'wc',
  'shower',
  'water',
  'carWc',
  'dirtWater',
  'electricity',
  'wifi',
  'other_infrastructure',
  'prohibitions',
  'advantages',
  'priceList',
  // 'camping_category',
  'camping_atmosphere',
  'camping_duration',
  'camping_groundType',
  'camping_pets',
  'camping_gsmSignal',
  'camping_wc',
  'camping_shower',
  'camping_kitchen',
  'camping_commonRoom',
  'camping_laundry',
  'camping_shed',
  'camping_bonfire',
  'camping_playground',
  'camping_shop',
  'camping_restaurant',
  'camping_pool',
  'camping_wifi',
  'caravaning_wc',
  'caravaning_dirtWater',
  'caravaning_fillWater',
  'caravaning_electricity',
  'caravaning_water',
  'caravaning_guest',
  'other_infrastructure',
  'tourist_attraction',
  'service',
  'email',
  'phone',
  'website',
  'facebook',
  'streetViewUrl',
];
