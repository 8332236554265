import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

const AlertDialog = forwardRef(
  (
    {
      actions,
      children,
      title,
      message,
      isOpen: isInitialOpen,
    },
    ref,
  ) => {
    const useStyles = makeStyles({
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
    });
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(isInitialOpen);

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
      isOpen,
    }));

    return (
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={isOpen}
        onClose={close}
      >
        {title && (
          <DialogTitle>{title}</DialogTitle>
        )}
        {message && (
          <DialogContent>
            <DialogContentText>
              {message}
            </DialogContentText>
            {children}
          </DialogContent>
        )}
        <DialogActions>
          {actions.map(({
            className,
            color,
            disabled,
            label,
            loading,
            onClick,
            ...action
          }, index) => (
            <Button
              color={color || 'primary'}
              className={className}
              disabled={disabled}
              onClick={onClick || close}
              key={`action-${index}`} // eslint-disable-line react/no-array-index-key
              {...action}
            >
              {label || 'Wróć'}
              {loading && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }
);

AlertDialog.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      color: PropTypes.string,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      loading: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
};

AlertDialog.defaultProps = {
  children: null,
  title: null,
  message: null,
  isOpen: false,
  actions: [],
};

export default AlertDialog;
