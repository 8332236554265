import React from 'react';

const MarinaIcon = (props) => {
  return (
    <img
      alt="Marina"
      src="/static/images/icons/tourist_attractions/marina.svg"
      {...props}
    />
  );
};

export default MarinaIcon;
