import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/PersonPinCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blue[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.blue[900]
  },
  differenceValue: {
    color: colors.blue[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCheckIns = ({ total }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ZAMELDOWAŃ
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PlaceIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalCheckIns.propTypes = {
  total: PropTypes.number,
};

export default TotalCheckIns;
