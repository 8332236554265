import React, {
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import slugify from 'slugify';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import {
  Box,
  CircularProgress,
  Container,
  Fab,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ContactIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  clearPlacesData,
  createPlace,
  deletePlace,
  fetchClosePlaces,
  fetchPlaceCheckIns,
  fetchPlaceComments,
  fetchPlaceDetails,
  sendQuestionEmail,
  updatePlaceDetails,
} from 'src/store/actions/places';
import placesSelectors from 'src/store/selectors/places';

import {
  SLUGIFY_REGEX,
  STREETVIEW_REGEXP,
  URL_REGEXP,
  YOUTUBE_REGEXP
} from 'src/utils/consts';
import {
  campingTypeValues,
  placeTypeValues,
} from 'src/utils/enums';

import AlertDialog from 'src/components/AlertDialog';
import CheckIns from 'src/components/CheckIns';
import Comments from 'src/components/Comments';
import Gallery from 'src/components/Gallery';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

import Map from './Map';
import ClosePlaces from './ClosePlaces';
import ServiceDetails from './ServiceDetails';
import Rate from './Rate';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
  contactButton: {
    bottom: 92,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    transform: 'scale(0)',
  },
  contactButtonVisible: {
    transform: 'scale(1)',
  },
}));

// Validation
const schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  country: Yup.string().nullable()
    .required('Pole jest wymagane'),
  region: Yup.string().nullable()
    .when('country', {
      is: (val) => val === 'PL',
      then: Yup.string()
        .nullable()
        .required('Pole jest wymagane'),
    }),
  latitude: Yup.string().nullable()
    .min(5)
    .required('Pole jest wymagane'),
  longitude: Yup.string().nullable()
    .min(5)
    .required('Pole jest wymagane'),
  description: Yup.string().nullable()
    .required('Pole jest wymagane'),
  language: Yup.string().nullable()
    .required('Pole jest wymagane'),
  service: Yup.string().nullable()
    .notOneOf(['undefined'])
    .required('Pole jest wymagane'),
  streetViewUrl: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(STREETVIEW_REGEXP, 'Niepoprawny adres StreetView'),
  youTubeUrl: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(YOUTUBE_REGEXP, 'Niepoprawny adres YouTube'),
  email: Yup.string()
    .email('Niepoprawny adres Email'),
  website: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(URL_REGEXP, 'Niepoprawny adres URL'),
  facebook: Yup.string().nullable()
    .transform((value) => (!value ? null : value))
    .matches(URL_REGEXP, 'Niepoprawny adres URL'),
  discount_content: Yup.string().nullable()
    .when('discount', {
      is: (val) => val === true,
      then: Yup.string()
        .nullable()
        .required('Pole jest wymagane'),
    }),
  slug: Yup.string().nullable()
    .required('Pole jest wymagane'),
  status: Yup.string().nullable()
    .required('Pole jest wymagane'),
});
const validate = makeValidate(schema);

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const ServiceDetailsView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const acceptAlertRef = useRef();
  const contactAlertRef = useRef();
  const deleteAlertRef = useRef();
  const editAlertRef = useRef();
  const visibilityAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const [emailMessage, setEmailMessage] = useState('');
  const [galleryItems, setGalleryItems] = useState([]);
  const [isMapDraggable, setMapDraggable] = useState(false);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [verifiedAlertConfirm, setVerifiedAlertConfirm] = useState(false);
  const [isClosePlacesVisible, setIsClosePlacesVisible] = useState(false);

  const place = useSelector((state) => placesSelectors.getPlaceDetails(state));
  const isFetching = useSelector((state) => placesSelectors.getPlaceDetailsIsFetching(state));
  const closePlaces = useSelector((state) => placesSelectors.getClosePlaces(state));
  const closePlacesIsFetching = useSelector((state) => placesSelectors
    .getClosePlacesIsFetching(state));

  const parseGallery = (files) => {
    const gallery = files.map((file) => {
      return {
        id: file.id,
        data: `${STORAGE_URL}/places/${id}/${file.filename}`,
        file: {
          name: file.filename,
        },
        order: file.order,
      };
    });

    setGalleryItems(gallery);
  };

  const getClosePlaces = (latitude, longitude) => {
    dispatch(fetchClosePlaces(latitude, longitude, 1));
  };

  const toggleClosePlaces = () => {
    setIsClosePlacesVisible(!isClosePlacesVisible);
  };

  useEffect(() => {
    if (action === 'edit') {
      const fetchData = async () => {
        const data = await dispatch(fetchPlaceDetails(id));
        if (data) {
          // Redirect if type is wrong
          if (!window.location.href.endsWith('?') && data.type !== 'service') {
            if (placeTypeValues.some((type) => type.value === data.type)) {
              navigate(`/place/${data.id}`, { replace: true });
            } else if (campingTypeValues.some((type) => type.value === data.type)) {
              navigate(`/camping/${data.id}`, { replace: true });
            } else if (data.type === 'tourist_attraction') {
              navigate(`/tourist-attraction/${data.id}`, { replace: true });
            }

            return;
          }

          parseGallery(data.files);
          getClosePlaces(data.latitude, data.longitude);
        }
      };

      fetchData()
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message || 'Wystąpił błąd podczas pobierania danych', { variant: 'error' });
        });
    }

    return () => {
      dispatch(clearPlacesData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, dispatch, enqueueSnackbar, id]);

  const createSlug = (title) => {
    return slugify(title, {
      lower: true,
      remove: SLUGIFY_REGEX,
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    if (
      !verifiedAlertConfirm && (
        values.status === 'unverified'
        || values.status === 'hidden'
      )
    ) {
      visibilityAlertRef.current.open();
      return false;
    }

    if (isMapDraggable) {
      editAlertRef.current.open();
      return false;
    }

    if (!['unverified', 'in_review'].includes(values.status)) {
      setSendConfirmationEmail(false);
    }

    const placeData = new FormData();

    if (values.country !== 'PL') {
      values.region = null;
    }
    values.type = 'service';
    values.description = values.description || '';
    values.streetViewUrl = values.streetViewUrl || '';
    values.youTubeUrl = values.youTubeUrl || '';
    values.slug = createSlug(values.slug);

    if (values.youTubeUrl !== '') {
      values.youTubeUrl = values.youTubeUrl.replace(/(&t=\d+s|\?t=\d+)/, '');
    }

    if (values.creator) {
      if (typeof values.creator === 'number') {
        values.userId = values.creator;
        values.username = null;
      } else if (typeof values.creator === 'string') {
        values.userId = null;
        values.username = values.creator;
      } else if (values.creator.id === null) {
        values.userId = null;
        values.username = values.creator.username;
      } else {
        values.userId = values.creator.id;
        values.username = null;
      }
    } else {
      values.userId = null;
      values.username = null;
    }

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      placeData.append(key, value);
    });

    if (galleryItems.length > 0) {
      const filesToRemove = [];

      galleryItems.forEach((item) => {
        if (!item.deleted) {
          if (item.file.path) {
            placeData.append('gallery', item.file);
          }
        } else {
          filesToRemove.push(item.id);
        }
      });

      placeData.append('galleryOrder', galleryItems.map(({ deleted, file }) => !deleted && file.name));

      if (filesToRemove.length > 0) {
        placeData.append('filesToRemove', filesToRemove.join(','));
      }
    }

    if (sendConfirmationEmail) {
      placeData.append('sendConfirmationEmail', true);
      placeData.append('message', emailMessage);
      setEmailMessage('');
    }

    try {
      if (action === 'edit') {
        const data = await dispatch(updatePlaceDetails(id, placeData));
        parseGallery(data.files);

        setVerifiedAlertConfirm(false);

        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createPlace(placeData));
        enqueueSnackbar('Dodano nowe miejsce', { variant: 'success' });

        setTimeout(() => {
          navigate('/services');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const sendEmail = async () => {
    try {
      await dispatch(sendQuestionEmail(place, emailMessage));
      enqueueSnackbar('Wiadomość została wysłana', { variant: 'success' });
      setEmailMessage('');

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania wiadomości', { variant: 'error' });

      return error;
    }
  };

  const onEmailMessageChange = (event) => {
    setEmailMessage(event.target.value);
  };

  const onDelete = async (reason) => {
    try {
      await dispatch(deletePlace(id, reason ? emailMessage : null));
      enqueueSnackbar('Miejsce zostało usunięte', { variant: 'success' });

      setTimeout(() => {
        if (['unverified', 'in_review'].includes(place.status)) {
          navigate('/unverified');
        } else {
          navigate('/services');
        }
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        place ? {
          title: place.title,
          description: place.description,
          language: place.language,
          latitude: place.latitude,
          longitude: place.longitude,
          country: place.country,
          region: place.region && place.region !== 'null' ? parseInt(place.region, 10) : '',
          service: place.service,
          email: place.email,
          phone: place.phone,
          website: place.website,
          facebook: place.facebook,
          streetViewUrl: place.streetViewUrl,
          youTubeUrl: place.youTubeUrl,
          discount: place.discount,
          discount_content: place.discount_content,
          creator: place.user ? place.user.id : place.username || '',
          slug: place.slug,
          status: place.status,
        } : {
          status: 'active',
        }
      }
      keepDirtyOnReinitialize
      mutators={{
        setLatLngValues: (args, state, utils) => {
          utils.changeValue(state, 'latitude', () => args[0].lat);
          utils.changeValue(state, 'longitude', () => args[0].lng);
        },
        setSlug: (args, state, utils) => {
          const slug = createSlug(args[0]);
          if (action === 'add' && !state.fields.slug.modified) {
            utils.changeValue(state, 'slug', () => slug);
          }
        },
        setStatus: (args, state, utils) => {
          const status = createSlug(args[0]);
          utils.changeValue(state, 'status', () => status);
        },
      }}
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={validate}
      render={({
        dirty,
        form,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja miejsca serwisowego' : 'Dodaj nowe miejsce serwisowe',
            hasBackButton: true,
            backTo: '/services',
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja miejsca serwisowego${place ? `: ${place.title}` : ''}`
              : 'Dodaj nowe miejsce serwisowe'
          }
        >
          {(action === 'add' || place) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  direction="row-reverse"
                  spacing={3}
                >
                  <Grid
                    item
                    lg={8}
                    md={6}
                    xs={12}
                  >
                    <ServiceDetails
                      data={place}
                      setSlug={form.mutators.setSlug}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <Box>
                      <Map
                        data={place}
                        isMapDraggable={isMapDraggable}
                        setMapDraggable={setMapDraggable}
                        setLatLngValues={form.mutators.setLatLngValues}
                        getClosePlaces={getClosePlaces}
                        closePlaces={closePlaces}
                        isClosePlacesVisible={isClosePlacesVisible}
                      />
                    </Box>
                    {action === 'edit' && (
                      <>
                        <Box mt={3}>
                          <FormSpy
                            subscription={{
                              values: true,
                            }}
                          >
                            {({ values }) => (
                              <ClosePlaces
                                latitude={values.latitude}
                                longitude={values.longitude}
                                data={closePlaces}
                                loading={closePlacesIsFetching}
                                isClosePlacesVisible={isClosePlacesVisible}
                                toggleClosePlaces={toggleClosePlaces}
                              />
                            )}
                          </FormSpy>
                        </Box>
                        <Box mt={3}>
                          <Rate data={place} />
                        </Box>
                        <Box mt={3}>
                          <CheckIns
                            fetchData={fetchPlaceCheckIns}
                            limit={10}
                            selectors={placesSelectors}
                            showPlace={false}
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Gallery
                    items={galleryItems}
                    setItems={setGalleryItems}
                  />
                </Box>
                {action === 'edit' && (
                  <Box mt={3}>
                    <Comments
                      fetchData={fetchPlaceComments}
                      selectors={placesSelectors}
                      showPlace={false}
                    />
                  </Box>
                )}
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <>
                      <Tooltip
                        placement="left"
                        title="Skontaktuj się z autorem"
                      >
                        <Fab
                          aria-label="contact"
                          className={clsx(
                            classes.floatingActionButton,
                            classes.contactButton,
                            ['unverified', 'in_review'].includes(values.status) && classes.contactButtonVisible,
                          )}
                          type="button"
                          onClick={() => {
                            contactAlertRef.current.open();
                          }}
                        >
                          <ContactIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={getSubmitLabel(values.status)}
                      >
                        <Fab
                          aria-label="save"
                          className={clsx(
                            classes.floatingActionButton,
                            values.status === 'deleted' && classes.deleteButton,
                          )}
                          color="secondary"
                          disabled={submitting || isFetching}
                          onClick={() => {
                            if (action === 'edit' && (['unverified', 'in_review'].includes(place.status) && values.status === 'active')) {
                              acceptAlertRef.current.open();
                            } else {
                              form.submit();
                            }
                          }}
                          type="button"
                        >
                          {!submitting && !isFetching
                            ? getSubmitIcon(values.status)
                            : <CircularProgress color="secondary" size={26} />}
                        </Fab>
                      </Tooltip>
                    </>
                  )}
                </FormSpy>
              </Container>
              <ReactRouterPrompt when={dirty && !submitSucceeded}>
                {({ isActive, onConfirm, onCancel }) => (
                  <AlertDialog
                    title="Nie zapisano zmian"
                    message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                    isOpen={isActive}
                    actions={[
                      {
                        label: 'Wyjdź bez zapisywania',
                        onClick: onConfirm,
                      },
                      {
                        autoFocus: true,
                        label: 'Wróć',
                        variant: 'contained',
                        onClick: onCancel,
                      },
                    ]}
                  />
                )}
              </ReactRouterPrompt>
              <AlertDialog
                title="Nie zapisano nowej lokalizacji"
                message="Wykryto zmianę lokalizacji miejsca, ale nie kliknięto w &quot;Zapisz nową lokalizację&quot;."
                ref={editAlertRef}
                actions={[
                  {
                    autoFocus: true,
                    label: 'Zapisz bez zmiany lokalizacji',
                    onClick: () => {
                      editAlertRef.current.close();
                      setMapDraggable(false);

                      setTimeout(() => {
                        form.submit();
                      });
                    },
                    type: 'submit',
                  },
                  {
                    label: 'Wróć',
                    variant: 'contained'
                  },
                ]}
              />
              <AlertDialog
                title="Czy wysłać powiadomienie o akceptacji miejsca?"
                message="Użytkownik zostanie poinformowany, że jego miejsce zostało akceptowane. Jeśli chcesz, możesz dodać wiadomość, która zostanie dołączona do powiadomienia (nieobowiązkowe)."
                ref={acceptAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    autoFocus: true,
                    disabled: isFetching,
                    label: 'Zapisz bez wysyłania powiadomienia',
                    loading: isFetching,
                    onClick: async () => {
                      acceptAlertRef.current.close();

                      setTimeout(() => {
                        form.submit();
                      });
                    },
                    type: 'submit',
                  },
                  {
                    autoFocus: true,
                    disabled: isFetching,
                    label: 'Zapisz',
                    loading: isFetching,
                    onClick: async () => {
                      acceptAlertRef.current.close();

                      setSendConfirmationEmail(true);
                      setTimeout(() => {
                        form.submit();
                        setSendConfirmationEmail(false);
                      });
                    },
                    type: 'submit',
                    variant: 'contained',
                  },
                ]}
              >
                <Box mt={3}>
                  <TextField
                    fullWidth
                    label="Wiadomość dla użytkownika"
                    multiline
                    onChange={onEmailMessageChange}
                    minRows={5}
                    variant="outlined"
                    value={emailMessage}
                  />
                </Box>
              </AlertDialog>
              <AlertDialog
                title="Czy na pewno miejsce ma być nieaktywne?"
                message="Miejsce będzie niewidoczne dla użytkowników, dopóki nie ustawiony zostanie status &quot;Widoczny&quot;."
                ref={visibilityAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    autoFocus: true,
                    label: 'Zapisz',
                    onClick: () => {
                      visibilityAlertRef.current.close();
                      setVerifiedAlertConfirm(true);

                      setTimeout(() => {
                        form.submit();
                      });
                    },
                    type: 'submit',
                    variant: 'contained',
                  },
                ]}
              />
              <AlertDialog
                title="Wyślij zapytanie do autora"
                message="Jeśli przed akceptacją potrzebujesz skontaktować się z autorem miejsca, wyślij do niego wiadomość."
                ref={contactAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    autoFocus: true,
                    disabled: isFetching || emailMessage.length === 0,
                    label: 'Wyślij',
                    loading: isFetching,
                    onClick: async () => {
                      if (emailMessage.length > 0) {
                        await sendEmail();
                        form.mutators.setStatus('in_review');
                        contactAlertRef.current.close();
                      }
                    },
                    type: 'submit',
                    variant: 'contained',
                  },
                ]}
              >
                <Box mt={3}>
                  <TextField
                    fullWidth
                    label="Wiadomość dla użytkownika"
                    multiline
                    onChange={onEmailMessageChange}
                    minRows={5}
                    variant="outlined"
                    value={emailMessage}
                  />
                </Box>
                <Box mt={1}>
                  {place?.user?.country !== 'PL' && (
                    <Box mb={1}>
                      <Typography color="error">
                        <strong>
                          Użytkownik nie ma podanego kraju, lub ma podany inny kraj niż Polski.
                        </strong>
                        <br />
                        Upewnij się, że wiadomość wysyłana jest w odpowiednim języku.
                        Najbezpieczniej będzie w tym wypadku po angielsku.
                      </Typography>
                    </Box>
                  )}
                  <Typography color="textSecondary">
                    Odpowiedź przyjdzie na adres
                    {' '}
                    <strong>aplikacja@grupabiwakowa.pl</strong>
                  </Typography>
                </Box>
              </AlertDialog>
              <AlertDialog
                title="Czy na pewno chcesz usunąć to miejsce?"
                message={(action === 'edit' && (['unverified', 'in_review'].includes(place.status))) && 'Użytkownik zostanie poinformowany, że jego miejsce zostało odrzucone.'}
                ref={deleteAlertRef}
                actions={
                  (action === 'edit' && (['unverified', 'in_review'].includes(place.status))) ? [
                    {
                      label: 'Wróć',
                    },
                    {
                      disabled: isFetching,
                      label: 'Usuń bez wysyłania powiadomienia',
                      loading: isFetching,
                      onClick: () => {
                        onDelete();
                        deleteAlertRef.current.close();
                      },
                    },
                    {
                      autoFocus: true,
                      className: classes.deleteButton,
                      disabled: isFetching || emailMessage.length === 0,
                      label: 'Usuń',
                      loading: isFetching,
                      onClick: async () => {
                        onDelete(true);
                        deleteAlertRef.current.close();
                      },
                      type: 'submit',
                      variant: 'contained',
                    },
                  ] : [
                    {
                      label: 'Wróć',
                    },
                    {
                      autoFocus: true,
                      className: classes.deleteButton,
                      disabled: isFetching,
                      label: 'Usuń',
                      loading: isFetching,
                      onClick: async () => {
                        onDelete();
                        deleteAlertRef.current.close();
                      },
                      type: 'submit',
                      variant: 'contained',
                    },
                  ]
                }
              >
                <Box mt={3}>
                  <TextField
                    fullWidth
                    label="Powód odrzucenia"
                    multiline
                    onChange={onEmailMessageChange}
                    minRows={5}
                    variant="outlined"
                    value={emailMessage}
                  />
                </Box>
              </AlertDialog>
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

ServiceDetailsView.propTypes = {
  action: PropTypes.string,
};

ServiceDetailsView.defaultProps = {
  action: 'add',
};

export default ServiceDetailsView;
