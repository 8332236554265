import React from 'react';

const BunkerIcon = (props) => {
  return (
    <img
      alt="Bunkier"
      src="/static/images/icons/tourist_attractions/bunker.svg"
      {...props}
    />
  );
};

export default BunkerIcon;
