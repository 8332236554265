import React from 'react';

const ArcheologyIcon = (props) => {
  return (
    <img
      alt="Stanowisko archeologiczne"
      src="/static/images/icons/tourist_attractions/archeology.svg"
      {...props}
    />
  );
};

export default ArcheologyIcon;
