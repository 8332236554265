import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const CustomNavLink = forwardRef(({ isActive: isProbablyActive, ...props }, ref) => {
  const classes = useStyles();

  return (
    <NavLink
      ref={ref}
      {...props}
      className={
        ({ isActive }) => clsx((isActive || isProbablyActive) && classes.active, props.className)
      }
      end
    />
  );
});

const NavItem = ({
  className,
  href,
  icon: Icon,
  onClick,
  title,
  isActive,
  ...rest
}) => {
  const classes = useStyles();

  let props = {
    className: classes.button
  };

  if (onClick) {
    props = {
      ...props,
      onClick,
    };
  } else {
    props = {
      ...props,
      component: CustomNavLink,
      to: href,
      isActive,
    };
  }

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button {...props}>
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

NavItem.defaultProps = {
  className: null,
  href: null,
  onClick: null,
  isActive: false,
};

CustomNavLink.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

CustomNavLink.defaultProps = {
  className: null,
  isActive: false,
};

export default NavItem;
