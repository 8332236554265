export default {
  getFilters: (state) => state.articles.filters,

  getArticleCategoryList: (state) => state.articles.categories.list,
  getArticleCategoryDetails: (state) => state.articles.categories.details,
  getArticleCategoryIsFetching: (state) => state.articles.categories.isFetching,

  getArticleDetails: (state) => state.articles.details.data,
  getArticleDetailsIsFetching: (state) => state.articles.details.isFetching,

  getArticles: (state) => state.articles.list.items,
  getArticlesTotalCount: (state) => state.articles.categories.totalCount,
  getArticlesPaginationData: (state) => state.articles.list.meta,
  getArticlesIsFetching: (state) => state.articles.list.isFetching,
};
