import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  border: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '18.5px 14px',
    borderRadius: '4px',
  },
  title: {
    position: 'absolute',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '17px',
    transform: 'translate(-6px, -15px) scale(0.75)',
  }
}));

const FieldSet = ({
  children,
  title,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.title}
        variant="caption"
      >
        {title}
      </Typography>
      <Box className={classes.border}>
        {children}
      </Box>
    </Box>
  );
};

FieldSet.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

FieldSet.defaultProps = {
  title: null,
};

export default FieldSet;
