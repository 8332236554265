import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  CardHeader,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import commentsSelectors from 'src/store/selectors/comments';

import TableLoading from 'src/components/TableLoading';
import CommentItem from 'src/components/CommentItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteCommentModal,
  openEditCommentModal
}) => {
  const classes = useStyles();

  const comments = useSelector((state) => commentsSelectors.getComments(state));
  const isFetching = useSelector((state) => commentsSelectors.getCommentsIsFetching(state));
  const pagination = useSelector((state) => commentsSelectors.getCommentsPaginationData(state));

  return (
    <Card>
      <CardHeader title="Komentarze" />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={250}>
                  Miejsce
                </TableCell>
                <TableCell width={250}>
                  Użytkownik
                </TableCell>
                <TableCell width={152}>
                  Ocena
                </TableCell>
                <TableCell>
                  Komentarz
                </TableCell>
                <TableCell width={200}>
                  Data dodania
                </TableCell>
                <TableCell width={130} />
              </TableRow>
            </TableHead>
            {comments && (
              <TableBody>
                {comments.length > 0 ? comments.map((comment) => (
                  <CommentItem
                    key={comment.id}
                    comment={comment}
                    openDeleteCommentModal={openDeleteCommentModal}
                    openEditCommentModal={openEditCommentModal}
                    showActions
                    showPlace
                    showUser
                  />
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={5}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak komentarzy
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
      <Divider />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteCommentModal: PropTypes.func.isRequired,
  openEditCommentModal: PropTypes.func.isRequired,
};

export default Results;
