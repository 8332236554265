import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormSpy } from 'react-final-form';
import {
  Autocomplete,
  Checkboxes,
  KeyboardDatePicker,
  TextField,
} from 'mui-rff';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmailIcon from '@material-ui/icons/Mail';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/PhotoCamera';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import WebsiteIcon from '@material-ui/icons/Language';

import {
  codes as countriesCodes,
  labels as countriesLabels,
} from 'src/utils/countries';
import parseBoolean from 'src/utils/parseBoolean';
import countryToFlag from 'src/utils/countryToFlag';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
}));

const ProfileDetails = ({ data }) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja użytkownika: ${data.username} [ID: #${data.id}]` : 'Dodaj nowego użytkownika'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              label="Nazwa użytkownika"
              name="username"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              label="Email"
              name="email"
              required
              type="email"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Biogram"
              multiline
              name="bio"
              minRows={3}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />
                  </InputAdornment>
                ),
              }}
              label="Miasto"
              name="city"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
              {({ errors, submitFailed }) => (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.countryIcon,
                  }}
                  disableClearable
                  getOptionLabel={(option) => countriesLabels[option] || ''}
                  name="country"
                  options={countriesCodes}
                  renderOption={(option) => (option !== '' ? (
                    <>
                      <span>{countryToFlag(option)}</span>
                      &nbsp;
                      {countriesLabels[option]}
                    </>
                  ) : '')}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <PublicIcon className={classes.countryFiledIcon} />
                          </InputAdornment>
                        ),
                      }}
                      error={submitFailed && !!errors?.country}
                      helperText={submitFailed && errors?.country && errors?.country[0]}
                      label="Kraj"
                      required
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon />
                  </InputAdornment>
                ),
              }}
              label="Facebook"
              name="facebook"
              type="url"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon />
                  </InputAdornment>
                ),
              }}
              label="Instagram"
              name="instagram"
              type="url"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WebsiteIcon />
                  </InputAdornment>
                ),
              }}
              label="Strona internetowa"
              name="website"
              type="url"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}

          >
            <Box mb={2}>
              <Grid
                container
                direction="row"
                alignItems="center"
              >
                <TextField
                  fieldProps={{
                    parse: parseBoolean,
                  }}
                  fullWidth
                  label="Konto premium"
                  name="premium"
                  select
                  required
                  variant="outlined"
                >
                  <MenuItem value="true">
                    Tak
                  </MenuItem>
                  <MenuItem value="false">
                    Nie
                  </MenuItem>
                </TextField>
              </Grid>
            </Box>
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                direction="row"
              >
                <FormSpy
                  subscription={{
                    values: true,
                  }}
                >
                  {({ values }) => (
                    <>
                      <Checkboxes
                        name="hasPremiumExpireDate"
                        disabled={!values.premium}
                        data={[{ value: true }]}
                        style={{ marginTop: 7, marginLeft: 2, marginRight: -8 }}
                      />
                      <Box flex={1}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={!values.premium || !values.hasPremiumExpireDate}
                          format="yyyy-MM-dd"
                          label="Data wygaśnięcia konta premium"
                          name="premiumExpireDate"
                          minDate={new Date()}
                          dateFunsUtils={DateFnsUtils}
                          inputVariant="outlined"
                        />
                      </Box>
                    </>
                  )}
                </FormSpy>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              <MenuItem value="active">
                Aktywny
              </MenuItem>
              <MenuItem value="inactive">
                Nieaktywny
              </MenuItem>
              <MenuItem value="administrator">
                Administrator
              </MenuItem>
              {data && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      {(data.facebookId || data.appleId || data.googleId) && (
        <>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={10}
              >
                {data.facebookId && (
                  <Typography
                    component="p"
                    variant="caption"
                  >
                    Konto powiązane z Facebookiem
                  </Typography>
                )}
                {data.appleId && (
                  <Typography
                    component="p"
                    variant="caption"
                  >
                    Konto powiązane z Apple ID
                  </Typography>
                )}
                {data.googleId && (
                  <Typography
                    component="p"
                    variant="caption"
                  >
                    Konto powiązane z Google
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Zarejestrowany
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnie logowanie:
                {' '}
                {data.lastLogin
                  ? moment(data.lastLogin).startOf('minute').fromNow()
                  : 'Nigdy'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

ProfileDetails.propTypes = {
  data: PropTypes.shape({
    bio: PropTypes.string,
    created: PropTypes.string,
    facebook: PropTypes.string,
    id: PropTypes.number,
    instagram: PropTypes.string,
    lastLogin: PropTypes.string,
    premium: PropTypes.bool,
    state: PropTypes.string,
    username: PropTypes.string,
    website: PropTypes.string,
    facebookId: PropTypes.string,
    appleId: PropTypes.string,
    googleId: PropTypes.string,
  }),
};

ProfileDetails.defaultProps = {
  data: null,
};

export default ProfileDetails;
