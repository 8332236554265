import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify/slugify';
import moment from 'moment';
import { Field, FormSpy } from 'react-final-form';
import { Autocomplete, TextField } from 'mui-rff';
import 'date-fns';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import countryToFlag from 'src/utils/countryToFlag';
import { adStatusValues, adTypeValues } from 'src/utils/enums';
import {
  codes as supportedLocalesCodes,
  labels as supportedLocalesLabels,
} from 'src/utils/locales';
import ImageBox from './ImageBox';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  tab: {
    minHeight: 44
  }
}));

const AdDetails = ({
  data,
  imageFile,
  setExpiryDate,
  setImageFile,
  setSlug
}) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja reklamy: ${data.title ?? ''} [ID: #${data.id}]` : 'Dodaj nową reklamę'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Tytuł"
              name="title"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="URL"
              name="url"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              className={classes.typeSelect}
              fullWidth
              label="Typ"
              name="type"
              required
              select
              disabled={!!data}
              variant="outlined"
            >
              <MenuItem
                key=""
                value=""
              />
              {adTypeValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
              {({ errors, submitFailed }) => (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.countryIcon,
                  }}
                  disableClearable
                  getOptionLabel={(option) => supportedLocalesLabels[option] || 'Wszystkie'}
                  name="language"
                  options={['', ...supportedLocalesCodes]}
                  renderOption={(option) => (option !== '' ? (
                    <>
                      <span>{countryToFlag(option)}</span>
                      {' '}
                      {supportedLocalesLabels[option]}
                    </>
                  ) : 'Wszystkie')}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      className={classes.descriptionLanguageField}
                      error={submitFailed && !!errors?.language}
                      helperText={submitFailed && errors?.language && errors?.language[0]}
                      label="Język"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormSpy>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <ImageBox data={data} file={imageFile} setFile={setImageFile} />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              name="expiryDate"
              label="Data zakończenia"
              type="date"
              className={classes.textField}
              onChange={(event) => {
                setExpiryDate(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              type="number"
              name="expiryViews"
              label="Limit wyświetleń"
              className={classes.textField}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: SLUGIFY_REGEX });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                  label="Slug"
                  name="slug"
                  required
                  disabled={data && data.type === 'element'}
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              {adStatusValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              {data && data.type !== 'element' && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Wyświetleń:
                {' '}
                {data.views}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnia edycja:
                {' '}
                {moment(data.updated).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

AdDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.oneOf(adTypeValues.map((option) => option.value)).isRequired,
    status: PropTypes.oneOf(adStatusValues.map((option) => option.value)).isRequired,
    expiryDate: PropTypes.string,
    expiryViews: PropTypes.number,
    views: PropTypes.number,
    created: PropTypes.string,
    updated: PropTypes.string,
  }),
  imageFile: PropTypes.array,
  setExpiryDate: PropTypes.func.isRequired,
  setImageFile: PropTypes.func.isRequired,
  setSlug: PropTypes.func.isRequired,
};

AdDetails.defaultProps = {
  imageFile: null,
  data: null,
};

export default AdDetails;
