import React, { useEffect } from 'react';
import {
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Container,
  makeStyles
} from '@material-ui/core';

import { fetchDiscounts } from 'src/store/actions/discounts';

import Page from 'src/components/Page';
import Results from '../components/Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DiscountsListView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page') || 1, 10);
  const rowsPerPage = parseInt(searchParams.get('limit') || 10, 10);

  const fetchData = async () => {
    try {
      await dispatch(fetchDiscounts({
        page: currentPage,
        limit: rowsPerPage,
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangePage = (event, value) => {
    const params = {
      page: value + 1,
      limit: rowsPerPage,
    };

    setSearchParams(params);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;

    const params = {
      page: currentPage,
      limit: value,
    };

    setSearchParams(params);
  };

  return (
    <Page
      className={classes.root}
      title="Miejsca honorujące kartę Grupy Biwakowej"
    >
      <Container maxWidth={false}>
        <Results
          handleChangePage={handleChangePage}
          handleLimitChange={handleLimitChange}
        />
      </Container>
    </Page>
  );
};

export default DiscountsListView;
