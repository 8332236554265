import React, {
  createContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

export const appBarDefaultProps = {
  backTo: null,
  hasBackButton: false,
  isOpenMobile: false,
  onBack: null,
  title: 'Grupa Biwakowa',
};

export const AppBarContext = createContext();

export const AppBarProvider = ({ children }) => {
  const [appBarProps, setProps] = useState(appBarDefaultProps);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const setAppBarProps = (props) => {
    const newProps = {
      ...appBarDefaultProps,
      ...props,
    };

    if (!isEqual(appBarProps, newProps)) {
      setProps(newProps);
    }
  };

  return useMemo(() => (
    <AppBarContext.Provider
      value={{
        ...appBarProps,
        isMobileMenuOpen,
        setAppBarProps,
        setMobileMenuOpen,
      }}
    >
      {children}
    </AppBarContext.Provider>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    appBarProps.title,
    appBarProps.hasBackButton,
    appBarProps.onBack,
    isMobileMenuOpen,
  ]);
};

AppBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
