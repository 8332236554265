export default {
  getFilters: (state) => state.pages.filters,

  getPageDetails: (state) => state.pages.details.data,
  getPageDetailsIsFetching: (state) => state.pages.details.isFetching,

  getPages: (state) => state.pages.list.items,
  getPagesPaginationData: (state) => state.pages.list.meta,
  getPagesIsFetching: (state) => state.pages.list.isFetching,
};
