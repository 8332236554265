import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FormSpy,
} from 'react-final-form';
import moment from 'moment';
import slugify from 'slugify';
import {
  Autocomplete,
  Checkboxes,
  TextField,
} from 'mui-rff';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  TextField as MuiTextField,
  makeStyles,
  Button,
  Box,
  FormHelperText
} from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';
import MapIcon from '@material-ui/icons/Map';
import EmailIcon from '@material-ui/icons/Mail';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import WebsiteIcon from '@material-ui/icons/Language';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  codes as countriesCodes,
  labels as countriesLabels,
} from 'src/utils/countries';
import {
  codes as regionsCodes,
  labels as regionsLabels,
} from 'src/utils/regions';
import {
  codes as supportedLocalesCodes,
  labels as supportedLocalesLabels,
} from 'src/utils/locales';
import {
  campingAtmosphereValues,
  // campingCategoryValues,
  campingDurationValues,
  campingPetsValues,
  campingTypeValues,
  groundTypeValues,
  gsmSignalValues,
  statusValues,
} from 'src/utils/enums';
import { SLUGIFY_REGEX } from 'src/utils/consts';
import countryToFlag from 'src/utils/countryToFlag';
import FieldSet from 'src/components/FieldSet';
import PlaceType from 'src/components/PlaceType';
import ChangeUserField from 'src/components/ChangeUserField';
import ConvertPlaceModal from 'src/components/ConvertPlaceModal';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  countryIcon: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  descriptionField: {
    '& .MuiOutlinedInput-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& fieldset': {
      borderBottomWidth: 0,
    },
  },
  descriptionLanguageField: {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  headerButtons: {
    display: 'flex',
    gap: '8px',
    position: 'absolute;',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'white',
  },
  placeTypeIcon: {
    marginRight: theme.spacing(1),
  },
  streetViewField: {
    position: 'relative',
    '& input': {
      paddingRight: 48,
    },
  },
  streetViewButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  typeSelect: {
    '& img': {
      width: 17,
      height: 17,
      verticalAlign: 'middle',
    },
  },
}));

const CampingDetails = ({
  data,
  setSlug,
}) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const openGoogleMaps = (latitude, longitude) => {
    if (latitude && longitude) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`);
    }
  };

  return (
    <Card className={classes.root}>
      <div className={classes.headerButtons}>
        {data && data.origin && (
          <a
            href={data.origin}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button
              color="primary"
              variant="outlined"
            >
              Oryginalny wpis
            </Button>
          </a>
        )}
        {data && data.status === 'unverified' && (
          <ConvertPlaceModal place={data} />
        )}
      </div>

      <CardHeader title={data ? `Edycja kempingu: ${data.title} [ID: #${data.id}]` : 'Dodaj nowy kemping'} />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Nazwa"
              name="title"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              className={classes.typeSelect}
              fullWidth
              label="Typ miejsca"
              name="type"
              required
              select
              variant="outlined"
            >
              <MenuItem
                key=""
                value=""
              />
              {campingTypeValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  <PlaceType
                    className={classes.placeTypeIcon}
                    type={option.value}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true }}>
              {({ errors, submitFailed }) => (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.countryIcon,
                  }}
                  disableClearable
                  getOptionLabel={(option) => countriesLabels[option] || ''}
                  name="country"
                  options={countriesCodes}
                  renderOption={(option) => (option !== '' ? (
                    <>
                      <span>{countryToFlag(option)}</span>
                      {countriesLabels[option]}
                    </>
                  ) : '')}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      error={submitFailed && !!errors?.country}
                      helperText={submitFailed && errors?.country && errors?.country[0]}
                      label="Kraj"
                      required
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
              {({ errors, submitFailed, values }) => (
                <Autocomplete
                  autoHighlight
                  disableClearable
                  disabled={values.country !== 'PL'}
                  getOptionLabel={(option) => regionsLabels[option] || ''}
                  name="region"
                  options={regionsCodes}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      error={submitFailed && !!errors?.region}
                      helperText={submitFailed && errors?.region && errors?.region[0]}
                      label="Województwo"
                      variant="outlined"
                    />
                  )}
                  required={values.country === 'PL'}
                />
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              className={classes.descriptionField}
              fullWidth
              label="Opis"
              multiline
              name="description"
              required
              minRows={5}
              variant="outlined"
            />
            <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
              {({ errors, submitFailed }) => (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.countryIcon,
                  }}
                  disableClearable
                  getOptionLabel={(option) => supportedLocalesLabels[option] || ''}
                  name="language"
                  options={supportedLocalesCodes}
                  renderOption={(option) => (option !== '' ? (
                    <>
                      <span>{countryToFlag(option)}</span>
                      {supportedLocalesLabels[option]}
                    </>
                  ) : '')}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      className={classes.descriptionLanguageField}
                      error={submitFailed && !!errors?.language}
                      helperText={submitFailed && errors?.language && errors?.language[0]}
                      label="Język opisu"
                      required
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Przybliżone ceny"
              multiline
              name="priceList"
              minRows={5}
              variant="outlined"
            />
          </Grid>
          {/* <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Kategoryzacja"
              name="camping_category"
              required
              select
              variant="outlined"
            >
              {campingCategoryValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Okres działania"
              name="camping_duration"
              required
              select
              variant="outlined"
            >
              {campingDurationValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Atmosfera"
              name="camping_atmosphere"
              required
              select
              variant="outlined"
            >
              {campingAtmosphereValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Rodzaj podłoża"
              name="camping_groundType"
              required
              select
              variant="outlined"
            >
              {groundTypeValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Zwierzęta domowe"
              name="camping_pets"
              required
              select
              variant="outlined"
            >
              {campingPetsValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Jakość synału GSM"
              name="camping_gsmSignal"
              required
              select
              variant="outlined"
            >
              {gsmSignalValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FieldSet title="Wyposażenie kempingowe">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_wc"
                    data={[{ label: 'Toalety', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_shower"
                    data={[{ label: 'Prysznice', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_kitchen"
                    data={[{ label: 'Kuchnia', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_commonRoom"
                    data={[{ label: 'Świetlica', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_laundry"
                    data={[{ label: 'Pralnia', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_shed"
                    data={[{ label: 'Wiata / Stoły piknikowe', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_bonfire"
                    data={[{ label: 'Miejsce na ognisko lub grill', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_playground"
                    data={[{ label: 'Plac zabaw', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_shop"
                    data={[{ label: 'Sklep', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_restaurant"
                    data={[{ label: 'Bar / restauracja', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_pool"
                    data={[{ label: 'Basen', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_wifi"
                    data={[{ label: 'Wi-Fi', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="camping_boardgames"
                    data={[{ label: 'Bierze udział w akcji "Rebel w trasie"', value: true }]}
                  />
                </Grid>
              </Grid>
            </FieldSet>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FieldSet title="Wyposażenie karawaningowe">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_wc"
                    data={[{ label: 'Zlanie kasety WC', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_dirtWater"
                    data={[{ label: 'Zlanie szarej wody', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_fillWater"
                    data={[{ label: 'Możliwość napełnienia zbiornika wody', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_electricity"
                    data={[{ label: 'Przyłącze elektryczne', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_water"
                    data={[{ label: 'Woda na parceli', value: true }]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Checkboxes
                    name="caravaning_guest"
                    data={[{ label: 'Możliwość zrobienia serwisu nie będąc gościem kempingu', value: true }]}
                  />
                </Grid>
              </Grid>
            </FieldSet>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Inna infrastruktura"
              name="other_infrastructure"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              label="Telefon"
              name="phone"
              type="tel"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              label="Email"
              name="email"
              type="email"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WebsiteIcon />
                  </InputAdornment>
                ),
              }}
              label="Strona internetowa"
              name="website"
              type="url"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon />
                  </InputAdornment>
                ),
              }}
              label="Facebook"
              name="facebook"
              type="url"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            className={classes.streetViewField}
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Google Maps Street View URL"
              name="streetViewUrl"
              variant="outlined"
            />
            <FormSpy
              subscription={{
                values: true,
              }}
            >
              {({ values }) => (
                <IconButton
                  disabled={!values.latitude && !values.longitude}
                  className={classes.streetViewButton}
                  onClick={() => openGoogleMaps(values.latitude, values.longitude)}
                >
                  <MapIcon />
                </IconButton>
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Link do filmu na YouTube"
              name="youTubeUrl"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}

          >
            <Box mb={2}>
              <Grid
                container
                direction="row"
                alignItems="center"
              >
                <CreditCard />
                <Box ml={1}>
                  <FormHelperText>Miejsce honoruje kartę Grupy Biwakowej</FormHelperText>
                </Box>
              </Grid>
            </Box>
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                direction="row"
              >
                <Checkboxes
                  name="discount"
                  data={[{ value: true }]}
                  style={{ marginTop: 7, marginLeft: 2, marginRight: -8 }}
                />
                <Box flex={1}>
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => (
                      <TextField
                        fullWidth
                        disabled={!values.discount}
                        required={values.discount}
                        label="Zniżka"
                        name="discount_content"
                        variant="outlined"
                      />
                    )}
                  </FormSpy>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <ChangeUserField
              label="Dodał"
              name="creator"
              customUser={data ? (data.user || data.username || null) : null}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: SLUGIFY_REGEX });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                  label="Slug"
                  name="slug"
                  required
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              {statusValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              {data && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Dodane:
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnia edycja:
                {' '}
                {moment(data.updated).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

CampingDetails.propTypes = {
  data: PropTypes.shape(),
  setSlug: PropTypes.func.isRequired,
};

CampingDetails.defaultTypes = {
  data: null,
};

export default CampingDetails;
