import React from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import EditLocationIcon from '@material-ui/icons/EditLocation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  button: {
    padding: '2px 8px 2px 0',
    textTransform: 'lowercase',
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.red[900],
    lineHeight: 1.66,
  },
  inkBox: {
    height: '100%',
  },
}));

const TotalPlaces = ({ all, suggestions, unverified }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const openUnverifiedPlaces = (event) => {
    event.preventDefault();

    navigate('/unverified');
  };

  const openSuggestions = (event) => {
    event.preventDefault();

    navigate('/suggestions');
  };

  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.inkBox}
        component={RouterLink}
        to="/places"
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                MIEJSCA
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {all}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <MapIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box mt={1}>
            {unverified > 0 && (
              <Button
                className={classes.button}
                onClick={openUnverifiedPlaces}
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <AddLocationIcon className={classes.differenceIcon} />
                  <Typography
                    className={classes.differenceValue}
                    variant="body2"
                  >
                    {unverified}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    nowych czekających na weryfikację
                  </Typography>
                </Box>
              </Button>
            )}
            {suggestions > 0 && (
              <Button
                className={classes.button}
                onClick={openSuggestions}
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <EditLocationIcon className={classes.differenceIcon} />
                  <Typography
                    className={classes.differenceValue}
                    variant="body2"
                  >
                    {suggestions}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    nowych propozycji zmian
                  </Typography>
                </Box>
              </Button>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

TotalPlaces.propTypes = {
  all: PropTypes.number,
  suggestions: PropTypes.number,
  unverified: PropTypes.number,
};

export default TotalPlaces;
