import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { deleteCheckIn } from 'src/store/actions/check-ins';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import AlertDialog from 'src/components/AlertDialog';
import TableLoading from 'src/components/TableLoading';

const useStyles = makeStyles((theme) => ({
  noResults: {
    width: '100%',
    height: '100%',
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const CheckIns = ({
  fetchData,
  limit,
  selectors,
  showPlace,
  showUser,
  showActions,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const deleteAlertRef = useRef();
  const classes = useStyles();

  const [currentPage, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState(null);

  const checkIns = useSelector((state) => selectors.getCheckIns(state));
  const isFetching = useSelector((state) => selectors.getCheckInsIsFetching(state));
  const pagination = useSelector((state) => selectors.getCheckInsPaginationData(state));

  const fetchCheckIns = useCallback(async ({
    page = currentPage + 1,
  } = {}) => {
    await dispatch(fetchData(id, { page, limit }))
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania zameldowań', { variant: 'error' });
      });
  }, [currentPage, dispatch, enqueueSnackbar, fetchData, id, limit]);

  useEffect(() => {
    fetchCheckIns();
  }, [fetchCheckIns, id]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const openDeleteModal = (checkInId) => {
    deleteAlertRef.current.open();
    setSelectedId(checkInId);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteCheckIn(selectedId));
      deleteAlertRef.current.close();
      setSelectedId(null);
      enqueueSnackbar('Usunięto zameldowanie', { variant: 'success' });

      await fetchCheckIns();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania zameldowania', { variant: 'error' });
    }
  };

  return (
    <Card>
      <CardHeader title="Zameldowania" />
      <Divider />
      <PerfectScrollbar>
        <Box minHeight={252}>
          {isFetching && <TableLoading />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={220}>
                  Data
                </TableCell>
                {showPlace && (
                  <TableCell>
                    Miejsce
                  </TableCell>
                )}
                {showUser && (
                  <TableCell>
                    Użytkownik
                  </TableCell>
                )}
                {showActions && <TableCell width={30} />}
              </TableRow>
            </TableHead>
            {checkIns && (
              <TableBody>
                {checkIns.length > 0 ? checkIns.map((checkIn) => (
                  <TableRow key={checkIn.id}>
                    <TableCell>
                      {moment(checkIn.date).startOf('minute').fromNow()}
                    </TableCell>
                    {showPlace && (
                      <TableCell>
                        {checkIn.place && (
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/place/${checkIn.place.id}`}
                            underline="none"
                          >
                            {checkIn.place.title}
                          </Link>
                        )}
                      </TableCell>
                    )}
                    {showUser && (
                      <TableCell>
                        {checkIn.user && (
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/user/${checkIn.user.id}`}
                            underline="none"
                          >
                            {checkIn.user.username}
                          </Link>
                        )}
                      </TableCell>
                    )}
                    {showActions && (
                      <TableCell>
                        <IconButton size="small" onClick={() => openDeleteModal(checkIn.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak zameldowań
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handlePageChange}
        page={currentPage}
        rowsPerPage={parseInt(pagination.itemsPerPage, 10)}
        rowsPerPageOptions={[]}
      />
      <AlertDialog
        title="Usuwanie zameldowania"
        message="Jesteś pewny, że chcesz usunąć to zameldowanie?"
        ref={deleteAlertRef}
        actions={[
          {
            color: 'secondary',
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            color: 'secondary',
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Card>
  );
};

CheckIns.propTypes = {
  fetchData: PropTypes.func.isRequired,
  limit: PropTypes.number,
  selectors: PropTypes.shape({
    getCheckIns: PropTypes.func.isRequired,
    getCheckInsPaginationData: PropTypes.func.isRequired,
    getCheckInsIsFetching: PropTypes.func.isRequired,
  }).isRequired,
  showActions: PropTypes.bool,
  showPlace: PropTypes.bool,
  showUser: PropTypes.bool,
};

CheckIns.defaultProps = {
  limit: 5,
  showActions: true,
  showPlace: true,
  showUser: true,
};

export default CheckIns;
