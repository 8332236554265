// import fetch from 'cross-fetch';
import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'mui-rff';
import {
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core/';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDebouncedCallback } from 'use-debounce';

import request from 'src/utils/request';

export default function ChangeUserField({
  customUser,
  error,
  helperText,
  label,
  rff,
  name,
  onChange,
}) {
  let initialUser;

  if (customUser) {
    if (customUser.id) {
      initialUser = { id: customUser.id, username: customUser.username };
    } else if (typeof customUser === 'object') {
      initialUser = { id: null, username: customUser.username, displayUsername: `${customUser.username} [Niezarejestrowany]` };
    } else {
      initialUser = { id: null, username: customUser, displayUsername: `${customUser} [Niezarejestrowany]` };
    }
  }

  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(['', initialUser]);

  const filter = createFilterOptions();

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else if (!customUser?.id) {
      const optionsList = [
        initialUser,
      ];

      setOptions(optionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchUserlist = useDebouncedCallback(async () => {
    setLoading(true);

    const response = await request.get(`v1/users/list?limit=10&search=${inputValue}`);

    let optionsList = [];

    if (!customUser?.id) {
      optionsList = [
        ...optionsList,
        initialUser,
      ];
    }

    setOptions([...optionsList, ...response.data]);

    setLoading(false);
  }, 1000);

  const onInput = React.useCallback((event) => {
    const { value } = event.target;
    setInputValue(value);

    if (value !== '') {
      fetchUserlist();
    }
  }, [fetchUserlist, setInputValue]);

  // To support field without react-final-form
  let valueProp = {};
  if (!rff) {
    valueProp = {
      value: initialUser?.username || null,
    };
  }

  return (
    <Autocomplete
      filterOptions={(fOptions, params) => {
        const filtered = filter(fOptions, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            id: null,
            username: params.inputValue,
            displayUsername: `Użyj "${params.inputValue} [Niezarejestrowany]"`,
          });
        }

        return filtered;
      }}
      getOptionSelected={(option, value) => option?.id === value.id}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        if (typeof option === 'number') {
          return initialUser.username;
        }

        // Regular option
        return option?.username || '';
      }}
      loading={loading}
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInput={onInput}
      onChange={onChange}
      options={options}
      {...valueProp}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          variant="outlined"
        />
      )}
      renderOption={
        (option) => <Typography noWrap>{option?.displayUsername || option?.username }</Typography>
      }
    />
  );
}

ChangeUserField.propTypes = {
  customUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rff: PropTypes.bool,
  onChange: PropTypes.func,
};

ChangeUserField.defaultProps = {
  customUser: null,
  error: false,
  helperText: null,
  label: 'Użytkownik',
  name: 'user',
  rff: true,
  onChange: null,
};
