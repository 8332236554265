import React from 'react';

const LakeIcon = (props) => {
  return (
    <img
      alt="Jezioro"
      src="/static/images/icons/tourist_attractions/lake.svg"
      {...props}
    />
  );
};

export default LakeIcon;
