import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Pagination } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import placesSelectors from 'src/store/selectors/places';

import Loading from 'src/components/Loading';
import PlaceCard from './PlaceCard';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    zIndex: 1,
    backgroundColor: alpha(theme.palette.background.dark, 0.5),
  },
  noResults: {
    width: '100%',
    height: '100%',
    borderBottom: 'none',
    marginBottom: 160,
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const GridLoading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loading}>
      <CircularProgress color="secondary" size={64} />
    </Box>
  );
};

const Results = ({
  handleChangePage,
}) => {
  const classes = useStyles();
  const places = useSelector((state) => placesSelectors.getPlaces(state));
  const pagination = useSelector(
    (state) => placesSelectors.getPlacesPaginationData(state),
  );
  const isFetching = useSelector((state) => placesSelectors.getPlacesIsFetching(state));

  return (
    <Box className={classes.container}>
      {places ? (
        <>
          {places.length > 0 ? (
            <>
              <Box mt={3} className={classes.container}>
                <Grid
                  container
                  spacing={3}
                >
                  {isFetching && <GridLoading />}
                  {places.map((place) => (
                    <Grid
                      item
                      key={place.id}
                      md={4}
                      sm={6}
                      xs={12}
                    >
                      <PlaceCard
                        className={classes.placeCard}
                        place={place}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                mt={3}
                display="flex"
                justifyContent="center"
              >
                <Pagination
                  color="secondary"
                  count={pagination.totalPages}
                  onChange={handleChangePage}
                  page={pagination.currentPage ? parseInt(pagination.currentPage, 10) : 0}
                  size="small"
                />
              </Box>
            </>
          ) : (
            <Box
              mt={16}
              display="flex"
              justifyContent="center"
            >
              <Typography className={classes.noResults}>
                <CloseIcon className={classes.noResultsIcon} />
                Brak elementów do wyświetlenia
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <Loading height="70vh" />
      )}
    </Box>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
};

export default Results;
