import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import {
  CamperStationIcon,
  CampStoreIcon,
  CaravanSalonIcon,
  LaundryIcon,
  LpgIcon,
  ServiceIcon,
  ShowerIcon,
  WaterIcon,
} from './icons/services';

const useStyles = makeStyles({
  icon: ({ size }) => ({
    width: size,
    height: size,
  }),
});

const ServiceType = ({
  className,
  type,
  ...props
}) => {
  const classes = useStyles(props);

  switch (type) {
    case 'camper_station':
      return <CamperStationIcon className={clsx(classes.icon, className)} />;
    case 'camp_store':
      return <CampStoreIcon className={clsx(classes.icon, className)} />;
    case 'caravan_salon':
      return <CaravanSalonIcon className={clsx(classes.icon, className)} />;
    case 'laundry':
      return <LaundryIcon className={clsx(classes.icon, className)} />;
    case 'lpg':
      return <LpgIcon className={clsx(classes.icon, className)} />;
    case 'shower':
      return <ShowerIcon className={clsx(classes.icon, className)} />;
    case 'water':
      return <WaterIcon className={clsx(classes.icon, className)} />;
    default:
      return <ServiceIcon className={clsx(classes.icon, className)} />;
  }
};

ServiceType.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
};

ServiceType.defaultProps = {
  className: null,
  size: 24,
};

export default ServiceType;
