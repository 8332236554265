import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography,
  colors,
  makeStyles,
  Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PublicIcon from '@material-ui/icons/Public';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.purple[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.purple[900]
  },
  differenceValue: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.purple[900],
    lineHeight: 1.66,
  },
  inkBox: {
    height: '100%',
  },
}));

const TotalUsers = ({ total, foreignTotal }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.inkBox}
        component={RouterLink}
        to="/users"
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                UŻYTKOWNIKÓW
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {total}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <PeopleIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box mt={1}>
            {foreignTotal > 0 && (
              <Box
                display="flex"
                alignItems="center"
              >
                <PublicIcon className={classes.differenceIcon} />
                <Typography
                  className={classes.differenceValue}
                  variant="body2"
                >
                  {foreignTotal}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                >
                  użytkowników zza granicy
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

TotalUsers.propTypes = {
  total: PropTypes.number,
  foreignTotal: PropTypes.number,
};

export default TotalUsers;
