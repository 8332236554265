import React from 'react';

const ServiceIcon = (props) => {
  return (
    <img
      alt="Inny serwis"
      src="/static/images/icons/services/service.svg"
      {...props}
    />
  );
};

export default ServiceIcon;
