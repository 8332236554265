import request from '../../utils/request';

const PREFIX = '[SUGGESTIONS]';

export const GET_SUGGESTIONS_REQUEST = `${PREFIX} GET_SUGGESTIONS_REQUEST`;
export const GET_SUGGESTIONS_SUCCESS = `${PREFIX} GET_SUGGESTIONS_SUCCESS`;
export const GET_SUGGESTIONS_FAILURE = `${PREFIX} GET_SUGGESTIONS_FAILURE`;

const fetchSuggestionsRequest = () => ({
  type: GET_SUGGESTIONS_REQUEST,
});

const fetchSuggestionsSuccess = (payload) => ({
  payload,
  type: GET_SUGGESTIONS_SUCCESS,
});

const fetchSuggestionsFailure = () => ({
  type: GET_SUGGESTIONS_FAILURE,
});

export const fetchSuggestions = (filters) => (dispatch) => {
  dispatch(fetchSuggestionsRequest());

  return request
    .get('v1/suggestions/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchSuggestionsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchSuggestionsFailure());

      throw error;
    });
};

export const fetchSuggestionsDetails = (id) => () => {
  return request
    .get(`v1/suggestions/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const approveSuggestions = (id, values) => () => {
  return request
    .post(`v1/suggestions/${id}/approve`, values)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const rejectSuggestions = (id, values) => () => {
  return request
    .post(`v1/suggestions/${id}/reject`, values)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};
