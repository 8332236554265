import {
  CLEAR_DATA,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_PAGES_FAILURE,
  GET_PAGES_REQUEST,
  GET_PAGES_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  SET_FILTERS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/pages';

const initialState = {
  details: {
    isFetching: false,
    data: null,
  },
  filters: {},
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        details: initialState.details,
        list: initialState.list,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case GET_PAGES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_PAGES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_PAGES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          checkIns: initialState.details.checkIns,
          comments: initialState.details.comments,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          ...action.payload,
        }
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default pagesReducer;
