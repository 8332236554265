import React from 'react';

const ZooIcon = (props) => {
  return (
    <img
      alt="Zoo"
      src="/static/images/icons/tourist_attractions/zoo.svg"
      {...props}
    />
  );
};

export default ZooIcon;
