import React, {
  useEffect,
  useState,
} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  fetchData,
  fetchActivities,
} from 'src/store/actions/dashboard';
import dashboardSelectors from 'src/store/selectors/dashboard';

import Page from 'src/components/Page';
import Loading from 'src/components/Loading';

import TotalPlaces from './TotalPlaces';
import TotalCheckIns from './TotalCheckIns';
import TotalComments from './TotalComments';
import TotalUsers from './TotalUsers';
import LatestCheckIns from './LatestCheckIns';
import LatestComments from './LatestComments';
import LatestPlaces from './LatestPlaces';
import Activities from './Activities';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activitiesPeriod, setActivitiesPeriod] = useState(7);

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchData()),
        dispatch(fetchActivities()),
      ]);
    };

    fetch()
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeActivitiesPeriod = (event) => {
    const period = event.target.value;

    setActivitiesPeriod(period);
    dispatch(fetchActivities(period));
  };

  const comments = useSelector((state) => dashboardSelectors.getComments(state));
  const checkIns = useSelector((state) => dashboardSelectors.getCheckIns(state));
  const places = useSelector((state) => dashboardSelectors.getPlaces(state));
  const placesCount = useSelector((state) => dashboardSelectors.getPlacesCount(state));
  const suggestionsCount = useSelector((state) => dashboardSelectors.getSuggestionsCount(state));
  const unverifiedPlacesCount = useSelector(
    (state) => dashboardSelectors.getUnverifiedPlacesCount(state)
  );
  const checkInsCount = useSelector((state) => dashboardSelectors.getCheckInsCount(state));
  const commentsCount = useSelector((state) => dashboardSelectors.getCommentsCount(state));
  const usersCount = useSelector((state) => dashboardSelectors.getUsersCount(state));
  const foreignUsersCount = useSelector((state) => dashboardSelectors.getForeignUsersCount(state));
  const activities = useSelector((state) => dashboardSelectors.getActivities(state));
  const isFetching = useSelector((state) => dashboardSelectors.isDataFetching(state));
  const isActivitiesFetching = useSelector(
    (state) => dashboardSelectors.isActivitiesFetching(state)
  );

  return (
    <Page
      className={classes.root}
      title="Panel aktywności"
    >
      {!isFetching ? (
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalPlaces
                all={placesCount}
                suggestions={suggestionsCount}
                unverified={unverifiedPlacesCount}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalCheckIns total={checkInsCount} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalComments total={commentsCount} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalUsers total={usersCount} foreignTotal={foreignUsersCount} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Activities
                data={activities}
                isFetching={isActivitiesFetching}
                period={activitiesPeriod}
                onChange={changeActivitiesPeriod}
              />
            </Grid>
            <Grid
              item
              md={4}
              xl={3}
              xs={12}
            >
              <LatestCheckIns data={checkIns} />
            </Grid>
            <Grid
              item
              md={8}
              xl={9}
              xs={12}
            >
              <LatestPlaces data={places} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <LatestComments data={comments} />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Loading />
      )}
    </Page>
  );
};

export default Dashboard;
