import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import FileUploadDialog from 'src/components/FileUploadDialog';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(2),
    fontSize: 48,
  },
  deleteButton: {
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}));

const AvatarBox = ({
  data,
  file,
  setFile,
}) => {
  const classes = useStyles();
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const getImage = () => {
    if (file && file[0].data) {
      return file[0].data;
    }

    if (file !== false && data?.avatar) {
      return `${STORAGE_URL}/users/${data.id}/${data.avatar}`;
    }

    return null;
  };

  const removeImage = () => {
    setFile(data?.avatar ? false : null);
  };

  return (
    <Card>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <>
                <Avatar
                  className={classes.avatar}
                  src={getImage()}
                >
                  {!(data?.avatar && file !== false) && getInitials(values.username)}
                </Avatar>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {values.username || <span>&nbsp;</span>}
                </Typography>
              </>
            )}
          </FormSpy>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={openFileUploadDialog}
        >
          {(
            (data?.avatar && file !== false)
            || (!data?.avatar && file)
          ) ? 'Zmien avatar'
            : 'Dodaj avatar'}
        </Button>
        {(
          (data?.avatar && file !== false)
          || (!data?.avatar && file)
        ) && (
          <Button
            className={classes.deleteButton}
            color="secondary"
            fullWidth
            variant="text"
            onClick={removeImage}
          >
            Usuń avatar
          </Button>
        )}
      </CardActions>
      <FileUploadDialog
        handleClose={closeFileUploadDialog}
        isOpen={isFileUploadOpen}
        setFiles={setFile}
      />
    </Card>
  );
};

AvatarBox.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    id: PropTypes.number,
  }),
  file: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setFile: PropTypes.func.isRequired,
};

AvatarBox.defaultProps = {
  data: {},
  file: null,
};

export default AvatarBox;
