import {
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  RUN_RECALCULATING,
  SAVE_AUTOCOMPLETE_API_KEY_INDEX,
  SAVE_TRANSLATED_SIGNS_LIMIT,
  SAVE_TRANSLATION_API_KEY_INDEX,
} from '../actions/settings';

const initialState = {
  recalculatingFinished: false,
  recalculatingDate: null,
  isFetching: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case GET_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case RUN_RECALCULATING:
      return {
        ...state,
        recalculatingFinished: false,
        recalculatingDate: new Date(),
      };
    case SAVE_TRANSLATED_SIGNS_LIMIT:
      return {
        ...state,
        translatedSignsLimit: action.payload,
      };
    case SAVE_TRANSLATION_API_KEY_INDEX:
      return {
        ...state,
        translationApiKeyIndex: action.payload,
      };
    case SAVE_AUTOCOMPLETE_API_KEY_INDEX:
      return {
        ...state,
        autocompleteApiKeyIndex: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
