import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import Map from '@material-ui/icons/Map';
import MapOutlined from '@material-ui/icons/MapOutlined';
import PlaceType from 'src/components/PlaceType';
import ServiceType from 'src/components/ServiceType';
import TouristAttractionType from 'src/components/TouristAttractionType';
import calculateDistance from 'src/utils/calculateDistance';
import TableLoading from 'src/components/TableLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  emptyTable: {
    height: 122,
  },
  distanceColumn: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  icon: {
    position: 'absolute',
    top: 11,
    right: 11,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '30px auto 0',
    fontSize: 64,
  },
}));

const ClosePlaces = ({
  data,
  latitude,
  longitude,
  loading,
  isClosePlacesVisible,
  toggleClosePlaces
}) => {
  const classes = useStyles();

  // sort and limit to 5
  const results = useMemo(() => data && data.map((place) => {
    place.distance = Math.round(calculateDistance(latitude, longitude, place.latitude, place.longitude, 'm'));

    return place;
  }).sort((a, b) => a.distance - b.distance).slice(0, 5), [data, latitude, longitude]);

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Miejsca w pobliżu" />
      <IconButton
        className={classes.icon}
        disabled={!data || !data.length}
        size="small"
        onClick={toggleClosePlaces}
      >
        {isClosePlacesVisible ? <Map /> : <MapOutlined />}
      </IconButton>
      <Divider />
      <PerfectScrollbar>
        <Box>
          {loading && <TableLoading size={32} />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Miejsce
                </TableCell>
                <TableCell width={37} className={classes.distanceColumn}>
                  Odległość
                </TableCell>
                <TableCell width={80} />
              </TableRow>
            </TableHead>
            <TableBody className={!results ? classes.emptyTable : undefined}>
              {results?.length > 0 && results.map((place) => (
                <TableRow
                  hover
                  key={place.id}
                >
                  <TableCell className={classes.placesTitle}>
                    <Link
                      className={classes.title}
                      color="inherit"
                      component={RouterLink}
                      to={`/place/${place.id}`}
                      underline="none"
                    >
                      {getPlaceIcon(place)}
                      {place.title}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.distanceColumn}>
                    {`${place.distance} m`}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      component={RouterLink}
                      to={`/place/${place.id}`}
                    >
                      <ChevronRight />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {results?.length === 0 && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={5}
                    className={classes.noResults}
                  >
                    <CloseIcon className={classes.noResultsIcon} />
                    Brak miejsc w pobliżu
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

ClosePlaces.propTypes = {
  data: PropTypes.array,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  isClosePlacesVisible: PropTypes.bool.isRequired,
  toggleClosePlaces: PropTypes.func.isRequired,
};

ClosePlaces.defaultProps = {
  data: null,
  latitude: null,
  longitude: null,
};

export default ClosePlaces;
