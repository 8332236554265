import request from '../../utils/request';

const PREFIX = '[ADS]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearAdsData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createAdRequest = () => ({
  type: CREATE_REQUEST,
});

const createAdSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createAdFailure = () => ({
  type: CREATE_FAILURE,
});

export const createAd = (payload) => (dispatch) => {
  dispatch(createAdRequest());

  return request
    .post('v1/ads/', payload)
    .then(({ data }) => {
      dispatch(createAdSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createAdFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteAdRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteAdSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteAdFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteAd = (id) => (dispatch) => {
  dispatch(deleteAdRequest());

  return request
    .delete(`v1/ads/${id}`)
    .then(({ data }) => {
      dispatch(deleteAdSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteAdFailure());

      throw error;
    });
};

export const GET_ADS_REQUEST = `${PREFIX} GET_ADS_REQUEST`;
export const GET_ADS_SUCCESS = `${PREFIX} GET_ADS_SUCCESS`;
export const GET_ADS_FAILURE = `${PREFIX} GET_ADS_FAILURE`;

const fetchAdsRequest = () => ({
  type: GET_ADS_REQUEST,
});

const fetchAdsSuccess = (payload) => ({
  payload,
  type: GET_ADS_SUCCESS,
});

const fetchAdsFailure = () => ({
  type: GET_ADS_FAILURE,
});

export const fetchAds = (filters) => (dispatch) => {
  dispatch(fetchAdsRequest());

  return request
    .get('v1/ads/', { params: { ...filters, isAdmin: true } })
    .then(({ data }) => {
      dispatch(fetchAdsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchAdsFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchAdDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchAdDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchAdDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchAdDetails = (language, id) => (dispatch) => {
  dispatch(fetchAdDetailsRequest());

  return request
    .get(`v1/ads/${id}?isAdmin&language=${language}`)
    .then(({ data }) => {
      dispatch(fetchAdDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchAdDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateAdRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateAdSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateAdFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateAd = (id, payload) => (dispatch) => {
  dispatch(updateAdRequest());

  return request
    .put(`v1/ads/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateAdSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateAdFailure());

      throw error;
    });
};
