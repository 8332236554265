import React from 'react';

const LaundryIcon = (props) => {
  return (
    <img
      alt="Pralnia"
      src="/static/images/icons/services/laundry.svg"
      {...props}
    />
  );
};

export default LaundryIcon;
