import {
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  // UPDATE_FAILURE,
  // UPDATE_REQUEST,
  // UPDATE_SUCCESS,
} from '../actions/comments';

const initialState = {
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case DELETE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    // case UPDATE_REQUEST:
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       isFetching: true,
    //     }
    //   };
    // case UPDATE_SUCCESS:
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       isFetching: true,
    //       ...action.payload,
    //     }
    //   }
    // case UPDATE_FAILURE:
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       isFetching: false,
    //     }
    //   };
    default:
      return state;
  }
};

export default usersReducer;
