import React from 'react';

const SwimmingPoolIcon = (props) => {
  return (
    <img
      alt="Basen"
      src="/static/images/icons/tourist_attractions/swimming_pool.svg"
      {...props}
    />
  );
};

export default SwimmingPoolIcon;
