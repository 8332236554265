import React from 'react';

const CaravanSalonIcon = (props) => {
  return (
    <img
      alt="Wypożyczalnia / salon / komis"
      src="/static/images/icons/services/caravan_salon.svg"
      {...props}
    />
  );
};

export default CaravanSalonIcon;
