import { combineReducers } from 'redux';
import ads from './ads';
import auth from './auth';
import articles from './articles';
import comments from './comments';
import config from './config';
import dashboard from './dashboard';
import discounts from './discounts';
import news from './news';
import pages from './pages';
import places from './places';
import settings from './settings';
import suggestions from './suggestions';
import unverified from './unverified';
import users from './users';

export default combineReducers({
  ads,
  auth,
  articles,
  comments,
  config,
  dashboard,
  discounts,
  news,
  pages,
  places,
  settings,
  suggestions,
  unverified,
  users,
});
