import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors,
  makeStyles
} from '@material-ui/core';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import FeaturedIcon from '@material-ui/icons/Star';
import UnverifiedIcon from '@material-ui/icons/OfflinePin';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import PlaceType from 'src/components/PlaceType';
import ServiceType from 'src/components/ServiceType';
import TouristAttractionType from 'src/components/TouristAttractionType';

const useStyles = makeStyles((theme) => ({
  root: {},
  active: {
    fill: colors.green[500],
  },
  featured: {
    fill: colors.orange[500],
  },
  unverified: {
    fill: colors.yellow[800],
  },
  inReview: {
    fill: colors.orange[800],
  },
  hidden: {
    fill: colors.red[500],
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
}));

const LatestPlaces = ({ data }) => {
  const classes = useStyles();

  const getPlaceStatus = (status) => {
    switch (status) {
      case 'active':
        return <ActiveIcon className={classes.active} />;
      case 'featured':
        return <FeaturedIcon className={classes.featured} />;
      case 'in_review':
        return <UnverifiedIcon className={classes.inReview} />;
      case 'unverified':
        return <UnverifiedIcon className={classes.unverified} />;
      case 'hidden':
        return <HiddenIcon className={classes.hidden} />;
      default:
        return null;
    }
  };

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Ostatnio dodane miejsca" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Miejsce
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell width={200}>
                  Data dodania
                </TableCell>
                <TableCell width={80} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((place) => (
                <TableRow
                  hover
                  key={place.id}
                >
                  <TableCell className={classes.placesTitle}>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/place/${place.id}`}
                      underline="none"
                    >
                      {getPlaceIcon(place)}
                      {place.title}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {getPlaceStatus(place.status)}
                  </TableCell>
                  <TableCell>
                    {moment(place.created).startOf('minute').fromNow()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      component={RouterLink}
                      to={`/place/${place.id}`}
                    >
                      <ZoomIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LatestPlaces.propTypes = {
  data: PropTypes.array.isRequired
};

export default LatestPlaces;
