import React from 'react';

const BivouacIcon = (props) => {
  return (
    <img
      alt="Kemping"
      src="/static/images/icons/bivouac.svg"
      {...props}
    />
  );
};

export default BivouacIcon;
