import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const LatestCheckIns = ({ data }) => {
  const [checkIns] = useState(data);

  return (
    <Card>
      <CardHeader title="Ostatnie zameldowania" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Miejsce
                </TableCell>
                <TableCell>
                  Użytkownik
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkIns.map((checkIn) => (
                <TableRow key={checkIn.id}>
                  <TableCell>
                    {moment(checkIn.date).startOf('minute').fromNow()}
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/place/${checkIn.place.id}`}
                      underline="none"
                    >
                      {checkIn.place.title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/user/${checkIn.user.id}`}
                      underline="none"
                    >
                      {checkIn.user.username}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LatestCheckIns.propTypes = {
  data: PropTypes.array,
};

export default LatestCheckIns;
