import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';
import FileUploadDialog from 'src/components/FileUploadDialog';
import { AdImageSizes } from 'src/utils/consts';
import { FormSpy } from 'react-final-form';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  deleteButton: {
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  field: {
    width: '100%',
    '& input': {
      textAlign: 'center',
    },
  },
  media: {
    display: 'block',
    maxWidth: '100%',
    objectFit: 'contain',
  },
}));

const ImageBox = ({
  data,
  file,
  setFile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const getImage = () => {
    if (file && file[0].data) {
      return file[0].data;
    }

    if (file !== false && data?.image) {
      return `${STORAGE_URL}/partners/${data.id}/${data.image}`;
    }

    return null;
  };

  const removeImage = () => {
    setFile(data?.image ? false : null);
  };

  const image = getImage();

  return (
    <>
      {image && (
        <>
          <CardContent className={classes.content}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <img
                className={classes.media}
                src={image}
                alt="Grafika"
              />
            </Box>
          </CardContent>
          <Divider />
        </>
      )}
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (values?.type ? (
          <>
            <Grid
              container
              justifyContent="center"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                style={{ padding: theme.spacing(1) }}
              >
                <Typography
                  component="p"
                  align="center"
                  variant="caption"
                  sx={{ padding: 1 }}
                >
                  {`Docelowy wymiar grafiki: ${AdImageSizes[values.type].width}x${AdImageSizes[values.type].height}`}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </>
        ) : null)}
      </FormSpy>
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          onClick={openFileUploadDialog}
          variant="text"
        >
          {(
            (data?.image && file !== false)
            || (!data?.image && file)
          ) ? 'Zmień grafikę'
            : 'Dodaj grafikę'}
        </Button>
        {(
          (data?.image && file !== false)
          || (!data?.image && file)
        ) && (
          <Button
            className={classes.deleteButton}
            color="secondary"
            fullWidth
            variant="text"
            onClick={removeImage}
          >
            Usuń grafikę
          </Button>
        )}
      </CardActions>
      <FileUploadDialog
        handleClose={closeFileUploadDialog}
        isOpen={isFileUploadOpen}
        setFiles={setFile}
      />
    </>
  );
};

ImageBox.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    image: PropTypes.string,
  }),
  file: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setFile: PropTypes.func.isRequired,
};

ImageBox.defaultProps = {
  data: {},
  file: null,
};

export default ImageBox;
