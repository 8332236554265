export const codes = [
  'pl',
  'en',
  'de',
  'sk',
  'ru',
];

export const labels = {
  pl: 'Polski',
  en: 'Angielski',
  de: 'Niemiecki',
  sk: 'Słowacki',
  ru: 'Rosyjski',
};
