import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';

import { saveTranslatedSignsLimit, saveTranslationApiKeyIndex } from 'src/store/actions/settings';
import settingsSelectors from 'src/store/selectors/settings';
import {
  Box,
  Button,
  LinearProgress,
  Grid,
  Typography,
  makeStyles,
  withStyles,
  TextField
} from '@material-ui/core';
import {
  green,
  yellow,
  red,
} from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  saveLimitButton: {
    height: 56,
    marginLeft: theme.spacing(1),
  }
}));

const API_KEYS_AMOUNT = 2;

const TranslationsSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const settings = useSelector((state) => settingsSelectors.getData(state));

  const [limitValue, setLimitValue] = useState(settings.translatedSignsLimit);
  const [apiValue, setApiValue] = useState(settings.translationApiKeyIndex);

  const progressBarValue = settings.translatedSigns < settings.translatedSignsLimit
    * API_KEYS_AMOUNT
    ? (settings.translatedSigns / (settings.translatedSignsLimit * API_KEYS_AMOUNT)) * 100
    : 100;

  const getProgressBarColor = () => {
    if (progressBarValue > 90) {
      return red[500];
    }

    if (progressBarValue > 65) {
      return yellow[700];
    }

    return green[500];
  };

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: getProgressBarColor(),
    },
  }))(LinearProgress);

  const submit = () => {
    enqueueSnackbar('Zapisano', { variant: 'success' });
    dispatch(saveTranslatedSignsLimit(limitValue));
  };

  const submitApi = () => {
    enqueueSnackbar('Zapisano', { variant: 'success' });
    dispatch(saveTranslationApiKeyIndex(apiValue));
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          {`Limit tłumaczeń (${settings.translatedSigns} / ${settings.translatedSignsLimit * API_KEYS_AMOUNT}) [API #${settings.translationApiKeyIndex}]`}
        </Typography>
        <Box mt={2} mb={1}>
          <BorderLinearProgress variant="determinate" value={progressBarValue} />
        </Box>
        <Typography variant="caption">
          W celu ograniczenia kosztów po przekroczeniu limitu ilości przetłumaczonych znaków
          blokowana jest możliwość tłumaczenia kolejnych tekstów.
          Bezpłatna ilość to 500000 na cykl rozliczeniowy. Licznik zeruje się pierwszego
          dnia miesiąca.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          Ustaw limit
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          label="Limit"
          variant="outlined"
          value={limitValue}
          onChange={(event) => {
            setLimitValue(event.target.value);
          }}
        />
        <Button
          className={classes.saveLimitButton}
          color="secondary"
          size="small"
          onClick={submit}
          variant="contained"
        >
          <SaveIcon />
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          Ustaw API
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          label="API"
          variant="outlined"
          value={apiValue}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              max: API_KEYS_AMOUNT
            }
          }}
          onChange={(event) => {
            setApiValue(event.target.value);
          }}
        />
        <Button
          className={classes.saveLimitButton}
          color="secondary"
          size="small"
          onClick={submitApi}
          variant="contained"
        >
          <SaveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default TranslationsSettings;
