import React from 'react';

const BikeIcon = (props) => {
  return (
    <img
      alt="Trasa rowerowa"
      src="/static/images/icons/tourist_attractions/bike.svg"
      {...props}
    />
  );
};

export default BikeIcon;
