import React from 'react';

const CampStoreIcon = (props) => {
  return (
    <img
      alt="Sklep kempingowy"
      src="/static/images/icons/services/camp_store.svg"
      {...props}
    />
  );
};

export default CampStoreIcon;
