export default {
  getCheckIns: (state) => state.places.details.checkIns.items,
  getCheckInsPaginationData: (state) => state.places.details.checkIns.meta,
  getCheckInsIsFetching: (state) => state.places.details.checkIns.isFetching,

  getComments: (state) => state.places.details.comments.items,
  getCommentsPaginationData: (state) => state.places.details.comments.meta,
  getCommentsIsFetching: (state) => state.places.details.comments.isFetching,

  getSuggestions: (state) => state.places.details.suggestions.items,
  getSuggestionsPaginationData: (state) => state.places.details.suggestions.meta,
  getSuggestionsIsFetching: (state) => state.places.details.suggestions.isFetching,

  getCampingsFilters: (state) => state.places.filters.campings,
  getPlacesFilters: (state) => state.places.filters.places,
  getServicesFilters: (state) => state.places.filters.services,
  getTouristAttractionsFilters: (state) => state.places.filters.tourist_attractions,

  getPlaceDetails: (state) => state.places.details.data,
  getPlaceDetailsIsFetching: (state) => state.places.details.isFetching,

  getPlaces: (state) => state.places.list.items,
  getPlacesPaginationData: (state) => state.places.list.meta,
  getPlacesIsFetching: (state) => state.places.list.isFetching,

  getClosePlaces: (state) => state.places.details.closePlaces.results,
  getClosePlacesIsFetching: (state) => state.places.details.closePlaces.isFetching,

  getMapType: (state) => state.places.map.current,
  getMapPlaces: (state) => state.places.map.data,
  getMapPlacesIsFetching: (state) => state.places.map.isFetching,
};
