import React, {
  useContext,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBarContext } from 'src/utils/appBarContext';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Assignment as DashboardIcon,
  Explore as ExploreIcon,
  Group as UsersIcon,
  Hotel as HotelIcon,
  Input as LogoutIcon,
  ImportContacts as ServiceIcon,
  Place as MapPinIcon,
  Settings as SettingsIcon,
  AddLocation as UnverifiedIcon,
  CreditCard as DiscountIcon,
  EditLocation as SuggestionsIcon,
  Description as ArticleIcon,
  MenuBook as FeedIcon,
  CallToAction as AdsIcon,
  Web as WebIcon,
} from '@material-ui/icons/';

import { logout } from 'src/store/actions/auth';
import authSelectors from 'src/store/selectors/auth';
import getInitials from 'src/utils/getInitials';

import NavItem from './NavItem';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const items = [
  {
    href: '/dashboard',
    icon: DashboardIcon,
    title: 'Panel aktywności'
  },
  {
    href: '/places',
    icon: MapPinIcon,
    title: 'Miejsca biwakowe'
  },
  {
    href: '/campings',
    icon: HotelIcon,
    title: 'Kempingi'
  },
  {
    href: '/tourist-attractions',
    icon: ExploreIcon,
    title: 'Atrakcje turystyczne'
  },
  {
    href: '/services',
    icon: ServiceIcon,
    title: 'Miejsca serwisowe'
  },
  {
    href: '/users',
    icon: UsersIcon,
    title: 'Użytkownicy'
  },
  {
    href: '/unverified',
    icon: UnverifiedIcon,
    title: 'Do weryfikacji'
  },
  {
    href: '/suggestions',
    icon: SuggestionsIcon,
    title: 'Propozycje zmian'
  },
  {
    href: '/discounts',
    icon: DiscountIcon,
    title: 'Miejsca honorujące kartę'
  },
];

const webappItems = [
  {
    href: '/ads',
    icon: AdsIcon,
    title: 'Reklamy'
  },
  {
    href: '/news',
    icon: FeedIcon,
    title: 'Aktualności'
  },
  {
    href: '/articles',
    icon: ArticleIcon,
    title: 'Artykuły'
  },
  {
    href: '/pages',
    icon: WebIcon,
    title: 'Strony'
  },
];

const otherItems = [
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Ustawienia'
  },
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(1),
  }
}));

const NavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobileMenuOpen, setMobileMenuOpen } = useContext(AppBarContext);
  const user = useSelector((state) => authSelectors.getUser(state));

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout);
    window.location = '/login';
  };

  useEffect(() => {
    if (isMobileMenuOpen && setMobileMenuOpen) {
      handleMobileMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isProbablyActive = (path) => {
    return location.pathname.substring(0, 5) === path.substring(0, 5);
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar && `${STORAGE_URL}/users/${user.id}/${user.avatar}`}
          to={`/user/${user.id}`}
        >
          {!user.avatar && getInitials(user.username)}
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.username}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              isActive={isProbablyActive(item.href)}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {webappItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              isActive={isProbablyActive(item.href)}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {otherItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              isActive={isProbablyActive(item.href)}
            />
          ))}
        </List>
      </Box>
      <Hidden lgUp>
        <Divider />
        <Box p={2}>
          <List>
            <NavItem
              icon={LogoutIcon}
              onClick={handleLogout}
              title="Wyloguj"
            />
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={handleMobileMenuClose}
          open={isMobileMenuOpen}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
