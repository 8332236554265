import request from '../../utils/request';

const PREFIX = '[COMMENTS]';

export const GET_COMMENTS_REQUEST = `${PREFIX} GET_COMMENTS_REQUEST`;
export const GET_COMMENTS_SUCCESS = `${PREFIX} GET_COMMENTS_SUCCESS`;
export const GET_COMMENTS_FAILURE = `${PREFIX} GET_COMMENTS_FAILURE`;

const fetchCommentsRequest = () => ({
  type: GET_COMMENTS_REQUEST,
});

const fetchCommentsSuccess = (payload) => ({
  payload,
  type: GET_COMMENTS_SUCCESS,
});

const fetchCommentsFailure = () => ({
  type: GET_COMMENTS_FAILURE,
});

export const fetchComments = (pagination) => (dispatch) => {
  dispatch(fetchCommentsRequest());

  return request
    .get('v1/comments/', { params: pagination })
    .then(({ data }) => {
      dispatch(fetchCommentsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchCommentsFailure());

      throw error;
    });
};

export const ADD_REQUEST = `${PREFIX} ADD_REQUEST`;
export const ADD_SUCCESS = `${PREFIX} ADD_SUCCESS`;
export const ADD_FAILURE = `${PREFIX} ADD_FAILURE`;

const addCommentRequest = () => ({
  type: ADD_REQUEST,
});

const addCommentSuccess = (payload) => ({
  payload,
  type: ADD_SUCCESS,
});

const addCommentFailure = () => ({
  type: ADD_FAILURE,
});

export const addComment = (payload) => async (dispatch) => {
  dispatch(addCommentRequest());

  return request
    .post('v1/comments', payload)
    .then(({ data }) => {
      dispatch(addCommentSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(addCommentFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteCommentRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteCommentSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteCommentFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteComment = (id) => async (dispatch) => {
  dispatch(deleteCommentRequest());

  return request
    .delete(`v1/comments/${id}`)
    .then(({ data }) => {
      dispatch(deleteCommentSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteCommentFailure());

      throw error;
    });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateCommentRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateCommentSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateCommentFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateComment = (id, payload) => async (dispatch) => {
  dispatch(updateCommentRequest());

  return request
    .put(`v1/comments/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateCommentSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateCommentFailure());

      throw error;
    });
};
