import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'mui-rff';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';

const ChangePassword = ({ action }) => {
  return (
    <Card>
      <CardHeader title={action === 'edit' ? 'Zmień hasło' : 'Ustal hasło'} />
      <Divider />
      <CardContent>
        <TextField
          fullWidth
          label="Hasło"
          margin="normal"
          name="password"
          required={action === 'add'}
          type="password"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Potwierdź hasło"
          margin="normal"
          name="confirm_password"
          required={action === 'add'}
          type="password"
          variant="outlined"
        />
      </CardContent>
    </Card>
  );
};

ChangePassword.propTypes = {
  action: PropTypes.string,
};

export default ChangePassword;
