import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify/slugify';
import moment from 'moment';
import { Field, FormSpy } from 'react-final-form';
import { TextField, Autocomplete } from 'mui-rff';
import 'date-fns';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import countryToFlag from 'src/utils/countryToFlag';
import { pageStatusValues } from 'src/utils/enums';
import {
  codes as supportedLocalesCodes,
  labels as supportedLocalesLabels,
} from 'src/utils/locales';
import WysiwygEditor from 'src/components/WysiwygEditor';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  tab: {
    minHeight: 44
  }
}));

const ArticleCategory = ({ data, setContent, setSlug }) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja kategorii: ${data.title ?? ''} [ID: #${data.id}]` : 'Dodaj nową kategorię'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Tytuł"
              name="title"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
              {({ errors, submitFailed }) => (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.countryIcon,
                  }}
                  disableClearable
                  getOptionLabel={(option) => supportedLocalesLabels[option] || ''}
                  name="language"
                  options={supportedLocalesCodes}
                  renderOption={(option) => (option !== '' ? (
                    <>
                      <span>{countryToFlag(option)}</span>
                      {' '}
                      {supportedLocalesLabels[option]}
                    </>
                  ) : '')}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      className={classes.descriptionLanguageField}
                      error={submitFailed && !!errors?.language}
                      helperText={submitFailed && errors?.language && errors?.language[0]}
                      label="Język"
                      required
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormSpy>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Opis"
              name="description"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Field name="content">
              {({ meta }) => (
                <WysiwygEditor
                  value={data?.content ?? ''}
                  onChange={(value) => setContent(value)}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: SLUGIFY_REGEX });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                  label="Slug"
                  name="slug"
                  required
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              {pageStatusValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              <MenuItem value="deleted">
                Usunięty
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              item
              xs={6}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Dodane:
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnia edycja:
                {' '}
                {moment(data.updated).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

ArticleCategory.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    language: PropTypes.oneOf(supportedLocalesCodes).isRequired,
    status: PropTypes.oneOf(pageStatusValues.map((option) => option.value)).isRequired,
    created: PropTypes.string,
    updated: PropTypes.string,
  }),
  setContent: PropTypes.func.isRequired,
  setSlug: PropTypes.func.isRequired,
};

ArticleCategory.defaultProps = {
  data: null,
};

export default ArticleCategory;
