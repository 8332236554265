import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { useSnackbar } from 'notistack';

import { Typography } from '@material-ui/core';
import request from 'src/utils/request';

const API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

const WysiwygEditor = ({
  value,
  onChange,
  error,
  helperText,
}) => {
  const editorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleInit = (_, editor) => { editorRef.current = editor; };

  const handleChange = () => {
    if (editorRef.current) {
      onChange(editorRef.current.getContent());
    }
  };

  const handleImageUpload = async (blobInfo) => new Promise((resolve, reject) => {
    const imageFile = new FormData();
    imageFile.append('image', blobInfo.blob(), blobInfo.filename());

    request
      .post('v1/files/image', imageFile)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
        throw err;
      });
  });

  const handleCreateGallery = async (images) => {
    const createThumnail = async (imageUrl) => {
      const dir = imageUrl.split('uploads/').pop();
      const filename = dir.split('/').pop();
      const folder = dir.replace(`/${filename}`, '');

      const response = await request.get(`v1/files/uploads--${folder.replaceAll('/', '--')}/${filename}`, { responseType: 'blob' });
      const blob = await response.data;
      const imageFile = new FormData();
      imageFile.append('image', blob, `thumbnail_${filename}`);

      try {
        const { data } = await request.post('v1/files/image?width=275&height=172&noSuffix=true', imageFile);
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    };

    const results = await Promise.all(Array.from(images).map((image) => createThumnail(image.src)));

    let html = '<div class="wysiwyg-gallery">';
    results.forEach((thumbnail, index) => {
      html += `
        <figure class="gallery-item" data-gallery-index="${index - results.length}">
            <img src="${thumbnail}" alt="Image" />
          </a>
        </figure>
      `;
    });
    html += '</div>';

    return html;
  };

  // Create gallery plugin
  const setup = (setupEditor) => {
    // convert images to gallery
    setupEditor.editorManager.PluginManager.add('creategallery', (editor) => {
      editor.ui.registry.addButton('creategallery', {
        icon: 'gallery',
        tooltip: 'Stwórz galerię z zaznaczonych zdjęć',
        onAction: async () => {
          const selection = editor.selection.getNode();
          let images = [];

          if (selection.tagName === 'IMG') {
            images = [selection];
          } else {
            images = selection.querySelectorAll('img');
          }

          // Check if any img elements were found
          if (images.length === 0) {
            enqueueSnackbar('Zaznacz zdjęcie lub zdjęcia, aby utworzyć z nich galerię', { variant: 'info' });
          } else {
            const html = await handleCreateGallery(images);
            editor.selection.setContent(html);
            enqueueSnackbar('Utworzono galerię z zaznaczonych zdjęć', { variant: 'success' });
          }
        }
      });
    });
  };

  return (
    <>
      <Editor
        apiKey={API_KEY}
        onInit={handleInit}
        initialValue={value}
        onChange={handleChange}
        init={{
          setup,
          height: 500,
          menubar: false,
          language: 'pl',
          plugins: [
            'creategallery', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'emoticons', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | '
            + 'bold italic underline strikethrough forecolor | alignleft aligncenter '
            + 'alignright alignjustify | link image creategallery media emoticons | bullist numlist outdent indent | '
            + 'removeformat | code preview help',
          content_style: `
            body {
              font-family:Roboto,Helvetica,Arial,sans-serif;
              font-size:17px;
              line-height: 1.75
            }
            a { color: #f44336; }
            .wysiwyg-gallery {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: 12px;
              margin: 24px 0;
            }
            .wysiwyg-gallery img {
              display: block;
              border-radius: 4px;
              transition: opacity 0.2s ease-in-out;
              cursor: zoom-in;
            }
            .wysiwyg-gallery figure {
              margin: 0;
            }
            .wysiwyg-gallery:hover img {
              opacity: 0.8;
            }
            .wysiwyg-gallery:hover img:hover {
              opacity: 1
            }
          `,
          automatic_uploads: true,
          file_picker_types: 'image',
          images_upload_handler: handleImageUpload,
        }}
      />
      {error && helperText && (
        <Typography color="error" variant="caption">
          {helperText}
        </Typography>
      )}
    </>
  );
};

WysiwygEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

WysiwygEditor.defaultProps = {
  value: '',
  error: false,
  helperText: undefined,
};

export default WysiwygEditor;
