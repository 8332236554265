export default (isoCode) => {
  if (isoCode === 'en') {
    isoCode = 'gb';
  }

  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};
