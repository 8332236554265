import React from 'react';

const KayakIcon = (props) => {
  return (
    <img
      alt="Spływ kajakowy"
      src="/static/images/icons/tourist_attractions/kayak.svg"
      {...props}
    />
  );
};

export default KayakIcon;
