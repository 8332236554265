import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink as RouterLink, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  grey,
  orange,
  red,
} from '@material-ui/core/colors';
import PublicIcon from '@material-ui/icons/Visibility';
import RestrictedIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import DeletedIcon from '@material-ui/icons/Cancel';

import articlesSelectors from 'src/store/selectors/articles';

import TableLoading from 'src/components/TableLoading';
import countryToFlag from 'src/utils/countryToFlag';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteModal,
}) => {
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const articles = useSelector((state) => articlesSelectors.getArticles(state));
  const pagination = useSelector(
    (state) => articlesSelectors.getArticlesPaginationData(state),
  );
  const isFetching = useSelector((state) => articlesSelectors.getArticlesIsFetching(state));

  const currentCategoryId = useMemo(() => (
    searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId'), 10) : null
  ), [searchParams]);

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tytuł
                </TableCell>
                {!currentCategoryId && (
                  <TableCell width={230}>
                    Kategoria
                  </TableCell>
                )}
                <TableCell width={210}>
                  Data
                </TableCell>
                <TableCell align="center" width={105}>
                  Wyświetleń
                </TableCell>
                <TableCell align="center" width={90}>
                  Status
                </TableCell>
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            {articles && (
              <TableBody>
                {articles.length > 0 ? articles.map((data) => (
                  <TableRow
                    hover
                    key={data.id}
                  >
                    <TableCell>
                      {data.title}
                    </TableCell>
                    {!currentCategoryId && (
                      <TableCell>
                        <Link
                          to={`/articles/category/${data.category.id}`}
                          underline="none"
                          style={{ color: 'inherit' }}
                        >
                          {`${countryToFlag(data.category.language)} ${data.category.title}`}
                        </Link>
                      </TableCell>
                    )}
                    <TableCell>
                      {moment(data.date).format('DD MMMM YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {data.views}
                    </TableCell>
                    <TableCell align="center">
                      {data.status === 'public' && <PublicIcon style={{ color: green[500] }} />}
                      {data.status === 'restricted' && <RestrictedIcon style={{ color: orange[500] }} />}
                      {data.status === 'hidden' && <HiddenIcon style={{ color: grey[500] }} />}
                      {data.status === 'deleted' && <DeletedIcon style={{ color: red[500] }} />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/article/${data.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(data.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak artykułów
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
