import React, { useEffect } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Container,
  makeStyles
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import VerifyIcon from '@material-ui/icons/LibraryAddCheck';

import { fetchSuggestions } from 'src/store/actions/suggestions';

import Page from 'src/components/Page';
import Results from '../components/Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SuggestionsListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page') || 1, 10);
  const rowsPerPage = parseInt(searchParams.get('limit') || 10, 10);

  const fetchData = async () => {
    try {
      await dispatch(fetchSuggestions({
        page: currentPage,
        limit: rowsPerPage,
        history: searchParams.has('history'),
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangePage = (event, value) => {
    const params = {
      page: value + 1,
      limit: rowsPerPage,
    };

    if (searchParams.has('history')) {
      params.history = 'true';
    }

    setSearchParams(params);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;

    const params = {
      page: currentPage,
      limit: value,
    };

    if (searchParams.has('history')) {
      params.history = 'true';
    }
    setSearchParams(params);
  };

  return (
    <Page
      className={classes.root}
      title="Propozycje zmian"
    >
      <Container maxWidth={false}>
        <Box textAlign="right">
          {searchParams.has('history') ? (
            <Button
              color="primary"
              startIcon={<VerifyIcon />}
              onClick={() => {
                navigate('/suggestions');
              }}
              variant="outlined"
            >
              Do weryfikacji
            </Button>
          ) : (
            <Button
              color="primary"
              startIcon={<HistoryIcon />}
              onClick={() => {
                navigate('/suggestions?history');
              }}
              variant="outlined"
            >
              Historia zmian
            </Button>
          )}
        </Box>
        <Box mt={3}>
          <Results
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            showStatuses={searchParams.has('history')}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SuggestionsListView;
