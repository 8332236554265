import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';

import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShowIcon from '@material-ui/icons/KeyboardArrowRight';

import getInitials from 'src/utils/getInitials';

import PlaceType from 'src/components/PlaceType';
import ServiceType from 'src/components/ServiceType';
import TouristAttractionType from 'src/components/TouristAttractionType';
import TableLoading from 'src/components/TableLoading';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
    textDecoation: 'none',
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
}));

const Suggestions = ({
  fetchData,
  selectors,
  showPlace,
  showUser,
  showActions,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [currentPage, setPage] = useState(0);

  const suggestions = useSelector((state) => selectors.getSuggestions(state));
  const isFetching = useSelector((state) => selectors.getSuggestionsIsFetching(state));
  const pagination = useSelector((state) => selectors.getSuggestionsPaginationData(state));

  const fetchSuggestions = useCallback(async ({
    page = currentPage + 1,
    limit = 5,
  } = {}) => {
    await dispatch(fetchData(id, { page, limit }))
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania historii zmian', { variant: 'error' });
      });
  }, [currentPage, dispatch, enqueueSnackbar, fetchData, id]);

  useEffect(() => {
    fetchSuggestions();
  }, [fetchSuggestions, id]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <Card>
      <CardHeader title="Historia zmian przez użytkowników" />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                {showPlace && (
                  <TableCell>
                    Miejsce
                  </TableCell>
                )}
                {showUser && (
                  <TableCell>
                    Użytkownik
                  </TableCell>
                )}
                <TableCell width={200}>
                  Data zmian
                </TableCell>
                {showActions && <TableCell width={60} />}
              </TableRow>
            </TableHead>
            {suggestions && (
              <TableBody>
                {suggestions.length > 0 ? suggestions.map((suggestion) => (
                  <TableRow
                    hover
                    key={suggestion.id}
                  >
                    {showPlace && (
                      <TableCell className={classes.placesTitle}>
                        {suggestion.place && (
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/place/${suggestion.place.id}`}
                            underline="none"
                          >
                            {getPlaceIcon(suggestion.place)}
                            {suggestion.place.title}
                          </Link>
                        )}
                      </TableCell>
                    )}
                    {showUser && (
                      <TableCell>
                        {suggestion.username ? (
                          <Box
                            alignItems="center"
                            display="flex"
                          >
                            <Avatar
                              className={classes.avatar}
                            >
                              {getInitials(suggestion.username)}
                            </Avatar>
                            <Typography
                              color="textPrimary"
                              variant="body1"
                            >
                              {suggestion.username}
                            </Typography>
                          </Box>
                        ) : (
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/user/${suggestion.user.id}`}
                            underline="none"
                          >
                            <Box
                              alignItems="center"
                              display="flex"
                            >
                              <Avatar
                                className={classes.avatar}
                                src={suggestion.user.avatar && `${STORAGE_URL}/users/${suggestion.user.id}/${suggestion.user.avatar}`}
                              >
                                {!suggestion.user.avatar && getInitials(suggestion.user.username)}
                              </Avatar>
                              <Typography
                                color="textPrimary"
                                variant="body1"
                              >
                                {suggestion.user.username}
                              </Typography>
                            </Box>
                          </Link>
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      {moment(suggestion.created).startOf('minute').fromNow()}
                    </TableCell>
                    {showActions && (
                      <TableCell>
                        <IconButton
                          component={RouterLink}
                          to={`/suggestions/history/${suggestion.id}`}
                        >
                          <ShowIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={5}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak historii zmian
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handlePageChange}
        page={currentPage}
        rowsPerPage={parseInt(pagination.itemsPerPage, 10)}
        rowsPerPageOptions={[]}
      />
      <Divider />
    </Card>
  );
};

Suggestions.propTypes = {
  fetchData: PropTypes.func.isRequired,
  selectors: PropTypes.shape({
    getSuggestions: PropTypes.func.isRequired,
    getSuggestionsPaginationData: PropTypes.func.isRequired,
    getSuggestionsIsFetching: PropTypes.func.isRequired,
  }).isRequired,
  showActions: PropTypes.bool,
  showPlace: PropTypes.bool,
  showUser: PropTypes.bool,
};

Suggestions.defaultProps = {
  showActions: true,
  showPlace: true,
  showUser: true,
};

export default Suggestions;
