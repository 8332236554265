import React from 'react';

const RestaurantIcon = (props) => {
  return (
    <img
      alt="Restauracja"
      src="/static/images/icons/tourist_attractions/restaurant.svg"
      {...props}
    />
  );
};

export default RestaurantIcon;
