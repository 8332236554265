import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShowIcon from '@material-ui/icons/KeyboardArrowRight';

import unverifiedSelectors from 'src/store/selectors/unverified';

import getInitials from 'src/utils/getInitials';

import TableLoading from 'src/components/TableLoading';
import PlaceType from 'src/components/PlaceType';
import ServiceType from 'src/components/ServiceType';
import TouristAttractionType from 'src/components/TouristAttractionType';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
}) => {
  const classes = useStyles();
  const unverified = useSelector((state) => unverifiedSelectors.getUnverified(state));
  const pagination = useSelector(
    (state) => unverifiedSelectors.getUnverifiedPaginationData(state),
  );
  const isFetching = useSelector((state) => unverifiedSelectors.getUnverifiedIsFetching(state));

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Miejsce
                </TableCell>
                <TableCell width={300}>
                  Dodał
                </TableCell>
                <TableCell width={300}>
                  Data dodania
                </TableCell>
                <TableCell width={95} />
              </TableRow>
            </TableHead>
            {unverified && (
              <TableBody>
                {unverified.length > 0 ? unverified.map((place) => (
                  <TableRow
                    hover
                    key={place.id}
                  >
                    <TableCell className={classes.placesTitle}>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/place/${place.id}`}
                        underline="none"
                      >
                        {getPlaceIcon(place)}
                        {place.title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {place.user && (
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/user/${place.user.id}`}
                          underline="none"
                        >
                          <Box
                            alignItems="center"
                            display="flex"
                          >
                            <Avatar
                              className={classes.avatar}
                              src={place.user.avatar && `${STORAGE_URL}/users/${place.user.id}/${place.user.avatar}`}
                            >
                              {!place.user.avatar && getInitials(place.user.username)}
                            </Avatar>
                            {place.user.username}
                          </Box>
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(place.created).startOf('minute').fromNow()}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/place/${place.id}`}
                      >
                        <ShowIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak nowych miejsc
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
};

export default Results;
