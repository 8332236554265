import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  grey,
  orange,
  red,
} from '@material-ui/core/colors';
import PublicIcon from '@material-ui/icons/Visibility';
import RestrictedIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import DeletedIcon from '@material-ui/icons/Cancel';

import pagesSelectors from 'src/store/selectors/pages';

import TableLoading from 'src/components/TableLoading';
import countryToFlag from 'src/utils/countryToFlag';
import {
  codes as supportedLocalesCodes,
} from 'src/utils/locales';

const countryFlagVisibleStates = ['public', 'restricted'];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  openDeleteModal,
}) => {
  const classes = useStyles();

  const pages = useSelector((state) => pagesSelectors.getPages(state));
  const pagination = useSelector(
    (state) => pagesSelectors.getPagesPaginationData(state),
  );
  const isFetching = useSelector((state) => pagesSelectors.getPagesIsFetching(state));

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tytuł
                </TableCell>
                <TableCell>
                  Url
                </TableCell>
                <TableCell width={115}>
                  Typ
                </TableCell>
                <TableCell width={125}>
                  Języki
                </TableCell>
                <TableCell align="center" width={115}>
                  Status
                </TableCell>
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            {pages && (
              <TableBody>
                {pages.length > 0 ? pages.map((page) => (
                  <TableRow
                    hover
                    key={page.id}
                  >
                    <TableCell>
                      {page.title}
                    </TableCell>
                    <TableCell>
                      {`/${page.slug}`}
                    </TableCell>
                    <TableCell>
                      {page.type === 'page' && 'Strona'}
                      {page.type === 'element' && 'Element'}
                    </TableCell>
                    <TableCell>
                      {supportedLocalesCodes.map((locale) => page[`page_${locale}`] && countryFlagVisibleStates.includes(page[`page_${locale}`].status) && countryToFlag(locale))}
                    </TableCell>
                    <TableCell align="center">
                      {page.status === 'public' && <PublicIcon style={{ color: green[500] }} />}
                      {page.status === 'restricted' && <RestrictedIcon style={{ color: orange[500] }} />}
                      {page.status === 'hidden' && <HiddenIcon style={{ color: grey[500] }} />}
                      {page.status === 'deleted' && <DeletedIcon style={{ color: red[500] }} />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/page/${page.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton disabled={page.type === 'element'} onClick={() => openDeleteModal(page.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak stron
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default Results;
