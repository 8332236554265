export default {
  getActivities: (state) => {
    const activities = { ...state.dashboard.activities };

    delete activities.isFetching;

    return activities;
  },
  getData: (state) => state.dashboard.data,
  getCheckIns: (state) => state.dashboard.data.checkIns,
  getComments: (state) => state.dashboard.data.comments,
  getPlaces: (state) => state.dashboard.data.places,
  getPlacesCount: (state) => state.dashboard.data.placesCount,
  getSuggestionsCount: (state) => state.dashboard.data.suggestionsCount,
  getUnverifiedPlacesCount: (state) => state.dashboard.data.unverifiedPlacesCount,
  getCheckInsCount: (state) => state.dashboard.data.checkInsCount,
  getCommentsCount: (state) => state.dashboard.data.commentsCount,
  getUsersCount: (state) => state.dashboard.data.usersCount,
  getForeignUsersCount: (state) => state.dashboard.data.foreignUsersCount,

  isActivitiesFetching: (state) => state.dashboard.activities.isFetching,
  isDataFetching: (state) => state.dashboard.data.isFetching,
};
