import {
  CLEAR_DATA,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_ARTICLES_FAILURE,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  SET_FILTERS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY_DETAILS_FAILURE,
  GET_CATEGORY_DETAILS_REQUEST,
  GET_CATEGORY_DETAILS_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from '../actions/articles';

const initialState = {
  categories: {
    isFetching: false,
    list: [],
    totalCount: 0,
    details: null,
  },
  details: {
    isFetching: false,
    data: null,
  },
  filters: {},
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        categories: initialState.categories,
        details: initialState.details,
        list: initialState.list,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case GET_ARTICLES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_ARTICLES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          ...action.payload,
        }
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: true,
        },
      };
    case DELETE_CATEGORY_SUCCESS:
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: false,
        },
      };
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: true,
        }
      };
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: false,
        }
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          list: action.payload.list,
          totalCount: action.payload.totalCount,
          isFetching: false,
        }
      };
    case GET_CATEGORY_DETAILS_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: false,
        }
      };
    case GET_CATEGORY_DETAILS_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: false,
        }
      };
    case GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          details: action.payload,
          isFetching: false,
        }
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: true,
        }
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: true,
          list: action.payload,
        }
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default articlesReducer;
