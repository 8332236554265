import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import {
  BivouacIcon,
  CampingIcon,
  CamperParkIcon,
  ShedIcon,
  ParkingIcon,
  PinIcon,
  WildIcon,
} from './icons';

const useStyles = makeStyles({
  icon: ({ size }) => ({
    width: size,
    height: size,
  }),
});

const PlaceType = ({
  className,
  type,
  ...props
}) => {
  const classes = useStyles(props);

  switch (type) {
    case 'bivouac':
      return <BivouacIcon className={clsx(classes.icon, className)} />;
    case 'camping':
      return <CampingIcon className={clsx(classes.icon, className)} />;
    case 'camper_park':
      return <CamperParkIcon className={clsx(classes.icon, className)} />;
    case 'parking':
      return <ParkingIcon className={clsx(classes.icon, className)} />;
    case 'shed':
      return <ShedIcon className={clsx(classes.icon, className)} />;
    case 'wild':
      return <WildIcon className={clsx(classes.icon, className)} />;
    default:
      return <PinIcon className={clsx(classes.icon, className)} />;
  }
};

PlaceType.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
};

PlaceType.defaultProps = {
  className: null,
  size: 24,
};

export default PlaceType;
