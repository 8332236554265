import request from '../../utils/request';

const PREFIX = '[DASHBOARD]';

export const GET_DATA_REQUEST = `${PREFIX} GET_DATA_REQUEST`;
export const GET_DATA_SUCCESS = `${PREFIX} GET_DATA_SUCCESS`;
export const GET_DATA_FAILURE = `${PREFIX} GET_DATA_FAILURE`;

const fetchDataRequest = () => ({
  type: GET_DATA_REQUEST,
});

const fetchDataSuccess = (payload) => ({
  payload,
  type: GET_DATA_SUCCESS,
});

const fetchDataFailure = () => ({
  type: GET_DATA_FAILURE,
});

export const fetchData = () => (dispatch) => {
  dispatch(fetchDataRequest());

  return request
    .get('v1/dashboard/')
    .then(({ data }) => {
      dispatch(fetchDataSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchDataFailure());

      throw error;
    });
};

export const GET_ACTIVITIES_REQUEST = `${PREFIX} GET_ACTIVITIES_REQUEST`;
export const GET_ACTIVITIES_SUCCESS = `${PREFIX} GET_ACTIVITIES_SUCCESS`;
export const GET_ACTIVITIES_FAILURE = `${PREFIX} GET_ACTIVITIES_FAILURE`;

const fetchActivitiesRequest = () => ({
  type: GET_ACTIVITIES_REQUEST,
});

const fetchActivitiesSuccess = (payload) => ({
  payload,
  type: GET_ACTIVITIES_SUCCESS,
});

const fetchActivitiesFailure = () => ({
  type: GET_ACTIVITIES_FAILURE,
});

export const fetchActivities = (period = 7) => (dispatch) => {
  dispatch(fetchActivitiesRequest());

  return request
    .get(`v1/dashboard/activities/${period}`)
    .then(({ data }) => {
      dispatch(fetchActivitiesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchActivitiesFailure());

      throw error;
    });
};
