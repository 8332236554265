import {
  GET_DISCOUNTS_FAILURE,
  GET_DISCOUNTS_REQUEST,
  GET_DISCOUNTS_SUCCESS,
} from '../actions/discounts';

const initialState = {
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const discountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCOUNTS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_DISCOUNTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default discountsReducer;
