import {
  CLEAR_DATA,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_MAP_FAILURE,
  GET_MAP_REQUEST,
  GET_MAP_SUCCESS,
  GET_PLACES_FAILURE,
  GET_PLACES_REQUEST,
  GET_PLACES_SUCCESS,
  GET_CLOSE_PLACES_FAILURE,
  GET_CLOSE_PLACES_REQUEST,
  GET_CLOSE_PLACES_SUCCESS,
  GET_CHECKINS_FAILURE,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  GET_SUGGESTIONS_REQUEST,
  GET_SUGGESTIONS_SUCCESS,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SET_CAMPINGS_FILTERS,
  SET_PLACES_FILTERS,
  SET_SERVICES_FILTERS,
  SET_TOURIST_ATTRACTIONS_FILTERS,
  SET_MAP_TYPE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/places';
import {
  DELETE_FAILURE as DELETE_CHECKIN_FAILURE,
  DELETE_REQUEST as DELETE_CHECKIN_REQUEST,
  DELETE_SUCCESS as DELETE_CHECKIN_SUCCESS,
} from '../actions/check-ins';
import {
  ADD_FAILURE as ADD_COMMENT_FAILURE,
  ADD_REQUEST as ADD_COMMENT_REQUEST,
  ADD_SUCCESS as ADD_COMMENT_SUCCESS,
  DELETE_FAILURE as DELETE_COMMENT_FAILURE,
  DELETE_REQUEST as DELETE_COMMENT_REQUEST,
  DELETE_SUCCESS as DELETE_COMMENT_SUCCESS,
  UPDATE_FAILURE as UPDATE_COMMENT_FAILURE,
  UPDATE_REQUEST as UPDATE_COMMENT_REQUEST,
  UPDATE_SUCCESS as UPDATE_COMMENT_SUCCESS,
} from '../actions/comments';
import parseMapMarker from '../../utils/parseMapMarker';

const initialState = {
  map: {
    current: null,
    isFetching: false,
    data: [],
  },
  filters: {
    campings: {},
    places: {},
    services: {},
    tourist_attractions: {},
  },
  details: {
    isFetching: false,
    data: null,
    closePlaces: {
      isFetching: false,
      results: null
    },
    comments: {
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
    checkIns: {
      items: null,
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
    suggestions: {
      items: null,
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
  },
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const placesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        details: initialState.details,
        list: initialState.list,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case DELETE_CHECKIN_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: true,
          }
        },
      };
    case DELETE_CHECKIN_SUCCESS:
    case DELETE_CHECKIN_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: false,
          }
        },
      };
    case ADD_COMMENT_REQUEST:
    case DELETE_COMMENT_REQUEST:
    case UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: true,
          }
        },
      };
    case ADD_COMMENT_SUCCESS:
    case ADD_COMMENT_FAILURE:
    case DELETE_COMMENT_SUCCESS:
    case DELETE_COMMENT_FAILURE:
    case UPDATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: false,
          }
        },
      };
    case GET_MAP_REQUEST:
      return {
        ...state,
        map: {
          ...state.map,
          isFetching: true,
        },
      };
    case GET_MAP_FAILURE:
      return {
        ...state,
        map: {
          ...state.map,
          isFetching: false,
        },
      };
    case GET_MAP_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          data: action.payload,
          isFetching: false,
        }
      };
    case GET_PLACES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_PLACES_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_PLACES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case GET_CHECKINS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: true,
          }
        }
      };
    case GET_CHECKINS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: false,
          }
        }
      };
    case GET_CHECKINS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            ...action.payload,
            isFetching: false,
          }
        }
      };
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: true,
          }
        }
      };
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: false,
          }
        }
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            ...action.payload,
            isFetching: false,
          }
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          checkIns: initialState.details.checkIns,
          comments: initialState.details.comments,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case GET_CLOSE_PLACES_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          closePlaces: {
            ...state.details.closePlaces,
            isFetching: true,
          },
        }
      };
    case GET_CLOSE_PLACES_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          closePlaces: {
            ...state.details.closePlaces,
            isFetching: false,
          },
        }
      };
    case GET_CLOSE_PLACES_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          closePlaces: {
            ...state.details.closePlaces,
            isFetching: false,
            results: action.payload
              ? action.payload.map(
                (place) => parseMapMarker(place)
              ).filter(
                (place) => place.id !== state.details.data.id
              ) : [],
          },
        }
      };
    case GET_SUGGESTIONS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          suggestions: {
            ...state.details.suggestions,
            isFetching: true,
          }
        }
      };
    case GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          suggestions: {
            ...state.details.suggestions,
            isFetching: false,
          }
        }
      };
    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          suggestions: {
            ...state.details.suggestions,
            ...action.payload,
            isFetching: false,
          }
        }
      };
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case SEND_EMAIL_FAILURE:
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case SET_CAMPINGS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          campings: action.payload,
        },
      };
    case SET_PLACES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          places: action.payload,
        },
      };
    case SET_SERVICES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          services: action.payload,
        },
      };
    case SET_TOURIST_ATTRACTIONS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          tourist_attractions: action.payload,
        },
      };
    case SET_MAP_TYPE:
      return {
        ...state,
        map: {
          ...state.map,
          current: action.payload,
        },
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default placesReducer;
