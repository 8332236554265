import React from 'react';

const StonesIcon = (props) => {
  return (
    <img
      alt="Skały, skalne miasto"
      src="/static/images/icons/tourist_attractions/stones.svg"
      {...props}
    />
  );
};

export default StonesIcon;
