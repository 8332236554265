import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { uploadImage } from 'src/store/actions/settings';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';

import FileUploadDialog from 'src/components/FileUploadDialog';

const { PUBLIC_URL } = process.env;
const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
  },
  field: {
    width: '100%',
    '& input': {
      textAlign: 'center',
    },
  },
  media: {
    display: 'block',
    width: '100%',
  },
}));

const ImageSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isFileUploadOpen, setFileUploadOpen] = useState(false);
  const [imageHash, setImageHash] = useState('');

  const openFileUploadDialog = () => {
    setFileUploadOpen(true);
  };

  const closeFileUploadDialog = () => {
    setFileUploadOpen(false);
  };

  const onSubmit = async (file) => {
    const formData = new FormData();

    if (file) {
      formData.append('picture', file[0].file);
    }

    try {
      await dispatch(uploadImage(formData));
      setImageHash(`?${Date.now()}`);
      enqueueSnackbar('Zdjęcie w tle zostało zmienione', { variant: 'success' });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił problem podczas wysyłania zdjęcia', { variant: 'error' });

      return error;
    }
  };

  return (
    <Card>
      <CardHeader title="Zdjęcie w tle" />
      <Divider />
      <CardContent className={classes.content}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <img
            className={classes.media}
            src={`${STORAGE_URL}/images/picture.jpg${imageHash}`}
            alt="Zdjęcie w tle"
            onError={(event) => {
              event.target.onerror = null;
              event.target.src = `${PUBLIC_URL}/static/images/car_placeholder.png`;
            }}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box paddingX={1} paddingY={1}>
        <TextField
          className={classes.field}
          label="Samochód/Kamper/Przyczepa"
          name="car"
          size="small"
          variant="outlined"
        />
      </Box>
      <Divider />
      <CardActions>
        <Button
          color="secondary"
          fullWidth
          onClick={openFileUploadDialog}
          variant="text"
        >
          Zmień zdjęcie
        </Button>
      </CardActions>
      <FileUploadDialog
        filesLimit={1}
        handleClose={closeFileUploadDialog}
        isOpen={isFileUploadOpen}
        setFiles={onSubmit}
      />
    </Card>
  );
};

export default ImageSettings;
