export default (type) => {
  switch (type) {
    case 'wild':
      return 'Dzikie miejsce biwakowe';
    case 'shed':
      return 'Miejsce z infrastrukturą';
    case 'parking':
      return 'Parking';
    case 'camper_park':
      return 'Kamper park';
    case 'camping':
      return 'Kemping';
    case 'bivouac':
      return 'Pole biwakowe';
    case 'tourist_attraction':
      return 'Atrakcja turystyczna';

      // Atrakcje turystyczne

    case 'swimming_pool':
      return 'Basen';
    case 'brewery':
      return 'Browar';
    case 'bunker':
      return 'Bunkier, twierdza, fort';
    case 'cementary':
      return 'Cmentarz';
    case 'mountain':
      return 'Góra, szczyt, pasmo';
    case 'cave':
      return 'Jaskinia';
    case 'lake':
      return 'Jezioro, rzeka, staw';
    case 'quarry':
      return 'Kamieniołom';
    case 'cross':
      return 'Kapliczka, krzyż przydrożny';
    case 'cable_car':
      return 'Kolej linowa, wyciąg';
    case 'lighthouse':
      return 'Latarnia morska';
    case 'city':
      return 'Miasto';
    case 'bridge':
      return 'Most';
    case 'watermill':
      return 'Młyn wodny, jaz';
    case 'museum':
      return 'Muzeum, kultura i sztuka';
    case 'palace':
      return 'Pałac, dwór';
    case 'park':
      return 'Park, ogród, rezerwat';
    case 'restaurant':
      return 'Restauracja';
    case 'theme_park':
      return 'Park rozrywki';
    case 'beach':
      return 'Plaża';
    case 'underground_path':
      return 'Podziemna trasa turystyczna';
    case 'monument':
      return 'Pomnik, fontanna, mała architektura';
    case 'overlook':
      return 'Punkt widokowy';
    case 'marina':
      return 'Rejs statkiem, marina';
    case 'stones':
      return 'Skała, skalne miasto';
    case 'village':
      return 'Skansen, wiejska zabudowa';
    case 'archeology':
      return 'Stanowisko archeologiczne';
    case 'kayak':
      return 'Trasa kajakowa, wypożyczalnia';
    case 'train':
      return 'Trasa kolejowa, wąskotorowa';
    case 'horse':
      return 'Trasa konna, stadnina';
    case 'ski':
      return 'Trasa narciarskia, stok';
    case 'trekking':
      return 'Trasa piesza, szlak';
    case 'bike':
      return 'Trasa rowerowa, wypożyczalnia';
    case 'car_overlook':
      return 'Trasa widokowa samochodowa';
    case 'tunnel':
      return 'Tunel';
    case 'urbex':
      return 'Urbex';
    case 'canyon':
      return 'Wąwóz, kanion';
    case 'windmill':
      return 'Wiatrak';
    case 'tower':
      return 'Wieża widokowa';
    case 'wine':
      return 'Winnica';
    case 'waterfall':
      return 'Wodospad';
    case 'technical':
      return 'Zabytek techniki';
    case 'church_wood':
      return 'Zabytek sakralny drewniany';
    case 'church':
      return 'Zabytek sakralny murowany';
    case 'building':
      return 'Zabytkowa zabudowa';
    case 'castle':
      return 'Zamek';
    case 'dam':
      return 'Zapora wodna';
    case 'zoo':
      return 'Zoo';
    case 'thermae':
      return 'Źródła termalne';
    case 'other':
      return 'Inna atrakcja';

      // Miejsca serwisowe

    case 'camper_station':
      return 'Serwis kampera';
    case 'camp_store':
      return 'Sklep kempingowy';
    case 'caravan_salon':
      return 'Wypożyczalnia / Salon / Komis';
    case 'laundry':
      return 'Pralnia';
    case 'lpg':
      return 'Punkt napełniania butli LPG';
    case 'shower':
      return 'Prysznic';
    case 'water':
      return 'Woda';

      //

    default:
      return 'Nieokreślony typ miejsca';
  }
};
