import React from 'react';

const MountainIcon = (props) => {
  return (
    <img
      alt="Góra"
      src="/static/images/icons/tourist_attractions/mountain.svg"
      {...props}
    />
  );
};

export default MountainIcon;
