import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import {
  clearAdsData,
  deleteAd,
  fetchAds,
  setFilters,
} from 'src/store/actions/ads';
import adsSelectors from 'src/store/selectors/ads';

import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import Results from '../components/Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdsListView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteAlertRef = useRef();

  const [isInited, setIsInited] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const search = searchParams.get('search') || null;
  const currentAd = parseInt(searchParams.get('page') || 1, 10);
  const rowsPerAd = parseInt(searchParams.get('limit') || 10, 10);

  const isFetching = useSelector((state) => adsSelectors.getAdsIsFetching(state));
  const filters = useSelector((state) => adsSelectors.getFilters(state));

  const fetchData = async () => {
    try {
      await dispatch(fetchAds({
        search,
        page: currentAd,
        limit: rowsPerAd,
        ...filters,
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
    }
  };

  const setFiltersFromSearchParams = () => {
    // eslint-disable-next-line no-unused-vars
    const { page, limit, ...filtersArray } = Object.fromEntries(searchParams);

    dispatch(setFilters(filtersArray));
  };

  useEffect(() => {
    setIsInited(true);
    return () => {
      dispatch(clearAdsData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInited) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFiltersFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeAd = (event, value) => {
    setSearchParams({
      page: value + 1,
      limit: rowsPerAd,
      ...filters
    });
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setSearchParams({
      page: currentAd,
      limit: value,
      ...filters
    });
  };

  const handleSearch = (values) => {
    setSearchParams({
      page: currentAd,
      limit: rowsPerAd,
      ...values
    });
  };

  const openDeleteModal = (id) => {
    deleteAlertRef.current.open();
    setSelectedId(id);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteAd(selectedId));
      deleteAlertRef.current.close();
      setSelectedId(null);
      enqueueSnackbar('Reklama została usunięta', { variant: 'success' });

      await fetchData();

      return true;
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania reklamy', { variant: 'error' });

      return error;
    }
  };

  return (
    <Page
      className={classes.root}
      title="Reklamy"
    >
      <Container maxWidth={false}>
        <Toolbar handleSearch={handleSearch} />
        <Box mt={3}>
          <Results
            handleChangeAd={handleChangeAd}
            handleLimitChange={handleLimitChange}
            openDeleteModal={openDeleteModal}
          />
        </Box>
      </Container>
      <AlertDialog
        title="Usuwanie reklamy"
        message="Czy na pewno chcesz usunąć reklamę?"
        ref={deleteAlertRef}
        actions={[
          {
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Page>
  );
};

export default AdsListView;
