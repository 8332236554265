import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ height }) => height,
  }
});

const Loading = ({ height }) => {
  const classes = useStyles({ height });

  return (
    <Box className={classes.root}>
      <CircularProgress color="secondary" size={64} />
    </Box>
  );
};

Loading.propTypes = {
  height: PropTypes.string,
};

Loading.defaultProps = {
  height: '85vh',
};

export default Loading;
