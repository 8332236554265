import React from 'react';

const DamIcon = (props) => {
  return (
    <img
      alt="Zapora wodna"
      src="/static/images/icons/tourist_attractions/dam.svg"
      {...props}
    />
  );
};

export default DamIcon;
