import React from 'react';

const CamperStationIcon = (props) => {
  return (
    <img
      alt="Serwis kampera"
      src="/static/images/icons/services/camper_station.svg"
      {...props}
    />
  );
};

export default CamperStationIcon;
