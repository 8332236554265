import request from '../../utils/request';

const PREFIX = '[PAGES]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearPagesData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createPageRequest = () => ({
  type: CREATE_REQUEST,
});

const createPageSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createPageFailure = () => ({
  type: CREATE_FAILURE,
});

export const createPage = (payload) => (dispatch) => {
  dispatch(createPageRequest());

  return request
    .post('v1/pages/', payload)
    .then(({ data }) => {
      dispatch(createPageSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createPageFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deletePageRequest = () => ({
  type: DELETE_REQUEST,
});

const deletePageSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deletePageFailure = () => ({
  type: DELETE_FAILURE,
});

export const deletePage = (id) => (dispatch) => {
  dispatch(deletePageRequest());

  return request
    .delete(`v1/pages/${id}`)
    .then(({ data }) => {
      dispatch(deletePageSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deletePageFailure());

      throw error;
    });
};

export const GET_PAGES_REQUEST = `${PREFIX} GET_PAGES_REQUEST`;
export const GET_PAGES_SUCCESS = `${PREFIX} GET_PAGES_SUCCESS`;
export const GET_PAGES_FAILURE = `${PREFIX} GET_PAGES_FAILURE`;

const fetchPagesRequest = () => ({
  type: GET_PAGES_REQUEST,
});

const fetchPagesSuccess = (payload) => ({
  payload,
  type: GET_PAGES_SUCCESS,
});

const fetchPagesFailure = () => ({
  type: GET_PAGES_FAILURE,
});

export const fetchPages = (filters) => (dispatch) => {
  dispatch(fetchPagesRequest());

  return request
    .get('v1/pages/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchPagesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPagesFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchPageDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchPageDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchPageDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchPageDetails = (language, id) => (dispatch) => {
  dispatch(fetchPageDetailsRequest());

  return request
    .get(`v1/pages/${id}?isAdmin&language=${language}`)
    .then(({ data }) => {
      dispatch(fetchPageDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPageDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updatePageRequest = () => ({
  type: UPDATE_REQUEST,
});

const updatePageSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updatePageFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updatePage = (id, payload) => (dispatch) => {
  dispatch(updatePageRequest());

  return request
    .put(`v1/pages/${id}`, payload)
    .then(({ data }) => {
      dispatch(updatePageSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updatePageFailure());

      throw error;
    });
};
