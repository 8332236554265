import request from '../../utils/request';

const PREFIX = '[CHECK-INS]';

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteCheckInRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteCheckInSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteCheckInFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteCheckIn = (id) => (dispatch) => {
  dispatch(deleteCheckInRequest());

  return request
    .delete(`v1/check-ins/${id}`)
    .then(({ data }) => {
      dispatch(deleteCheckInSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteCheckInFailure());

      throw error;
    });
};
