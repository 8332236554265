import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import {
  green,
  red,
} from '@material-ui/core/colors';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InActiveIcon from '@material-ui/icons/Cancel';
import ShowIcon from '@material-ui/icons/KeyboardArrowRight';

import suggestionsSelectors from 'src/store/selectors/suggestions';

import getInitials from 'src/utils/getInitials';

import TableLoading from 'src/components/TableLoading';
import PlaceType from 'src/components/PlaceType';
import ServiceType from 'src/components/ServiceType';
import TouristAttractionType from 'src/components/TouristAttractionType';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
}));

const Results = ({
  handleChangePage,
  handleLimitChange,
  showStatuses,
}) => {
  const classes = useStyles();
  const suggestions = useSelector((state) => suggestionsSelectors.getSuggestions(state));
  const pagination = useSelector(
    (state) => suggestionsSelectors.getSuggestionsPaginationData(state),
  );
  const isFetching = useSelector((state) => suggestionsSelectors.getSuggestionsIsFetching(state));

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Miejsce
                </TableCell>
                <TableCell width={300}>
                  Zgłaszający
                </TableCell>
                {showStatuses && (
                  <TableCell width={115}>
                    Status
                  </TableCell>
                )}
                <TableCell width={300}>
                  Data dodania
                </TableCell>
                <TableCell width={95} />
              </TableRow>
            </TableHead>
            {suggestions && (
              <TableBody>
                {suggestions.length > 0 ? suggestions.map((suggestion) => (
                  <TableRow
                    hover
                    key={suggestion.id}
                  >
                    <TableCell className={classes.placesTitle}>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/suggestions/${suggestion.status !== 'unverified' ? 'history/' : ''}${suggestion.id}`}
                        underline="none"
                      >
                        {getPlaceIcon(suggestion.place)}
                        {suggestion.place.title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/user/${suggestion.user.id}`}
                        underline="none"
                      >
                        <Box
                          alignItems="center"
                          display="flex"
                        >
                          <Avatar
                            className={classes.avatar}
                            src={suggestion.user.avatar && `${STORAGE_URL}/users/${suggestion.user.id}/${suggestion.user.avatar}`}
                          >
                            {!suggestion.user.avatar && getInitials(suggestion.user.username)}
                          </Avatar>
                          {suggestion.user.username}
                        </Box>
                      </Link>
                    </TableCell>
                    {showStatuses && (
                      <TableCell>
                        {suggestion.status === 'approved' && <ActiveIcon style={{ color: green[500] }} />}
                        {suggestion.status === 'rejected' && <InActiveIcon style={{ color: red[500] }} />}
                      </TableCell>
                    )}
                    <TableCell>
                      {moment(suggestion.created).startOf('minute').fromNow()}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/suggestions/${suggestion.status !== 'unverified' ? 'history/' : ''}${suggestion.id}`}
                      >
                        <ShowIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak nowych propozycji zmian
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleLimitChange}
        page={pagination.currentPage ? parseInt(pagination.currentPage, 10) - 1 : 0}
        rowsPerPage={pagination.itemsPerPage ? parseInt(pagination.itemsPerPage, 10) : 25}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
  showStatuses: PropTypes.bool.isRequired,
};

export default Results;
