export default {
  getCheckIns: (state) => state.users.details.checkIns.items,
  getCheckInsPaginationData: (state) => state.users.details.checkIns.meta,
  getCheckInsIsFetching: (state) => state.users.details.checkIns.isFetching,

  getFilters: (state) => state.users.filters,

  getComments: (state) => state.users.details.comments.items,
  getCommentsPaginationData: (state) => state.users.details.comments.meta,
  getCommentsIsFetching: (state) => state.users.details.comments.isFetching,

  getUserDetails: (state) => state.users.details.data,
  getUserDetailsIsFetching: (state) => state.users.details.isFetching,

  getUsers: (state) => state.users.list.items,
  getUsersPaginationData: (state) => state.users.list.meta,
  getUsersIsFetching: (state) => state.users.list.isFetching,
};
