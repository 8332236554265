import request from 'src/utils/request';

const PREFIX = '[PLACES]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearPlacesData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createPlaceRequest = () => ({
  type: CREATE_REQUEST,
});

const createPlaceSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createPlaceFailure = () => ({
  type: CREATE_FAILURE,
});

export const createPlace = (payload) => (dispatch) => {
  dispatch(createPlaceRequest());

  return request
    .post('v1/places/', payload)
    .then(({ data }) => {
      dispatch(createPlaceSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createPlaceFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deletePlaceRequest = () => ({
  type: DELETE_REQUEST,
});

const deletePlaceSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deletePlaceFailure = () => ({
  type: DELETE_FAILURE,
});

export const deletePlace = (id, reason) => (dispatch) => {
  dispatch(deletePlaceRequest());

  return request
    .delete(`v1/places/${id}`, { params: { reason } })
    .then(({ data }) => {
      dispatch(deletePlaceSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deletePlaceFailure());

      throw error;
    });
};

export const GET_MAP_REQUEST = `${PREFIX} GET_MAP_REQUEST`;
export const GET_MAP_SUCCESS = `${PREFIX} GET_MAP_SUCCESS`;
export const GET_MAP_FAILURE = `${PREFIX} GET_MAP_FAILURE`;

const fetchMapPlacesRequest = () => ({
  type: GET_MAP_REQUEST,
});

const fetchMapPlacesSuccess = (payload) => ({
  payload,
  type: GET_MAP_SUCCESS,
});

const fetchMapPlacesFailure = () => ({
  type: GET_MAP_FAILURE,
});

export const fetchMapPlaces = (type, filters) => (dispatch) => {
  dispatch(fetchMapPlacesRequest());

  filters.showPlaces = type === 'places';
  filters.showCampings = type === 'campings';
  filters.showTouristAttractions = type === 'tourist_attractions';
  filters.showServices = type === 'services';

  return request
    .get('v1/places/map', { params: filters })
    .then(({ data }) => {
      dispatch(fetchMapPlacesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchMapPlacesFailure());

      throw error;
    });
};

export const GET_PLACES_REQUEST = `${PREFIX} GET_PLACES_REQUEST`;
export const GET_PLACES_SUCCESS = `${PREFIX} GET_PLACES_SUCCESS`;
export const GET_PLACES_FAILURE = `${PREFIX} GET_PLACES_FAILURE`;

const fetchPlacesRequest = () => ({
  type: GET_PLACES_REQUEST,
});

const fetchPlacesSuccess = (payload) => ({
  payload,
  type: GET_PLACES_SUCCESS,
});

const fetchPlacesFailure = () => ({
  type: GET_PLACES_FAILURE,
});

export const fetchPlaces = (type, filters) => (dispatch) => {
  dispatch(fetchPlacesRequest());

  filters.showPlaces = type === 'places';
  filters.showCampings = type === 'campings';
  filters.showTouristAttractions = type === 'tourist_attractions';
  filters.showServices = type === 'services';

  return request
    .get('v1/places/', { params: filters })
    .then(({ data }) => {
      dispatch(fetchPlacesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlacesFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchPlaceDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchPlaceDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchPlaceDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchPlaceDetails = (id) => (dispatch) => {
  dispatch(fetchPlaceDetailsRequest());

  return request
    .get(`v1/places/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchPlaceDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlaceDetailsFailure());

      throw error;
    });
};

export const GET_CLOSE_PLACES_REQUEST = `${PREFIX} GET_CLOSE_PLACES_REQUEST`;
export const GET_CLOSE_PLACES_SUCCESS = `${PREFIX} GET_CLOSE_PLACES_SUCCESS`;
export const GET_CLOSE_PLACES_FAILURE = `${PREFIX} GET_CLOSE_PLACES_FAILURE`;

const fetchClosePlacesRequest = () => ({
  type: GET_CLOSE_PLACES_REQUEST,
});

const fetchClosePlacesSuccess = (payload) => ({
  payload,
  type: GET_CLOSE_PLACES_SUCCESS,
});

const fetchClosePlacesFailure = () => ({
  type: GET_CLOSE_PLACES_FAILURE,
});

export const fetchClosePlaces = (latitude, longitude, distance) => (dispatch) => {
  dispatch(fetchClosePlacesRequest());

  return request
    .get(`v1/places/map?latitude=${latitude}&longitude=${longitude}&distance=${distance}&isAdmin`)
    .then(({ data }) => {
      dispatch(fetchClosePlacesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchClosePlacesFailure());

      throw error;
    });
};

export const GET_CHECKINS_REQUEST = `${PREFIX} GET_CHECKINS_REQUEST`;
export const GET_CHECKINS_SUCCESS = `${PREFIX} GET_CHECKINS_SUCCESS`;
export const GET_CHECKINS_FAILURE = `${PREFIX} GET_CHECKINS_FAILURE`;

const fetchPlaceCheckInsRequest = () => ({
  type: GET_CHECKINS_REQUEST,
});

const fetchPlaceCheckInsSuccess = (payload) => ({
  payload,
  type: GET_CHECKINS_SUCCESS,
});

const fetchPlaceCheckInsFailure = () => ({
  type: GET_CHECKINS_FAILURE,
});

export const fetchPlaceCheckIns = (id, pagination) => (dispatch) => {
  dispatch(fetchPlaceCheckInsRequest());

  return request
    .get(`v1/places/${id}/check-ins`, { params: pagination })
    .then(({ data }) => {
      dispatch(fetchPlaceCheckInsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlaceCheckInsFailure());

      throw error;
    });
};

export const GET_COMMENTS_REQUEST = `${PREFIX} GET_COMMENTS_REQUEST`;
export const GET_COMMENTS_SUCCESS = `${PREFIX} GET_COMMENTS_SUCCESS`;
export const GET_COMMENTS_FAILURE = `${PREFIX} GET_COMMENTS_FAILURE`;

const fetchPlaceCommentsRequest = () => ({
  type: GET_COMMENTS_REQUEST,
});

const fetchPlaceCommentsSuccess = (payload) => ({
  payload,
  type: GET_COMMENTS_SUCCESS,
});

const fetchPlaceCommentsFailure = () => ({
  type: GET_COMMENTS_FAILURE,
});

export const fetchPlaceComments = (id, pagination) => (dispatch) => {
  dispatch(fetchPlaceCommentsRequest());

  return request
    .get(`v1/places/${id}/comments`, { params: pagination })
    .then(({ data }) => {
      dispatch(fetchPlaceCommentsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlaceCommentsFailure());

      throw error;
    });
};

export const GET_SUGGESTIONS_REQUEST = `${PREFIX} GET_SUGGESTIONS_REQUEST`;
export const GET_SUGGESTIONS_SUCCESS = `${PREFIX} GET_SUGGESTIONS_SUCCESS`;
export const GET_SUGGESTIONS_FAILURE = `${PREFIX} GET_SUGGESTIONS_FAILURE`;

const fetchPlaceSuggestionsRequest = () => ({
  type: GET_SUGGESTIONS_REQUEST,
});

const fetchPlaceSuggestionsSuccess = (payload) => ({
  payload,
  type: GET_SUGGESTIONS_SUCCESS,
});

const fetchPlaceSuggestionsFailure = () => ({
  type: GET_SUGGESTIONS_FAILURE,
});

export const fetchPlaceSuggestions = (id, pagination) => (dispatch) => {
  dispatch(fetchPlaceSuggestionsRequest());

  return request
    .get(`v1/places/${id}/suggestions`, { params: pagination })
    .then(({ data }) => {
      dispatch(fetchPlaceSuggestionsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchPlaceSuggestionsFailure());

      throw error;
    });
};

export const recalculateCounters = () => {
  return request
    .post('v1/places/counters/update')
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const SEND_EMAIL_REQUEST = `${PREFIX} SEND_EMAIL_REQUEST`;
export const SEND_EMAIL_SUCCESS = `${PREFIX} SEND_EMAIL_SUCCESS`;
export const SEND_EMAIL_FAILURE = `${PREFIX} SEND_EMAIL_FAILURE`;

const sendQuestionEmailRequest = () => ({
  type: SEND_EMAIL_REQUEST,
});

const sendQuestionEmailSuccess = (payload) => ({
  payload,
  type: SEND_EMAIL_SUCCESS,
});

const sendQuestionEmailFailure = () => ({
  type: SEND_EMAIL_FAILURE,
});

export const sendQuestionEmail = (place, message) => (dispatch) => {
  dispatch(sendQuestionEmailRequest());

  return request
    .post(`v1/places/${place.id}/contact`, { place, message })
    .then(({ data }) => {
      dispatch(sendQuestionEmailSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(sendQuestionEmailFailure());

      throw error;
    });
};

export const SET_CAMPINGS_FILTERS = `${PREFIX} SET_CAMPINGS_FILTERS`;
export const setCampingsFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_CAMPINGS_FILTERS,
  });
};

export const SET_PLACES_FILTERS = `${PREFIX} SET_PLACES_FILTERS`;
export const setPlacesFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_PLACES_FILTERS,
  });
};

export const SET_SERVICES_FILTERS = `${PREFIX} SET_SERVICES_FILTERS`;
export const setServicesFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_SERVICES_FILTERS,
  });
};

export const SET_TOURIST_ATTRACTIONS_FILTERS = `${PREFIX} SET_TOURIST_ATTRACTIONS_FILTERS`;
export const setTouristAttractionsFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_TOURIST_ATTRACTIONS_FILTERS,
  });
};

export const SET_MAP_TYPE = `${PREFIX} SET_MAP_TYPE`;
export const setMapType = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_MAP_TYPE,
  });
};

export const convertPlaceType = (id, payload) => () => {
  return request
    .post(`v1/places/${id}/convert`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updatePlaceDetailsRequest = () => ({
  type: UPDATE_REQUEST,
});

const updatePlaceDetailsSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updatePlaceDetailsFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updatePlaceDetails = (id, payload) => (dispatch) => {
  dispatch(updatePlaceDetailsRequest());

  return request
    .put(`v1/places/${id}`, payload)
    .then(({ data }) => {
      dispatch(updatePlaceDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updatePlaceDetailsFailure());

      throw error;
    });
};
