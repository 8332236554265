import React from 'react';

const ParkingIcon = (props) => {
  return (
    <img
      alt="Parking"
      src="/static/images/icons/parking.svg"
      {...props}
    />
  );
};

export default ParkingIcon;
