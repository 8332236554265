import React from 'react';

const PinIcon = (props) => {
  return (
    <img
      alt="Pin"
      src="/static/images/icons/pin.svg"
      {...props}
    />
  );
};

export default PinIcon;
