import React from 'react';

const WildIcon = (props) => {
  return (
    <img
      alt="Wild"
      src="/static/images/icons/wild.svg"
      {...props}
    />
  );
};

export default WildIcon;
