import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { DropzoneDialogBase } from 'material-ui-dropzone';

const useStyles = makeStyles({
  root: {
    background: 'red',
  },
  dropzone: {
    display: ({ fileObjects, filesLimit }) => (fileObjects.length < filesLimit ? 'flex' : 'none'),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  container: {
    width: '100%',
    margin: 0,
    justifyContent: ({ filesLimit }) => (filesLimit > 1 ? 'flex-start' : 'center'),
  },
  item: {
    '& img': {
      height: 'auto',
      maxWidth: '100%',
      maxHeight: 66,
    },
    '& p': {
      overflow: 'hidden',
      marginTop: 4,
      color: '#546e7a',
      fontSize: 12,
      textAlign: 'center',
      textOverflow: 'ellipsis',
    },
  },
});

const FileUploadDialog = ({
  fileSizeLimit,
  filesLimit,
  handleClose,
  isOpen,
  title,
  setFiles,
}) => {
  const [fileObjects, setFileObject] = useState([]);

  const classes = useStyles({ filesLimit, fileObjects });

  const onAdd = (file) => {
    if (filesLimit === 1) {
      setFileObject(file);
    } else {
      setFileObject([].concat(fileObjects, file));
    }
  };

  const onClose = () => {
    setFileObject([]);
    handleClose();
  };

  const onDelete = (file) => {
    setFileObject(fileObjects.filter((item) => item !== file));
  };

  const onSave = () => {
    handleClose();
    setFiles(fileObjects);
    setFileObject([]);
  };

  const onError = (rejectedFile, acceptedFiles, maxFileSize) => {
    let message = `Plik ${rejectedFile.name} nie został przesłany. `;

    if (!acceptedFiles.includes(rejectedFile.type)) {
      message += 'Nieobsługiwany format pliku. ';
    }

    if (rejectedFile.size > maxFileSize) {
      message += `Za duży rozmiar (max. ${fileSizeLimit} MB)`;
    }

    return message;
  };

  return (
    <DropzoneDialogBase
      acceptedFiles={['image/jpeg', 'image/png']}
      cancelButtonText="Anuluj"
      initialFiles={[]}
      className={classes.root}
      dropzoneClass={classes.dropzone}
      dialogTitle={`${title} (max. ${fileSizeLimit} MB na plik)`}
      dropzoneText="Przeciągnij i upuść obrazek lub kliknij"
      fileObjects={fileObjects}
      filesLimit={filesLimit}
      getDropRejectMessage={onError}
      maxFileSize={fileSizeLimit * 1048576}
      open={isOpen}
      onAdd={onAdd}
      onClose={onClose}
      onDelete={onDelete}
      onSave={onSave}
      previewText="Podgląd:"
      previewGridClasses={{
        container: classes.container,
        item: classes.item,
      }}
      submitButtonText="Wyślij"
      showAlerts={['error']}
      showPreviews
      showFileNamesInPreview
    />
  );
};

FileUploadDialog.propTypes = {
  fileSizeLimit: PropTypes.number,
  filesLimit: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setFiles: PropTypes.func.isRequired,
  title: PropTypes.string,
};

FileUploadDialog.defaultProps = {
  fileSizeLimit: 4,
  filesLimit: 1,
  title: 'Wyślij pliki',
};

export default FileUploadDialog;
