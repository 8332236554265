export const URL_REGEXP = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
export const STREETVIEW_REGEXP = /^https?:\/\/(www\.|)google\.[a-z]{2,3}\/maps\/@(-?\d{1,2}\.\d+),(-?\d{1,2}\.\d+),.+$/;
export const YOUTUBE_REGEXP = /^https?:\/\/(www\.|)(youtube\.[a-z]{2,3}\/watch\?v=.*|youtu\.be\/.*)$/;

export const LATLNG_REGEXP = /^(-||\+)([0-9](\.\d+)?|([0-8][0-9])(\.\d+)?|90)(, |,)?((-||\+)([0-9]{1,2}|1[0-7][0-9])(\.\d+)?|180)$/g;
export const SLUGIFY_REGEX = /[*+~#.,()'"!:@=[\]{};\\/?§`]/g;

export const AdImageSizes = {
  mobile: {
    width: 320,
    height: 60,
  },
  webapp_banner: {
    width: 717,
    height: 130,
  },
  webapp_panel: {
    width: 300,
    height: 300
  }
};
