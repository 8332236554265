import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { runRecalculating } from 'src/store/actions/settings';
import settingsSelectors from 'src/store/selectors/settings';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

const CounterSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const runRecalculatingCounters = () => {
    enqueueSnackbar('Przeliczanie potrwa kilka minut. Możesz w tym czasie korzystać dalej z panelu.', { variant: 'info' });
    dispatch(runRecalculating());
  };

  const settings = useSelector((state) => settingsSelectors.getData(state));
  const isFetching = useSelector((state) => settingsSelectors.isFetching(state));

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          Przelicz liczniki
        </Typography>
        <Typography variant="caption">
          Dla zoptymalizowania wydajności liczba komentarzy, ocen i zameldowań nie aktualizuje
          podczas usuwania użytkownika.
          W przypadku błędnych błędnych wartości należy uruchomić przeliczenie.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Box
          mt={1}
        >
          <Button
            color="secondary"
            disabled={isFetching || !settings.recalculatingFinished}
            size="small"
            onClick={runRecalculatingCounters}
            variant="contained"
          >
            {!isFetching && settings.recalculatingFinished ? 'Uruchom przeliczanie' : 'Trwa przeliczanie'}
            {!isFetching && !settings.recalculatingFinished && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
          </Button>
          {settings.recalculatingDate && (
            <Box mt={1}>
              <Typography variant="caption">
                {settings.recalculatingFinished ? 'Ostatnie przeliczono zakończono' : 'Przeliczanie uruchomiono'}
                {' '}
                {moment(settings.recalculatingDate).startOf('seconds').fromNow()}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CounterSettings;
