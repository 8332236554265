import {
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_USER,
} from '../actions/auth';

const initialState = {
  isFetching: false,
  user: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
    case GET_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case LOGOUT_USER: {
      return {
        ...state,
        user: {},
      };
    }
    default:
      return state;
  }
};

export default authReducer;
