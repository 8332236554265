import request from '../../utils/request';
import {
  clearToken,
  setToken,
} from '../../utils/jwt';

const PREFIX = '[AUTH]';

export const GET_USER_REQUEST = `${PREFIX} GET_USER_REQUEST`;
export const GET_USER_SUCCESS = `${PREFIX} GET_USER_SUCCESS`;
export const GET_USER_FAILURE = `${PREFIX} GET_USER_FAILURE`;

const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});

const getUserSuccess = (payload) => ({
  payload,
  type: GET_USER_SUCCESS,
});

const getUserFailure = () => ({
  type: GET_USER_FAILURE,
});

export const getUser = () => (dispatch) => {
  dispatch(getUserRequest());

  return request
    .get('v1/auth/user/')
    .then(({ data }) => {
      dispatch(getUserSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(getUserFailure());

      throw error;
    });
};

export const LOGIN_REQUEST = `${PREFIX} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${PREFIX} LOGIN_FAILURE`;

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const login = (payload) => (dispatch) => {
  dispatch(loginRequest());

  return request
    .post('v1/auth/signin', payload)
    .then((res) => {
      const {
        data,
      } = res;

      if (data.status === 'administrator') {
        dispatch(loginSuccess());
        setToken('token', data.token, data.expiryDate);
        // setToken('refresh', refresh, token);

        request.setAuthorizationToken(data.token);

        delete data.token;
        delete data.expiryDate;

        dispatch(getUserSuccess(data));

        return res;
      }

      // eslint-disable-next-line no-throw-literal
      throw { message: 'Unauthorized' };
    })
    .catch((error) => {
      console.log(error);
      dispatch(loginFailure());

      throw error;
    });
};

export const LOGOUT_USER = `${PREFIX} LOGOUT_USER`;

export const logout = () => {
  clearToken('token');

  request.removeAuthorizationToken();

  return {
    type: LOGOUT_USER,
  };
};
