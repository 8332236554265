export default {
  getFilters: (state) => state.ads.filters,

  getAdDetails: (state) => state.ads.details.data,
  getAdDetailsIsFetching: (state) => state.ads.details.isFetching,

  getAds: (state) => state.ads.list.items,
  getAdsPaginationData: (state) => state.ads.list.meta,
  getAdsIsFetching: (state) => state.ads.list.isFetching,
};
