import React, { useContext } from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import InputIcon from '@material-ui/icons/Input';

import { logout } from 'src/store/actions/auth';

import { AppBarContext } from 'src/utils/appBarContext';

import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  logo: {
    width: 52,
    height: 52,
    marginLeft: -12,
    marginRight: 12,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
}));

const TopBar = ({
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    backTo,
    hasBackButton,
    onBack,
    setMobileMenuOpen,
    title,
  } = useContext(AppBarContext);

  const handleLogout = () => {
    dispatch(logout);
    window.location = '/login';
  };

  const handleBack = () => {
    if (onBack && onBack()) {
      return true;
    }

    if (backTo) {
      return navigate(backTo);
    }

    if (document.referrer.includes(document.location.host)) {
      return navigate(-1);
    }

    return navigate(-1);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <>
          {hasBackButton ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleBack}
            >
              <BackIcon />
            </IconButton>
          ) : (
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          )}
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        </>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={() => {
              setMobileMenuOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

TopBar.defaultProps = {
  className: null,
};

export default TopBar;
