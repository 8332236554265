import {
  CLEAR_DATA,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_CHECKINS_FAILURE,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_DETAILS_FAILURE,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  SET_FILTERS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/users';
import {
  DELETE_FAILURE as DELETE_CHECKIN_FAILURE,
  DELETE_REQUEST as DELETE_CHECKIN_REQUEST,
  DELETE_SUCCESS as DELETE_CHECKIN_SUCCESS,
} from '../actions/check-ins';
import {
  DELETE_FAILURE as DELETE_COMMENT_FAILURE,
  DELETE_REQUEST as DELETE_COMMENT_REQUEST,
  DELETE_SUCCESS as DELETE_COMMENT_SUCCESS,
  UPDATE_FAILURE as UPDATE_COMMENT_FAILURE,
  UPDATE_REQUEST as UPDATE_COMMENT_REQUEST,
  UPDATE_SUCCESS as UPDATE_COMMENT_SUCCESS,
} from '../actions/comments';

const initialState = {
  details: {
    isFetching: false,
    data: null,
    comments: {
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
    checkIns: {
      items: null,
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
  },
  filters: {},
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return {
        ...state,
        details: initialState.details,
        list: initialState.list,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_SUCCESS:
    case DELETE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case DELETE_CHECKIN_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: true,
          }
        },
      };
    case DELETE_CHECKIN_SUCCESS:
    case DELETE_CHECKIN_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: false,
          }
        },
      };
    case DELETE_COMMENT_REQUEST:
    case UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: true,
          }
        },
      };
    case DELETE_COMMENT_SUCCESS:
    case DELETE_COMMENT_FAILURE:
    case UPDATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: false,
          }
        },
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    case GET_CHECKINS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: true,
          }
        }
      };
    case GET_CHECKINS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            isFetching: false,
          }
        }
      };
    case GET_CHECKINS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          checkIns: {
            ...state.details.checkIns,
            ...action.payload,
            isFetching: false,
          }
        }
      };
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: true,
          }
        }
      };
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            isFetching: false,
          }
        }
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          comments: {
            ...state.details.comments,
            ...action.payload,
            isFetching: false,
          }
        }
      };
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          checkIns: initialState.details.checkIns,
          comments: initialState.details.comments,
        }
      };
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload,
          isFetching: false,
        }
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        }
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          ...action.payload,
        }
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default usersReducer;
