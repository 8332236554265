import request from '../../utils/request';

const PREFIX = '[UNVERIFIED]';

export const GET_UNVERIFIED_REQUEST = `${PREFIX} GET_UNVERIFIED_REQUEST`;
export const GET_UNVERIFIED_SUCCESS = `${PREFIX} GET_UNVERIFIED_SUCCESS`;
export const GET_UNVERIFIED_FAILURE = `${PREFIX} GET_UNVERIFIED_FAILURE`;

const fetchUnverifiedRequest = () => ({
  type: GET_UNVERIFIED_REQUEST,
});

const fetchUnverifiedSuccess = (payload) => ({
  payload,
  type: GET_UNVERIFIED_SUCCESS,
});

const fetchUnverifiedFailure = () => ({
  type: GET_UNVERIFIED_FAILURE,
});

export const fetchUnverified = (filters) => (dispatch) => {
  dispatch(fetchUnverifiedRequest());

  return request
    .get('v1/places/unverified', { params: filters })
    .then(({ data }) => {
      dispatch(fetchUnverifiedSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchUnverifiedFailure());

      throw error;
    });
};
