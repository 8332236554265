import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import slugify from 'slugify/slugify';
import moment from 'moment';
import { Field } from 'react-final-form';
import { TextField } from 'mui-rff';
import 'date-fns';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import countryToFlag from 'src/utils/countryToFlag';
import { pageStatusValues, pageTypeValues } from 'src/utils/enums';
import {
  codes as supportedLocalesCodes,
  labels as supportedLocalesLabels,
} from 'src/utils/locales';
import WysiwygEditor from 'src/components/WysiwygEditor';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  tab: {
    minHeight: 44
  }
}));

const PageDetails = ({ data, setContent, setSlug }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const language = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('language') || 'pl';
  }, [location.search]);

  const handleChangeLanguage = (event, value) => {
    navigate(`${location.pathname}?language=${value}`);
  };

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja strony: ${data.title ?? ''} [ID: #${data.id}]` : 'Dodaj nową stronę'}
      />
      <Divider />
      {data && (
        <>
          <Tabs value={language} onChange={handleChangeLanguage}>
            {supportedLocalesCodes.map((locale) => (
              <Tab
                key={`tab-lang-${locale}`}
                icon={`${countryToFlag(locale)} `}
                label={supportedLocalesLabels[locale]}
                value={locale}
                aria-label={supportedLocalesLabels[locale]}
                className={classes.tab}
              />
            ))}
          </Tabs>
          <Divider />
        </>
      )}
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Tytuł"
              name="title"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              className={classes.typeSelect}
              fullWidth
              label="Typ"
              name="type"
              required
              select
              disabled={!!data}
              variant="outlined"
            >
              <MenuItem
                key=""
                value=""
              />
              {pageTypeValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Opis"
              name="description"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Field name="content">
              {({ meta }) => (
                <WysiwygEditor
                  value={data?.content ?? ''}
                  onChange={(value) => setContent(value)}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: SLUGIFY_REGEX });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                  label="Slug"
                  name="slug"
                  required
                  disabled={data && data.type === 'element'}
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              {pageStatusValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              {data && data.type !== 'element' && (
                <MenuItem value="deleted">
                  Usunięty
                </MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            {/* <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Dodane:
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid> */}
            <Grid
              item
              sm={12}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnia edycja:
                {' '}
                {moment(data.updated).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

PageDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.oneOf(pageTypeValues.map((option) => option.value)).isRequired,
    status: PropTypes.oneOf(pageStatusValues.map((option) => option.value)).isRequired,
    created: PropTypes.string,
    updated: PropTypes.string,
  }),
  setContent: PropTypes.func.isRequired,
  setSlug: PropTypes.func.isRequired,
};

PageDetails.defaultProps = {
  data: null,
};

export default PageDetails;
