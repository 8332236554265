import React from 'react';
import { useSnackbar } from 'notistack';

import { refreshWebappCachce } from 'src/store/actions/settings';
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

const WebappCacheSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const revalidateWebappContent = async () => {
    await refreshWebappCachce().then(() => {
      enqueueSnackbar('Cache strony został odświeżony', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Wystąpił bład podczas próby odświeżenia cache.', { variant: 'error' });
    });
  };

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={6}
      >
        <Typography
          className={classes.heading}
          variant="h5"
        >
          Odśwież cache aplikacji webowej
        </Typography>
        <Typography variant="caption">
          W przypadku, gdyby treści w aplikacji webowej (stronie www) się nie odświeżyły,
          można spróbować odświeżyć cache.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Box
          mt={1}
        >
          <Button
            color="secondary"
            size="small"
            onClick={revalidateWebappContent}
            variant="contained"
          >
            Odśwież
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WebappCacheSettings;
