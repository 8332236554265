export default (data) => ({
  id: data[0],
  title: data[1],
  type: data[2],
  latitude: data[3],
  longitude: data[4],
  discount: data[5],
  tourist_attraction: data[6],
  service: data[7],
  slug: data[13],
  thumbnail: data[15],
});
