export default {
  getFilters: (state) => state.news.filters,

  getNewsDetails: (state) => state.news.details.data,
  getNewsDetailsIsFetching: (state) => state.news.details.isFetching,

  getNews: (state) => state.news.list.items,
  getNewsTotalCount: (state) => state.news.categories.totalCount,
  getNewsPaginationData: (state) => state.news.list.meta,
  getNewsIsFetching: (state) => state.news.list.isFetching,
};
