import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  inkBox: {
    height: '100%',
  },
}));

const TotalComments = ({ total }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.inkBox}
        component={RouterLink}
        to="/comments"
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                KOMENTARZY
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {total}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <CommentIcon />
              </Avatar>
            </Grid>
          </Grid>
          {/* <Box
            mt={2}
            display="flex"
            alignItems="center"
          >
            <AddLocationIcon className={classes.differenceIcon} />
            <Typography
              className={classes.differenceValue}
              variant="body2"
            >
              1
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              zgłoszonych jako nieodpowiednie
            </Typography>
          </Box> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

TotalComments.propTypes = {
  total: PropTypes.number,
};

export default TotalComments;
