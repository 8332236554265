import request from '../../utils/request';

const PREFIX = '[DISCOUNTS]';

export const GET_DISCOUNTS_REQUEST = `${PREFIX} GET_DISCOUNTS_REQUEST`;
export const GET_DISCOUNTS_SUCCESS = `${PREFIX} GET_DISCOUNTS_SUCCESS`;
export const GET_DISCOUNTS_FAILURE = `${PREFIX} GET_DISCOUNTS_FAILURE`;

const fetchDiscountsRequest = () => ({
  type: GET_DISCOUNTS_REQUEST,
});

const fetchDiscountsSuccess = (payload) => ({
  payload,
  type: GET_DISCOUNTS_SUCCESS,
});

const fetchDiscountsFailure = () => ({
  type: GET_DISCOUNTS_FAILURE,
});

export const fetchDiscounts = (filters) => (dispatch) => {
  dispatch(fetchDiscountsRequest());

  return request
    .get('v1/places/discounts', { params: filters })
    .then(({ data }) => {
      dispatch(fetchDiscountsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchDiscountsFailure());

      throw error;
    });
};
