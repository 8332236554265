import React from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
} from 'react-router-dom';
import moment from 'moment';

import {
  Avatar,
  Box,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import GalleryImage from 'src/components/GalleryImage';

import getInitials from 'src/utils/getInitials';
import countryToFlag from 'src/utils/countryToFlag';

import ServiceType from './ServiceType';
import TouristAttractionType from './TouristAttractionType';
import PlaceType from './PlaceType';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
    textDecoation: 'none',
  },
  placeLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  placesTitle: {
    '& img': {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle',
    },
  },
}));

const CommentItem = ({
  comment,
  openDeleteCommentModal,
  openEditCommentModal,
  showActions,
  showPlace,
  showUser,
}) => {
  const classes = useStyles();

  const getPlaceIcon = (place) => {
    if (place.type === 'service') {
      return <ServiceType type={place.service} />;
    }

    if (place.type === 'tourist_attraction') {
      return <TouristAttractionType type={place.tourist_attraction} />;
    }

    return <PlaceType type={place.type} />;
  };

  return (
    <TableRow
      hover
      key={comment.id}
    >
      {showPlace && (
        <TableCell className={classes.placesTitle}>
          {comment.place && (
            <Link
              className={classes.placeLink}
              color="inherit"
              component={RouterLink}
              to={`/place/${comment.place.id}`}
              underline="none"
            >
              {getPlaceIcon(comment.place)}
              {comment.place.title}
            </Link>
          )}
        </TableCell>
      )}
      {showUser && (
        <TableCell>
          {comment.username ? (
            <Box
              alignItems="center"
              display="flex"
            >
              <Avatar
                className={classes.avatar}
              >
                {getInitials(comment.username)}
              </Avatar>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {comment.username}
              </Typography>
            </Box>
          ) : (
            <Link
              color="inherit"
              component={RouterLink}
              to={`/user/${comment.user.id}`}
              underline="none"
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <Avatar
                  className={classes.avatar}
                  src={comment.user.avatar && `${STORAGE_URL}/users/${comment.user.id}/${comment.user.avatar}`}
                >
                  {!comment.user.avatar && getInitials(comment.user.username)}
                </Avatar>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {comment.user.username}
                  {comment.user.country !== 'PL' && ` ${countryToFlag(comment.user.country)}`}
                </Typography>
              </Box>
            </Link>
          )}
        </TableCell>
      )}
      <TableCell>
        {comment.rate > 0 ? (
          <Rating
            readOnly
            precision={0.5}
            value={comment.rate}
          />
        ) : 'Brak oceny'}
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-line' }}>
        {comment.content}
        <p>{comment.content !== null && comment.language !== 'pl' && countryToFlag(comment.language)}</p>
        {comment.files?.length > 0 && (
          <Box>
            {comment.files.map((item) => <GalleryImage key={item.filename} file={item} />)}
          </Box>
        )}
      </TableCell>
      <TableCell>
        {moment(comment.created).startOf('minute').fromNow()}
      </TableCell>
      {showActions && (
        <TableCell>
          <IconButton onClick={() => openEditCommentModal(comment)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => openDeleteCommentModal(comment)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string,
    created: PropTypes.string,
    language: PropTypes.string,
    rate: PropTypes.number,
    files: PropTypes.array,
    place: PropTypes.any,
    user: PropTypes.any,
    username: PropTypes.string,
  }).isRequired,
  openDeleteCommentModal: PropTypes.func,
  openEditCommentModal: PropTypes.func,
  showActions: PropTypes.bool,
  showPlace: PropTypes.bool,
  showUser: PropTypes.bool,
};

CommentItem.defaultProps = {
  openDeleteCommentModal: () => {},
  openEditCommentModal: () => {},
  showActions: true,
  showPlace: true,
  showUser: true,
};

export default CommentItem;
