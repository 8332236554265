import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Resizable } from 're-resizable';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import Loading from 'src/components/TableLoading';
import getPlaceMarkerIcon from 'src/components/PlaceMarkerIcon';
import getServiceMarkerIcon from 'src/components/ServiceMarkerIcon';
import parseMapMarker from 'src/utils/parseMapMarker';
import PlaceCard from '../components/PlaceCard';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    transition: theme.transitions.create('padding', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  headerOpen: {
    paddingTop: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  handle: {
    position: 'relative',
    top: 15,
    height: 3,
    borderRadius: 3,
    zIndex: 400,
    background: theme.palette.grey[200],
  },
  map: {
    height: '100%',
  },
  popup: {
    '& .leaflet-popup-content': {
      margin: 0,
      '& p': {
        margin: 0,
      },
    },
    '& .leaflet-popup-tip-container': {
      marginTop: -1,
    },
  },
}));

const Map = ({
  data,
  loading,
}) => {
  const classes = useStyles();
  const position = { lat: 51.9189046, lng: 19.1343786 };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [isMapOpen, setMapOpen] = useState(localStorage.getItem('isMapOpen') !== 'false');
  const [mapHeight, setMapHeight] = useState(parseInt(localStorage.getItem('mapHeight'), 10) || 500);

  const toggleMap = () => {
    const status = !isMapOpen;
    setMapOpen(status);
    localStorage.setItem('isMapOpen', status);
  };

  return (
    <Card>
      <Box
        className={clsx(classes.header, {
          [classes.headerOpen]: isMapOpen,
        })}
      >
        <Typography component="h2" variant="h5">
          {`Wyświetlane miejsca${data && `: ${data.length}`}`}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {!isMobile && (
            <Typography component="p" variant="caption">
              {isMapOpen ? 'Ukryj mapę' : 'Pokaż mapę'}
            </Typography>
          )}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: isMapOpen,
            })}
            onClick={toggleMap}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isMapOpen}>
        <CardContent className={classes.root}>
          {loading && <Loading />}
          <Resizable
            enable={{ bottom: !isMobile }}
            handleComponent={{ bottom: <Box className={classes.handle} /> }}
            minHeight={225}
            size={{
              height: !isMobile ? mapHeight : 300,
            }}
            onResizeStop={(e, direction, ref, d) => {
              setMapHeight(mapHeight + d.height);
              localStorage.setItem('mapHeight', mapHeight + d.height);
            }}
          >
            <MapContainer
              center={position}
              className={classes.map}
              scrollWheelZoom
              zoom={3}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup>
                {data.map((place) => {
                  const placeData = parseMapMarker(place);

                  return (
                    <Marker
                      key={`marker-${placeData.id}`}
                      icon={
                        placeData.type === 'service'
                          ? getServiceMarkerIcon(placeData.service)
                          : getPlaceMarkerIcon(placeData.type)
                      }
                      position={{
                        lat: placeData.latitude,
                        lng: placeData.longitude,
                      }}
                    >
                      <Popup className={classes.popup}>
                        <PlaceCard place={placeData} />
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            </MapContainer>
          </Resizable>
        </CardContent>
      </Collapse>
    </Card>
  );
};

Map.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Map;
