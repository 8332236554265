import request from '../../utils/request';

const PREFIX = '[ARTICLES]';

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;
export const clearArticlesData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createArticleRequest = () => ({
  type: CREATE_REQUEST,
});

const createArticleSuccess = (payload) => ({
  payload,
  type: CREATE_SUCCESS,
});

const createArticleFailure = () => ({
  type: CREATE_FAILURE,
});

export const createArticle = (payload) => (dispatch) => {
  dispatch(createArticleRequest());

  return request
    .post('v1/articles/', payload)
    .then(({ data }) => {
      dispatch(createArticleSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createArticleFailure());

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteArticleRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteArticleSuccess = (payload) => ({
  payload,
  type: DELETE_SUCCESS,
});

const deleteArticleFailure = () => ({
  type: DELETE_FAILURE,
});

export const deleteArticle = (id) => (dispatch) => {
  dispatch(deleteArticleRequest());

  return request
    .delete(`v1/articles/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(deleteArticleSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteArticleFailure());

      throw error;
    });
};

export const GET_ARTICLES_REQUEST = `${PREFIX} GET_ARTICLES_REQUEST`;
export const GET_ARTICLES_SUCCESS = `${PREFIX} GET_ARTICLES_SUCCESS`;
export const GET_ARTICLES_FAILURE = `${PREFIX} GET_ARTICLES_FAILURE`;

const fetchArticlesRequest = () => ({
  type: GET_ARTICLES_REQUEST,
});

const fetchArticlesSuccess = (payload) => ({
  payload,
  type: GET_ARTICLES_SUCCESS,
});

const fetchArticlesFailure = () => ({
  type: GET_ARTICLES_FAILURE,
});

export const fetchArticles = (filters) => (dispatch) => {
  dispatch(fetchArticlesRequest());

  return request
    .get('v1/articles/', { params: { ...filters, isAdmin: true } })
    .then(({ data }) => {
      dispatch(fetchArticlesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchArticlesFailure());

      throw error;
    });
};

export const GET_DETAILS_REQUEST = `${PREFIX} GET_DETAILS_REQUEST`;
export const GET_DETAILS_SUCCESS = `${PREFIX} GET_DETAILS_SUCCESS`;
export const GET_DETAILS_FAILURE = `${PREFIX} GET_DETAILS_FAILURE`;

const fetchArticleDetailsRequest = () => ({
  type: GET_DETAILS_REQUEST,
});

const fetchArticleDetailsSuccess = (payload) => ({
  payload,
  type: GET_DETAILS_SUCCESS,
});

const fetchArticleDetailsFailure = () => ({
  type: GET_DETAILS_FAILURE,
});

export const fetchArticleDetails = (id) => (dispatch) => {
  dispatch(fetchArticleDetailsRequest());

  return request
    .get(`v1/articles/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchArticleDetailsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchArticleDetailsFailure());

      throw error;
    });
};

export const SET_FILTERS = `${PREFIX} SET_FILTERS`;
export const setFilters = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_FILTERS,
  });
};

export const UPDATE_REQUEST = `${PREFIX} UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${PREFIX} UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${PREFIX} UPDATE_FAILURE`;

const updateArticleRequest = () => ({
  type: UPDATE_REQUEST,
});

const updateArticleSuccess = (payload) => ({
  payload,
  type: UPDATE_SUCCESS,
});

const updateArticleFailure = () => ({
  type: UPDATE_FAILURE,
});

export const updateArticle = (id, payload) => (dispatch) => {
  dispatch(updateArticleRequest());

  return request
    .put(`v1/articles/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateArticleSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateArticleFailure());

      throw error;
    });
};

export const CREATE_CATEGORY_REQUEST = `${PREFIX} CREATE_CATEGORY_REQUEST`;
export const CREATE_CATEGORY_SUCCESS = `${PREFIX} CREATE_CATEGORY_SUCCESS`;
export const CREATE_CATEGORY_FAILURE = `${PREFIX} CREATE_CATEGORY_FAILURE`;

const createArticleCategoryRequest = () => ({
  type: CREATE_CATEGORY_REQUEST,
});

const createArticleCategorySuccess = (payload) => ({
  payload,
  type: CREATE_CATEGORY_SUCCESS,
});

const createArticleCategoryFailure = () => ({
  type: CREATE_CATEGORY_FAILURE,
});

export const createArticleCategory = (payload) => (dispatch) => {
  dispatch(createArticleCategoryRequest());

  return request
    .post('v1/articles/category', payload)
    .then(({ data }) => {
      dispatch(createArticleCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(createArticleCategoryFailure());

      throw error;
    });
};

export const DELETE_CATEGORY_REQUEST = `${PREFIX} DELETE_CATEGORY_REQUEST`;
export const DELETE_CATEGORY_SUCCESS = `${PREFIX} DELETE_CATEGORY_SUCCESS`;
export const DELETE_CATEGORY_FAILURE = `${PREFIX} DELETE_CATEGORY_FAILURE`;

const deleteArticleCategoryRequest = () => ({
  type: DELETE_CATEGORY_REQUEST,
});

const deleteArticleCategorySuccess = (payload) => ({
  payload,
  type: DELETE_CATEGORY_SUCCESS,
});

const deleteArticleCategoryFailure = () => ({
  type: DELETE_CATEGORY_FAILURE,
});

export const deleteArticleCategory = (id) => (dispatch) => {
  dispatch(deleteArticleCategoryRequest());

  return request
    .delete(`v1/articles/category/${id}`)
    .then(({ data }) => {
      dispatch(deleteArticleCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(deleteArticleCategoryFailure());

      throw error;
    });
};

export const GET_CATEGORIES_REQUEST = `${PREFIX} GET_CATEGORIES_REQUEST`;
export const GET_CATEGORIES_SUCCESS = `${PREFIX} GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAILURE = `${PREFIX} GET_CATEGORIES_FAILURE`;

const fetchArticleCategoriesRequest = () => ({
  type: GET_CATEGORIES_REQUEST,
});

const fetchArticleCategoriesSuccess = (payload) => ({
  payload,
  type: GET_CATEGORIES_SUCCESS,
});

const fetchArticleCategoriesFailure = () => ({
  type: GET_CATEGORIES_FAILURE,
});

export const fetchArticleCategories = (filters) => (dispatch) => {
  dispatch(fetchArticleCategoriesRequest());

  return request
    .get('v1/articles/categories', { params: { isAdmin: true, ...filters } })
    .then(({ data }) => {
      dispatch(fetchArticleCategoriesSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchArticleCategoriesFailure());

      throw error;
    });
};

export const GET_CATEGORY_DETAILS_REQUEST = `${PREFIX} GET_CATEGORY_DETAILS_REQUEST`;
export const GET_CATEGORY_DETAILS_SUCCESS = `${PREFIX} GET_CATEGORY_DETAILS_SUCCESS`;
export const GET_CATEGORY_DETAILS_FAILURE = `${PREFIX} GET_CATEGORY_DETAILS_FAILURE`;

const fetchArticleCategoryRequest = () => ({
  type: GET_CATEGORY_DETAILS_REQUEST,
});

const fetchArticleCategorySuccess = (payload) => ({
  payload,
  type: GET_CATEGORY_DETAILS_SUCCESS,
});

const fetchArticleCategoryFailure = () => ({
  type: GET_CATEGORY_DETAILS_FAILURE,
});

export const fetchArticleCategory = (id) => (dispatch) => {
  dispatch(fetchArticleCategoryRequest());

  return request
    .get(`v1/articles/category/${id}?isAdmin`)
    .then(({ data }) => {
      dispatch(fetchArticleCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchArticleCategoryFailure());

      throw error;
    });
};

export const UPDATE_CATEGORY_REQUEST = `${PREFIX} UPDATE_CATEGORY_REQUEST`;
export const UPDATE_CATEGORY_SUCCESS = `${PREFIX} UPDATE_CATEGORY_SUCCESS`;
export const UPDATE_CATEGORY_FAILURE = `${PREFIX} UPDATE_CATEGORY_FAILURE`;

const updateArticleCategoryRequest = () => ({
  type: UPDATE_CATEGORY_REQUEST,
});

const updateArticleCategorySuccess = (payload) => ({
  payload,
  type: UPDATE_CATEGORY_SUCCESS,
});

const updateArticleCategoryFailure = () => ({
  type: UPDATE_CATEGORY_FAILURE,
});

export const updateArticleCategory = (id, payload) => (dispatch) => {
  dispatch(updateArticleCategoryRequest());

  return request
    .put(`v1/articles/category/${id}`, payload)
    .then(({ data }) => {
      dispatch(updateArticleCategorySuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(updateArticleCategoryFailure());

      throw error;
    });
};
