import React from 'react';

const BeachIcon = (props) => {
  return (
    <img
      alt="Plaża"
      src="/static/images/icons/tourist_attractions/beach.svg"
      {...props}
    />
  );
};

export default BeachIcon;
