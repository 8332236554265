import React from 'react';

const TicketIcon = (props) => {
  return (
    <img
      alt="Atrakcja"
      src="/static/images/icons/tourist_attractions/ticket.svg"
      {...props}
    />
  );
};

export default TicketIcon;
