import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import getInitials from 'src/utils/getInitials';
import FieldSet from 'src/components/FieldSet';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      height: 198,
    }
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(2),
    fontSize: 48,
  },
}));

const UserCard = ({
  message,
  user,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Zgłaszający" />
      <Divider />
      <CardContent>
        <Grid
          alignItems="center"
          className={classes.root}
          container
          spacing={3}
        >
          <Grid
            item
            sm={4}
            xs={12}
          >
            <CardActionArea
              component={RouterLink}
              to={`/user/${user.id}`}
            >
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <Avatar
                  className={classes.avatar}
                  src={user.avatar && `${STORAGE_URL}/users/${user.id}/${user.avatar}`}
                >
                  {!user?.avatar && getInitials(user.username)}
                </Avatar>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {user.username || <span>&nbsp;</span>}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="caption"
                >
                  Punktów: ---
                </Typography>
              </Box>
            </CardActionArea>
          </Grid>
          <Grid
            item
            sm={8}
            xs={12}
          >
            <FieldSet
              title="Wiadomość od zgłaszającego"
            >
              <PerfectScrollbar>
                <Box height={100}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    {message}
                  </Typography>
                </Box>
              </PerfectScrollbar>
            </FieldSet>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

UserCard.propTypes = {
  message: PropTypes.string,
  user: PropTypes.object.isRequired
};

UserCard.defaultProps = {
  message: '',
};

export default UserCard;
