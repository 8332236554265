import {
  GET_SUGGESTIONS_FAILURE,
  GET_SUGGESTIONS_REQUEST,
  GET_SUGGESTIONS_SUCCESS,
} from '../actions/suggestions';

const initialState = {
  list: {
    isFetching: false,
    items: null,
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
};

const suggestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTIONS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        }
      };
    case GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        }
      };
    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false,
        }
      };
    default:
      return state;
  }
};

export default suggestionsReducer;
